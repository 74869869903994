import copy from 'copy-text-to-clipboard';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import _concat from 'lodash/concat';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _mapKeys from 'lodash/mapKeys';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import _startCase from 'lodash/startCase';
import _difference from 'lodash/difference';
import dayjs from 'dayjs';
import { ignoreUnicode } from '@/ui/plugins/utils';
import _pickBy from 'lodash/pickBy';

/**
 * Returns the new sorted array.
 * @param {array} The collection to iterate over.
 * @param {array} The iteratees to sort by. eg: ['user', 'age']
 * @param {array} The sort orders of iteratees.. eg: ['asc', 'desc']
 * @return {array} The new sorted array.
 **/
export const arrayOrderBy = (collection, iterate, order) => {
    return _orderBy(collection, iterate, order);
};
/**
 * Returns a nomal text.
 * @param {array} The text to convert.
 * @param {array} Convert space or not
 * @return {array} The converted text.
 **/
export const stringNomalize = (text, nonSpace = false) => {
    if (!text) return '';
    let returnText = '';
    returnText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    returnText = returnText.replace(/đ/g, 'd');
    returnText = returnText.replace(/Đ/g, 'D');
    if (nonSpace) returnText = returnText.replace(/[ ]/g, '_');
    return returnText;
};

/**
 * @param {string} value
 * @returns {Boolean}
 */
export function copyToClipBroard(value) {
    // // Create a dummy input to copy the string array inside it
    // var dummy = document.createElement('input');

    // // Add it to the document
    // document.body.appendChild(dummy);

    // // Set its ID
    // dummy.setAttribute('id', 'dummy_id');

    // // Output the array into it
    // document.getElementById('dummy_id').value = value;

    // // Select it
    // dummy.select();

    // // Copy its contents
    // document.execCommand('copy');

    // // Remove it as its not needed anymore
    // document.body.removeChild(dummy);
    // return true;
    // navigator.clipboard.writeText(value);
    copy(value);
    return true;
}

/**
 * @param {Object| Array} value
 * @returns {Object| Array} cloned value
 */
export function cloneDeep(value) {
    return _cloneDeep(value);
}

/**
 * @param {Object| Array} value
 * @returns {Object| Array} cloned value
 */
export function isEqual(value, other) {
    return _isEqual(value, other);
}

export function uniq(value) {
    return _uniq(value);
}

export function mergeById(key = 'id', arr1, arr2) {
    const data = _map(_groupBy(_concat(arr1, arr2), key), (o) => {
        if (o?.length > 0) return o[0];
        return {};
    });
    return data;
}

export function debounce() {
    return _debounce;
}

export function isEmpty(data) {
    return _isEmpty(data);
}

/**
 * @param {Object| Array} value
 * @returns {Object| Array} cloned value
 */
// export function cloneDeep(value) {

//   const objectAwsS3 = await getObjectFromAwsS3(
//     `photo/original/${file.fileName || file.name}`
//   );
//   const newBlob = new Blob([objectAwsS3.Body], {
//     type: objectAwsS3.ContentType,
//   });
//   binary = new File([newBlob], 'file.png', { type: 'image/png' });
// }
/**
 * @param {Object| Array} value
 * @returns {Object| Array} cloned value
 */
export function formatDate(value, format = 'DD/MM/YYYY') {
    return dayjs(value).format(format);
}

export function isValidEmail(value) {
    if (!value) return;
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(value).trim().toLowerCase());
}

export function isValidLengthEmail(value) {
    if (!value) return;
    const subEmail = value.split('@');
    if (subEmail.length == 2 && subEmail[1].length > 1) {
        return true;
    }
    return false;
}

export function isValidPhoneNumber(value) {
    if (!value) return false;
    // const regex = /^[+\]*[(\]{0,1}[0-9]{1,4}[)\]{0,1}[-\s\\./0-9]*$/;
    // const regex = /^(?:[+0][0-9])?[0-9]{10}[0-9]{5}?$/;
    // const regex = /^\+(?:[0-9]?){6,15}[0-9]$/;
    const regex = /^\+[1-9]{1}[0-9]{3,14}$/;
    /*
     value.length == 23:
     Create more acount with existed phone number
    */
    return regex.test(value) || value.length == 23;
}

export function isNumber(value) {
    if (!value) return;
    const regex = /^[0-9]+$/;
    return regex.test(value);
}

export function getCompanyOwnName(fullName) {
    const companyTypeNameList = [
        'cong ty trach nhiem huu han',
        'cong ty tnhh',
        'cong ty trach nhiem huu han mot thanh vien',
        'cong ty co phan',
        'cong ty cp',
        'cong ty hop danh',
        'cong ty hd',
        'doanh nghiep tu nhan',
        'doanh nghiep tn',
        'dntn',
        'doanh nghiep nha nuoc',
        'company limited',
        'a limited liability company',
        'one member limited liability companies',
        'partnership',
        'private enterprise',
        'shareholding companies',
        'state - owned enterprise',
        'private enterprise',
        'government',
        'state enterprise',
        'venture ',
        'foreign company',
        'transnational companies',
        'household business',
    ];
    if (!fullName || typeof fullName !== 'string') return;
    let ownName = fullName;
    const trimmedFullName = fullName.trim();
    const normalizeFullName = stringNomalize(trimmedFullName, false);
    const index = companyTypeNameList.findIndex((name) =>
        normalizeFullName.toLowerCase().includes(name)
    );
    if (index > -1) {
        ownName = trimmedFullName.slice(
            companyTypeNameList[index].length,
            trimmedFullName.length
        );
    }
    return ownName;
}

export function getTimestampUtc() {
    // const now = Date.now();
    // const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;
    // return now + timeOffset;
    return Date.now();
}

export function validateFormatDatetime(string) {
    if (!string) return;
    const regex =
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return regex.test(string);
}

export function markTextSearch(text, searchText) {
    const key = ignoreUnicode(searchText);
    const indexOfFirst = stringNomalize(text?.toLowerCase()).indexOf(
        stringNomalize(key?.toLowerCase())
    );

    const indexOfEnd = indexOfFirst + key?.length;
    if (indexOfFirst == -1) return text;
    return `
        <span>${text.substring(
            0,
            indexOfFirst
        )}<span class="font-bold text-current-600">${text.substring(
        indexOfFirst,
        indexOfEnd
    )}</span>${text.substring(indexOfEnd, text.length)}</span>`;
}

export function markBackgroundTextSearch(text, searchText) {
    if (!text) return;

    const key = ignoreUnicode(searchText);
    const indexOfFirst = stringNomalize(text?.toLowerCase()).indexOf(
        stringNomalize(key?.toLowerCase())
    );
    const indexOfEnd = indexOfFirst + key?.length;
    if (indexOfFirst == -1) return text;
    return `
        <span>${text.substring(
            0,
            indexOfFirst
        )}<span class="bg-orange-300">${text.substring(
        indexOfFirst,
        indexOfEnd
    )}</span>${text.substring(indexOfEnd, text.length)}</span>`;
}

export function truncateLinkFile(fullStr, strLen, separator) {
    if (fullStr?.length <= strLen) return fullStr;

    separator = separator || '...';

    const sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return (
        fullStr?.substring(0, frontChars) +
        separator +
        fullStr?.substring(fullStr?.length - backChars)
    );
}

export function filterObject(object, condition) {
    return _pickBy(object, condition);
}

export function checkIsFileObject(data) {
    return 'File' in window && data instanceof File;
}

export function randomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function getDynamicPosition({ x, y, width, height, padding = 5 }) {
    const bodyWidth = document.body.clientWidth;
    const bodyHeight = document.body.clientHeight;

    const positionX = x + width;
    const positionY = y + height;
    return {
        x: positionX > bodyWidth ? bodyWidth - width - padding : x - padding,
        y:
            positionY > bodyHeight
                ? bodyHeight - 2 * height - padding
                : y - height - padding,
    };
}

// Covert key value abc_xyz to abcXyz
export function covertSnakeCaseToCamelCase(objectCovert) {
    return _mapKeys(objectCovert, (value, key) => _camelCase(key));
}

export function covertCamelCaseToSnakeCase(objectCovert) {
    if (!objectCovert) return objectCovert;
    return _mapKeys(objectCovert, (value, key) => _snakeCase(key));
}

export function mapKeysToCamelCase(objectCovert) {
    return _mapKeys(objectCovert, (value, key) => _camelCase(key));
}

export function startCase(text) {
    return _startCase(text);
}

export function arrayUnique(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
        }
    }

    return a;
}

// Covert key value abc_xyz to abcXyz
export function clearDoubleSpaces(name: string) {
    if (!name) return '';
    return name.replace(/ +(?= )/g, '');
}

export function formatNumberToString(num: number): string {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toString();
}

export function formatDuration(miliseconds: number): string {
    let result = '';

    const _hour = Math.floor(miliseconds / 1000 / 60 / 60);
    if (_hour > 0) result = `${_hour}h`;

    const _minus = Math.floor((miliseconds / 1000 / 60 / 60 - _hour) * 60);
    if (_minus > 0) result = `${result} ${_minus}m`;
    // s = Math.floor(((miliseconds/1000/60/60 - h)*60 - m)*60);
    // return `${`0${h}`.slice(-2)}h${`0${m}`.slice(-2)}m`;
    return result || '0h';
}

export const COLOR_BY_PERCENT = {
    power: '#4bb0b2',
    warning: '#fb923c',
    urgency: '#ef4444',
    notYetStatedWorking: '#b1dcde',
    hasDayOff: '#f3f4f6',
};

export const STYLE_BY_STATUS = {
    power: {
        bg: COLOR_BY_PERCENT.power,
        text: '#fff',
    },
    warning: {
        bg: COLOR_BY_PERCENT.warning,
        text: '#fff',
    },
    urgency: {
        bg: COLOR_BY_PERCENT.urgency,
        text: '#fff',
    },
    notYetStatedWorking: {
        bg: COLOR_BY_PERCENT.notYetStatedWorking,
        text: '#4bb0b2',
    },
    hasDayOff: {
        bg: COLOR_BY_PERCENT.hasDayOff,
        text: '#fff',
    },
    stopped: {
        bg: '#f9fafb', //gray-50
        text: '#4b5563', //gray-600
    },
};

export const styleByPercent = (percent: number) => {
    if (percent <= 10) return STYLE_BY_STATUS.urgency;

    if (10 < percent && percent <= 40) return STYLE_BY_STATUS.warning;

    return STYLE_BY_STATUS.power;
};

export const encodeHtml = (str) => {
    return String(str).replace(/[^\w. ]/gi, function (c) {
        return `&#${c.charCodeAt(0)};`;
    });
};
export const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};
export const parseHTML = (html) => {
    const t = document.createElement('span');
    t.innerHTML = html;
    return t.textContent;
};

export const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
};

export const changeElementPosition = (array, index, newPosition) => {
    const arr = cloneDeep(array);
    // Check if the provided index is within the array bounds
    if (index < 0 || index >= arr.length) {
        console.error('Invalid index');
        return;
    }

    // Remove the element from the original position
    const element = arr.splice(index, 1)[0];

    // Insert the element at the new position
    arr.splice(newPosition, 0, element);
    return arr;
};

export const validateUsername = (username) => {
    const regex = /^[a-zA-Z][a-zA-Z0-9_-]{7,30}$/;
    return regex.test(username);
};

export const convertClassToJSON = (classObj) => {
    if (!classObj) return {};

    const jsonObject = {};

    for (const prop in classObj) {
        if (
            Object.prototype.hasOwnProperty.call(classObj, prop) &&
            typeof classObj[prop] !== 'function'
        ) {
            jsonObject[prop] = _cloneDeep(classObj[prop]);
        }
    }

    return jsonObject;
};

export const checkValidUrl = (url) => {
    const validUrlRegex =
        /^((https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*|([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*)(\?.+=.+&)*(\?.+=.+)?$/i;

    return validUrlRegex.test(url);
};

export const getDomainLink = (url) => {
    if (!url) return url;

    // Define a regular expression to extract the domain
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+)/i;

    // Use the regular expression to extract the domain from the URL
    const match = url.match(domainRegex);
    return match ? match[1] : '';
};

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
    return Object.keys(obj1).reduce((result, key) => {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            result.push(key);
        } else if (_isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);

            if (compareRef && obj1[key] !== obj2[key]) {
                result[resultKeyIndex] = `${key} (ref)`;
            } else {
                result.splice(resultKeyIndex, 1);
            }
        }
        return result;
    }, Object.keys(obj2));
};
export const getArrayDiff = (array1, array2) => {
    return _difference(array1, array2);
};

export const convertNameToShortName = (name: string) => {
    const nameArray = name?.split(' ');
    if (nameArray?.length == 1) return name;

    return nameArray
        ?.map((n, index) => {
            return index == nameArray?.length - 1 ? n : n?.charAt(0);
        })
        .join('.');
};

export const getFileUrl = (file) => {
    if (file instanceof File) return URL.createObjectURL(file);
    if (file?.fileBlob instanceof Blob) {
        return URL.createObjectURL(file?.fileBlob);
    }
    if (file?.blob instanceof Blob) {
        return URL.createObjectURL(file?.blob);
    }
    if (file?.base64) return file?.base64;
    if (file?.fileUrl) return file?.fileUrl;
    if (file?.url_full) return file?.url_full;
    if (file?.pathUrl) return file?.pathUrl;
    if (file?.Path) return file?.Path;

    return file?.imagePath || file?.src;
};

export const onLoadImage = (
    file,
    options: {
        onLoaded?: Function;
    }
) => {
    const image = new Image();

    image.onload = () => {
        if (options.onLoaded && typeof options.onLoaded == 'function') {
            options.onLoaded(image);
        }
    };

    image.src = getFileUrl(file);
};

export function roundToNearestMultipleOfFive(number, { min, isRoundUp }) {
    const remainder = number % 5;
    const isCloserToNextMultiple = isRoundUp ? remainder > 2.5 : false;
    const roundedNumber =
        Math.floor(number / 5) * 5 + (isCloserToNextMultiple ? 5 : 0);

    return Math.max(roundedNumber, min);
}

export const getRandomByPercentage = (dataList) => {
    // Tạo một số ngẫu nhiên trong khoảng từ 0 đến 100
    const randomValue = Math.random() * 100;
    let cumulativeSum = 0;

    for (let i = 0; i < dataList.length; i++) {
        cumulativeSum += dataList[i].percent;
        if (randomValue < cumulativeSum) {
            return dataList[i].key;
        }
    }

    // Trường hợp đặc biệt, khi không tìm thấy phần tử phù hợp
    return null;
};
