import dayjs from '@/ui/plugins/dayjs';
import { TaskLife } from '@/domain/enums/taskEnum';
import { sortDefaultTaskList, taskStates } from '@/ui/hooks/taskHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import { EActionOnTask, ETaskFileStatus } from '../types/task/task.types';
import systemConfig from '@/application/constants/system-config.const';
import userStore from '@/store/user';

const linkImg = `https://d1tvqetrcurhzb.cloudfront.net/common/images/`;
export enum ETaskViewType {
    LIST = 'LIST',
    BOARD = 'BOARD',
    GANTT = 'GANTT',
    CALENDAR = 'CALENDAR',
}
export type TabTask = {
    id: EFilterTaskDefault;
    menuTab: ETaskListMenuTab;
    active: boolean;
    name: string;
    key: string;
    labelKey: string;
    code: string;
    value: string;
    color: string;
    totalTask: number;
    isCollapse: boolean;
    isDefault: boolean;
    visible: boolean;
    noDataPage: {
        default: {
            imgUrl: string;
            imgSize: string;
            titleKey: string;
            subTitleKey: string;
        };
        group?: {
            imgUrl: string;
            imgSize: string;
            titleKey: string;
            subTitleKey: string;
        };
    };
};
export enum EFilterTaskDefault {
    DEFAULT = 'DEFAULT',
    DO_TODAY = 'DO_TODAY',
    OVERDUE_DATE = 'OVERDUE_DATE',
    MY_RELATED = 'MY_RELATED',
    FINISHED = 'FINISHED',
    PINNED = 'PINNED',
    OTHERS = 'OTHERS',
    NEED_APPROVAL = 'NEED_APPROVAL',
    APPROVED = 'APPROVED',
}
export enum EFilterTaskBoard {
    DEFAULT = 'DEFAULT',
    BY_URGENCY = 'BY_URGENCY',
    BY_STATUS = 'BY_STATUS',
    PINNED = 'PINNED',
    BY_DEADLINE = 'BY_DEADLINE',
}

export enum ETaskListMenuTab {
    DO_TODAY = 101,
    OVERDUE_DATE = 102,
    FINISHED = 103,
    MY_RELATED = 104,
    OTHERS = 105,
    PINNED = 106,
}

export const LIST_FILTER_TASK_DEFAULT = {
    [EFilterTaskDefault.DEFAULT]: {
        id: EFilterTaskDefault.DEFAULT,
        menuTab: null,
        active: true,
        name: translate('TASK_LIST_VIEW_ALL_LABEL'),
        key: 'All',
        labelKey: 'TASK_LIST_VIEW_ALL_LABEL',
        value: '3',
        color: '#008E8E',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}all-task-mylist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}all-task-grouplist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.DO_TODAY]: {
        id: EFilterTaskDefault.DO_TODAY,
        menuTab: ETaskListMenuTab.DO_TODAY,
        active: false,
        key: 'DoToday',
        name: translate('TASK_LIST_VIEW_DO_TODAY_LABEL'),
        labelKey: 'TASK_LIST_VIEW_DO_TODAY_LABEL',
        value: '0',
        color: '#1D7FFB',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}do-today-task.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_TODAY_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_TODAY_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}do-today-group-task.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_TODAY_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_TODAY_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.OVERDUE_DATE]: {
        id: EFilterTaskDefault.OVERDUE_DATE,
        menuTab: ETaskListMenuTab.OVERDUE_DATE,
        active: false,
        name: translate('TASK_LIST_VIEW_OVERDUE_DATE_LABEL'),
        key: 'OverdueDate',
        labelKey: 'TASK_LIST_VIEW_OVERDUE_DATE_LABEL',
        value: '1',
        color: '#EF4444',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}overdue_task_1.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}overdue_task_1.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_OVERDUE_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.MY_RELATED]: {
        id: EFilterTaskDefault.MY_RELATED,
        menuTab: ETaskListMenuTab.MY_RELATED,
        active: false,
        name: translate('TASKTAB_LABEL_MY_RELATED'),
        key: 'OverdueDate',
        labelKey: 'TASKTAB_LABEL_RELATED',
        value: '4',
        color: '#0ea5e9',
        totalTask: 0,
        isDefault: true,
        visible: false,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}all-task-mylist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}all-task-grouplist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.FINISHED]: {
        id: EFilterTaskDefault.FINISHED,
        menuTab: ETaskListMenuTab.FINISHED,
        active: false,
        name: translate('TASK_LIST_VIEW_FINISHED_LABEL'),
        key: 'Finished',
        labelKey: 'TASK_LIST_VIEW_FINISHED_LABEL',
        value: '2',
        color: '#22C55E',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}finished_task_1.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_FINISHED_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_FINISHED_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}finished_task_1.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_FINISHED_TASK_GROUP_TITLE',
                subTitleKey:
                    'TASK_LIST_DONT_HAVE_FINISHED_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.PINNED]: {
        id: EFilterTaskDefault.PINNED,
        menuTab: ETaskListMenuTab.PINNED,
        active: false,
        name: translate('COMMON_LABEL_PIN'),
        key: 'Pinned',
        labelKey: 'COMMON_LABEL_PIN',
        value: '2',
        color: '#ec4899',
        totalTask: 0,
        isDefault: true,
        visible: false,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}all-task-mylist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}all-task-grouplist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE',
            },
        },
    },
    [EFilterTaskDefault.OTHERS]: {
        id: EFilterTaskDefault.OTHERS,
        menuTab: ETaskListMenuTab.OTHERS,
        active: false,
        name: translate('TASK_LIST_VIEW_LABEL_OTHER'),
        key: 'Others',
        labelKey: 'TASK_LIST_VIEW_LABEL_OTHER',
        value: '5',
        color: '#f97316',
        totalTask: 0,
        isDefault: true,
        visible: false,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}all-task-mylist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_SUB_TITLE',
            },
            group: {
                imgUrl: `${linkImg}all-task-grouplist.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_TITLE',
                subTitleKey: 'TASK_LIST_DONT_HAVE_ALL_TASK_GROUP_SUB_TITLE',
            },
        },
    },
};

export const LIST_FILTER_TASK_BOARD_DEFAULT = [
    {
        id: EFilterTaskBoard.DEFAULT,
        active: true,
        labelKey: 'TASK_LIST_VIEW_BY_DEFAULT',
        color: '#008E8E',
    },
    {
        id: EFilterTaskBoard.BY_URGENCY,
        active: false,
        labelKey: 'TASK_LIST_VIEW_BY_URGENCY',
        color: '#3b82f6',
    },
    {
        id: EFilterTaskBoard.BY_STATUS,
        active: false,
        labelKey: 'TASK_LIST_VIEW_BY_STATUS',
        color: '#eab308',
    },
    {
        id: EFilterTaskBoard.BY_DEADLINE,
        active: false,
        labelKey: 'TASK_TABLE_LABEL_DEADLINE',
        color: '#22c55e',
    },
    {
        id: EFilterTaskBoard.PINNED,
        active: false,
        labelKey: 'COMMON_LABEL_PIN',
        color: '#ec4899',
    },
];

export interface ITaskSoftLocal {
    index: number;
    key:
        | 'DEFAULT'
        | 'STATUS'
        | 'PLAN_TODAY'
        | 'URGENCY'
        | 'NAME'
        | 'DOMAIN'
        | 'SCHEDULE'
        | 'CREATED_DATE'
        | 'LAST_MODIFIED_DATE';
    nameCode: string;
    orderData: { iterate: any[]; order: string[] };
}

export const TASK_SORT_BY = {
    DEFAULT: {
        index: 0,
        key: 'DEFAULT',
        nameCode: 'TASK_LIST_ORDER_BY_DEFAULT',
        orderBy: 'asc',
        orderData: sortDefaultTaskList,
    },
    STATUS: {
        index: 1,
        key: 'STATUS',
        nameCode: 'TASK_LIST_ORDER_BY_STATUS',
        orderBy: 'asc',
        orderData: {
            iterate: [
                (t) => {
                    return t.taskLife == TaskLife.InProcess
                        ? 1
                        : t.taskLife == TaskLife.Todo
                        ? 2
                        : t.taskLife == TaskLife.Created
                        ? 3
                        : t.taskLife == TaskLife.Finished
                        ? 4
                        : taskStates[t.taskLife]?.index + 4;
                },
            ],
            order: ['asc'],
        },
    },
    PLAN_TODAY: {
        index: 2,
        key: 'PLAN_TODAY',
        nameCode: 'TASK_LIST_ORDER_BY_DATE',
        orderBy: 'asc',
        orderData: {
            iterate: [
                (task) => {
                    return task?.taskSchedule?.finishItToday
                        ? 1
                        : task?.taskSchedule?.doItToday
                        ? 2
                        : 3;
                },
            ],
            order: ['asc'],
        },
    },
    URGENCY: {
        index: 3,
        key: 'URGENCY',
        nameCode: 'TASK_LIST_ORDER_BY_PRIORITY',
        orderBy: 'asc',
        orderData: {
            iterate: ['urgency'],
            order: ['asc'],
        },
    },
    NAME: {
        index: 4,
        key: 'NAME',
        nameCode: 'TASK_LIST_ORDER_BY_NAME',
        orderBy: 'asc',
        orderData: {
            iterate: ['name'],
            order: ['asc'],
        },
    },
    DOMAIN: {
        index: 5,
        key: 'DOMAIN',
        nameCode: 'TASK_LIST_ORDER_BY_DOMAIN',
        orderBy: 'asc',
        orderData: {
            iterate: ['domainId'],
            order: ['asc'],
        },
    },
    ASSIGNEE: {
        index: 6,
        key: 'ASSIGNEE',
        nameCode: 'COMMON_LABEL_ASSIGNEE',
        orderBy: 'asc',
        orderData: {
            iterate: [
                (t) => {
                    if (!t?.assigneeId) return '';
                    return userStore().allUserByIds[t?.assigneeId]?.name;
                },
            ],
            order: ['asc'],
        },
    },
    START_DATE: {
        index: 7,
        key: 'START_DATE',
        nameCode: 'DOMAIN_LABEL_START_DATE',
        orderBy: 'asc',
        orderData: {
            iterate: [
                (t) => {
                    return t?.startTime ? t?.startTime : t?.scheduleTime;
                },
            ],
            order: ['asc'],
        },
    },
    SCHEDULE: {
        index: 8,
        key: 'SCHEDULE',
        nameCode: 'TASK_LIST_ORDER_BY_SCHEDULE',
        orderBy: 'asc',
        orderData: {
            iterate: [
                (t) => {
                    return t?.scheduleTime ? t?.scheduleTime : t?.startTime;
                },
            ],
            order: ['asc'],
        },
    },
    CREATED_DATE: {
        index: 9,
        key: 'CREATED_DATE',
        nameCode: 'TASK_LIST_ORDER_BY_CREATED_DATE',
        orderBy: 'asc',
        orderData: {
            iterate: ['creationTime'],
            order: ['desc'],
        },
    },
    LAST_MODIFIED_DATE: {
        index: 10,
        key: 'LAST_MODIFIED_DATE',
        nameCode: 'TASK_LIST_ORDER_BY_LAST_MODIFIED_DATE',
        orderBy: 'asc',
        orderData: {
            iterate: ['lastModificationTime'],
            order: ['desc'],
        },
    },
};

export enum ETaskUrgency {
    UrgentImportant = 4,
    Urgent = 3,
    Important = 2,
    Cool = 1,
}

export const TASK_URGENCY = {
    [ETaskUrgency.UrgentImportant]: {
        key: 4,
        text: 'URGENT & IMPORTANT',
        textCode: 'TASK_CREATE_FORM_URGENT_IMPORTANT',
        data: {
            priority: 0,
            important: true,
        },
    },
    [ETaskUrgency.Urgent]: {
        key: 3,
        text: 'URGENT',
        textCode: 'TASK_CREATE_FORM_URGENT',
        data: {
            priority: 0,
            important: false,
        },
    },
    [ETaskUrgency.Important]: {
        key: 2,
        text: 'IMPORTANT',
        textCode: 'TASK_CREATE_FORM_IMPORTANT',
        data: {
            priority: 1, // 1 || 2
            important: true,
        },
    },
    [ETaskUrgency.Cool]: {
        key: 1,
        text: 'COOL',
        textCode: 'TASK_CREATE_FORM_COOL',
        data: {
            priority: 1, // 1 || 2
            important: false,
        },
    },
};
export const TASK_PIN_BY_CODE = {
    UN_PIN: {
        code: 'UN_PIN',
        color: '#4b5563',
        type: 'PIN',
    },
    PIN_GRAY: {
        code: 'PIN_GRAY',
        color: '#1f2937',
        type: 'PIN',
    },
    PIN_RED: {
        code: 'PIN_RED',
        color: '#ff0000',
        type: 'PIN',
    },
    PIN_GREEN: {
        code: 'PIN_GREEN',
        color: '#22c55e',
        type: 'PIN',
    },
    PIN_BLUE: {
        code: 'PIN_BLUE',
        color: '#3b82f6',
        type: 'PIN',
    },
    PIN_YELLOW: {
        code: 'PIN_YELLOW',
        color: '#eab308',
        type: 'PIN',
    },
    PIN_PINK: {
        code: 'PIN_PINK',
        color: '#ff69b4',
        type: 'PIN',
    },
};

export enum ETaskByDeadline {
    NO_SCHEDULE = 'NO_SCHEDULE',
    OVER_A_WEEK_AGO = 'OVER_A_WEEK_AGO',
    LAST_WEEK = 'LAST_WEEK',
    YESTERDAY = 'YESTERDAY',
    TODAY = 'TODAY',
    TOMORROW = 'TOMORROW',
    NEXT_WEEK = 'NEXT_WEEK',
    MORE_THAN_A_WEEK = 'MORE_THAN_A_WEEK',
}

export const _getLastWeekPeriod = () => {
    const currentDate = new Date();

    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setUTCDate(currentDate.getUTCDate() - 7);

    const yesterday = new Date(currentDate);
    yesterday.setUTCDate(currentDate.getUTCDate() - 1);

    return {
        startDate: sevenDaysAgo.toUTCString(),
        endDate: yesterday.toUTCString(),
    };
};

export const _getNextWeekPeriod = () => {
    const currentDate = new Date();

    const tomorrow = new Date(currentDate);
    tomorrow.setUTCDate(currentDate.getUTCDate() + 1);

    const endDate = new Date(currentDate);
    endDate.setUTCDate(currentDate.getUTCDate() + 7);

    return {
        startDate: tomorrow.toUTCString(),
        endDate: endDate.toUTCString(),
    };
};

const RETURN_DATE_FORMAT = 'YYYY-MM-DD';

export const TaskByDeadlineByKey = {
    [ETaskByDeadline.NO_SCHEDULE]: {
        index: 0,
        nameCode: 'TASK_DETAIL_LABEL_NO_SCHEDULE',
        name: 'No schedule',
        key: ETaskByDeadline.NO_SCHEDULE,
        colorHex: '#6b7280',
        filterFunc: (scheduleTime) => {
            return (
                !scheduleTime ||
                scheduleTime == '' ||
                new Date(scheduleTime).getTime() == 0
            );
        },
        scheduleTime: null,
        filterObject: {
            isNoSchedule: true,
        },
    },
    [ETaskByDeadline.OVER_A_WEEK_AGO]: {
        index: 1,
        nameCode: 'COMMON_LABEL_OVER_A_WEEK_AGO',
        name: 'Over a week ago',
        key: ETaskByDeadline.OVER_A_WEEK_AGO,
        colorHex: '#ef4444',
        filterFunc: (scheduleTime) => {
            const lastWeekPeriod = _getLastWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isBefore(lastWeekPeriod.startDate, 'day')
            );
        },
        scheduleTime: dayjs(_getLastWeekPeriod().startDate)
            .add(-1, 'day')
            .format(RETURN_DATE_FORMAT),
        filterObject: {
            isNoSchedule: false,
            deadlineToDate: dayjs(_getLastWeekPeriod().startDate)
                .add(-1, 'day')
                .utc(),
        },
    },
    [ETaskByDeadline.LAST_WEEK]: {
        index: 2,
        nameCode: 'COMMON_LABEL_LAST_WEEK',
        descriptionCode: '',
        name: 'Last week',
        key: ETaskByDeadline.LAST_WEEK,
        colorHex: '#ef4444',
        filterFunc: (scheduleTime) => {
            const lastWeekPeriod = _getLastWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isBetween(
                    lastWeekPeriod.startDate,
                    lastWeekPeriod.endDate,
                    'day',
                    '[)'
                )
            );
        },
        scheduleTime: dayjs(_getLastWeekPeriod().endDate)
            .add(-1, 'd')
            .format(RETURN_DATE_FORMAT),
        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs(_getLastWeekPeriod().startDate).utc(),
            deadlineToDate: dayjs(_getLastWeekPeriod().endDate).utc(),
        },
    },
    [ETaskByDeadline.YESTERDAY]: {
        index: 3,
        nameCode: 'COMMON_LABEL_YESTERDAY',
        name: 'Yesterday',
        key: ETaskByDeadline.YESTERDAY,
        colorHex: '#ef4444',
        filterFunc: (scheduleTime) => {
            const lastWeekPeriod = _getLastWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isSame(lastWeekPeriod.endDate, 'day')
            );
        },
        scheduleTime: dayjs(_getLastWeekPeriod().endDate).format(
            RETURN_DATE_FORMAT
        ),
        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs().add(-1, 'd').utc(),
            deadlineToDate: dayjs().add(-1, 'd').utc(),
        },
    },
    [ETaskByDeadline.TODAY]: {
        index: 4,
        nameCode: 'COMMON_LABEL_TODAY',
        name: 'Today',
        key: ETaskByDeadline.TODAY,
        colorHex: '#018180',
        filterFunc: (scheduleTime) => {
            return (
                scheduleTime && dayjs(scheduleTime).isSame(new Date(), 'day')
            );
        },
        scheduleTime: dayjs().format(RETURN_DATE_FORMAT),
        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs().utc(),
            deadlineToDate: dayjs().utc(),
        },
    },
    [ETaskByDeadline.TOMORROW]: {
        index: 5,
        nameCode: 'COMMON_LABEL_TOMORROW',
        name: 'Tomorrow',
        key: ETaskByDeadline.TOMORROW,
        colorHex: '#22c55e',
        filterFunc: (scheduleTime) => {
            const nextWeekPeriod = _getNextWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isSame(nextWeekPeriod.startDate, 'day')
            );
        },
        scheduleTime: dayjs().add(1, 'd').format(RETURN_DATE_FORMAT),
        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs().add(1, 'd').utc(),
            deadlineToDate: dayjs().add(1, 'd').utc(),
        },
    },
    [ETaskByDeadline.NEXT_WEEK]: {
        index: 6,
        nameCode: 'COMMON_LABEL_NEXT_WEEK',
        name: 'Next week',
        key: ETaskByDeadline.NEXT_WEEK,
        colorHex: '#22c55e',
        filterFunc: (scheduleTime) => {
            const nextWeekPeriod = _getNextWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isBetween(
                    nextWeekPeriod.startDate,
                    nextWeekPeriod.endDate,
                    'day',
                    '(]'
                )
            );
        },
        scheduleTime: dayjs(_getNextWeekPeriod().startDate)
            .add(1, 'd')
            .format(RETURN_DATE_FORMAT),
        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs(_getNextWeekPeriod().startDate).utc(),
            deadlineToDate: dayjs(_getNextWeekPeriod().endDate).utc(),
        },
    },
    [ETaskByDeadline.MORE_THAN_A_WEEK]: {
        index: 7,
        nameCode: 'COMMON_LABEL_MORE_THAN_A_WEEK',
        name: 'More than a week',
        key: ETaskByDeadline.MORE_THAN_A_WEEK,
        colorHex: '#22c55e',
        filterFunc: (scheduleTime) => {
            const nextWeekPeriod = _getNextWeekPeriod();
            return (
                scheduleTime &&
                dayjs(scheduleTime).isAfter(nextWeekPeriod.endDate, 'day')
            );
        },
        scheduleTime: dayjs(_getNextWeekPeriod().endDate)
            .add(1, 'd')
            .format(RETURN_DATE_FORMAT),

        filterObject: {
            isNoSchedule: false,
            deadlineFromDate: dayjs(_getNextWeekPeriod().endDate).utc(),
        },
    },
};

export const TaskStatusByTaskLife = {
    0: 'CREATED',
    1: 'IN_PROCESS',
    2: 'PENDING',
    3: 'FINISHED',
    4: 'ARCHIVED',
    5: 'CANCELLED',
    6: 'DUPLICATED',
    7: 'TODO',
    8: 'DRAFT',
};

export const ATTACHMENTS_ICON_LIST = [
    {
        key: 'SUB_TASK',
        titleKey: 'COMMON_LABEL_SUB_TASK',
        index: -1,
        iconName: 'SubTask',
        color: '#008e8e',
        isActive: true,
        total: 5,
    },
    {
        key: 'APPROVAL',
        titleKey: 'COMMON_LABEL_APPROVAL',
        index: 0,
        iconName: 'approval',
        color: '#008e8e',
        isActive: true,
        total: 0,
    },
    {
        key: 'WORKFLOW',
        titleKey: 'TASK_ACTION_WORK_FLOW',
        index: 1,
        iconName: 'flow',
        color: '#008e8e',
        isActive: true,
        total: 0,
    },
    {
        key: 'FILE',
        titleKey: 'GED_FILTER_FILES',
        index: 2,
        iconName: 'attach',
        color: '#3b82f6',
        isActive: true,
        total: 0,
    },
    {
        key: 'NOTE',
        titleKey: 'COMMON_LABEL_NOTES',
        index: 3,
        iconName: 'noter',
        color: '#f97316',
        isActive: true,
        total: 0,
    },
    {
        id: `TASK_ATTACHMENT_DETAIL__TODO_LIST`,
        key: 'CHECKLIST',
        titleKey: 'COMMON_LABEL_CHECKLIST',
        index: 4,
        iconName: 'TodoList',
        color: '#008e8e',
        isActive: true,
        total: 0,
    },
    {
        id: `TASK_ATTACHMENT_DETAIL__DESCRIPTION`,
        key: 'DESCRIPTION',
        titleKey: 'TASK_EXPORT_COLUMNS_DESCRIPTION',
        index: 5,
        iconName: 'comment',
        color: '#128f90',
        isActive: true,
        total: 0,
    },
    {
        id: `TASK_ATTACHMENT_DETAIL__COMMENT`,
        key: 'COMMENT',
        titleKey: 'TASK_CREATE_FORM_LABEL_COMMENT',
        index: 6,
        iconName: 'message',
        color: '#6b7280',
        isActive: false,
        total: 0,
    },
];

export const GANTT_TABLE_COLUMN_LIST = {
    ASSIGNEE: {
        key: 'ASSIGNEE',
        isHiddenDefault: false,
        visible: true,
        name: 'ASSIGNEE',
        nameCode: 'TASK_LABEL_ASSIGNEE',
        orderKey: 'ASSIGNEE',
    },
    TASK_LIFE: {
        key: 'TASK_LIFE',
        isHiddenDefault: false,
        visible: true,
        name: 'TASK_LIFE',
        nameCode: 'TASK_CREATE_FORM_LIFE',
        orderKey: 'STATUS',
    },
    URGENCY: {
        key: 'URGENCY',
        isHiddenDefault: false,
        visible: true,
        name: 'URGENCY',
        nameCode: 'TASK_TABLE_LABEL_URGENCY',
        orderKey: 'URGENCY',
    },
    START_TIME: {
        key: 'START_TIME',
        isHiddenDefault: false,
        visible: false,
        name: 'START_TIME',
        nameCode: 'DOMAIN_LABEL_START_DATE',
        orderKey: 'START_DATE',
    },
    SCHEDULE_TIME: {
        key: 'SCHEDULE_TIME',
        isHiddenDefault: false,
        visible: false,
        name: 'SCHEDULE_TIME',
        nameCode: 'TASK_TABLE_LABEL_DEADLINE',
        orderKey: 'SCHEDULE',
    },
};

/*
functionCode : check permission by payment state
permissionList: check permission by system configs
*/
export const TASK_ACTION_LIST_DEFAULT = [
    {
        index: 0,
        quickActionIndex: 1,
        key: EActionOnTask.FINISH,
        functionCode: null,
        iconName: 'Check',
        labelKey: 'COMMON_LABEL_FINISH',
        permissionList: [],
        needCheckPermissionOnTask: true,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 1,
        quickActionIndex: 1,
        key: EActionOnTask.DISCUSS,
        functionCode: ALL_FUNCTIONS.TASK.DISCUSS,
        iconName: 'Message',
        labelKey: 'TASK_DETAIL_CHAT_ABOUT',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 2,
        quickActionIndex: 2,
        key: EActionOnTask.REMINDER_TASK,
        functionCode: ALL_FUNCTIONS.TASK.REMINDER,
        iconName: 'ClockHistory',
        labelKey: 'TASK_DETAIL_REMINDER_ME',
        permissionList: [],
        needCheckPermissionOnTask: true,
        hasQuickAction: true,
        isIgnoreReadonly: false,
    },
    {
        index: 3,
        quickActionIndex: 3,
        key: EActionOnTask.CLONE,
        functionCode: ALL_FUNCTIONS.TASK.DUPLICATE,
        iconName: 'duplicate',
        labelKey: 'COMMON_LABEL_CLONE',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: true,
        isIgnoreReadonly: false,
    },
    {
        index: 4,
        quickActionIndex: 4,
        key: EActionOnTask.SET_REPEAT,
        functionCode: ALL_FUNCTIONS.TASK.REPEAT,
        iconName: 'recurrence',
        labelKey: 'COMMON_LABEL_MAKE_RECURRING',
        permissionList: [],
        needCheckPermissionOnTask: true,
        hasQuickAction: true,
        isIgnoreReadonly: false,
    },
    {
        index: 5,
        quickActionIndex: 0,
        key: EActionOnTask.WORKFLOW,
        functionCode: ALL_FUNCTIONS.TASK.WORKFLOW,
        iconName: 'flow',
        labelKey: 'COMMON_LABEL_WORKFLOW_TASK',
        permissionList: [
            {
                functionCode: 'ADVANCED_WORKFLOW_WORKFLOW_ALLOWED_BY_SYSTEM',
                functionValue: '1',
            },
            {
                functionCode: 'DRAFT_WORKFLOW_WORKFLOW_ALLOWED',
                functionValue: '1',
            },
        ],
        needCheckPermissionOnTask: true,
        hasQuickAction: true,
        isIgnoreReadonly: false,
    },
    {
        index: 6,
        quickActionIndex: 6,
        key: EActionOnTask.COPY_LINK,
        functionCode: null,
        iconName: 'link',
        labelKey: 'TASK_DETAIL_COPY_LINK',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 7,
        quickActionIndex: 7,
        key: EActionOnTask.SHARE,
        functionCode: null,
        iconName: 'Share',
        labelKey: 'TASK_ACTION_SHARING',
        permissionList: [],
        needCheckPermissionOnTask: true,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 8,
        quickActionIndex: 8,
        key: EActionOnTask.SEND_TO_WHITEBOARD,
        functionCode: null,
        iconName: 'desktop',
        labelKey: 'COMMON_LABEL_SEND_TO_WHITEBOARD',
        permissionList: [
            {
                functionCode: systemConfig.WHITEBOARD_FUNCTION,
                functionValue: '1',
            },
        ],
        needCheckPermissionOnTask: true,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 9,
        quickActionIndex: 9,
        key: EActionOnTask.SNAPSHOT,
        functionCode: null,
        iconName: 'Snapshot',
        labelKey: 'TASK_SNAPSHOTS',
        permissionList: [],
        needCheckPermissionOnTask: true,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 10,
        quickActionIndex: 99,
        key: EActionOnTask.PIN,
        functionCode: null,
        iconName: 'pin',
        labelKey: 'CHAT_PIN',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 11,
        quickActionIndex: 10,
        key: EActionOnTask.APPROVAL,
        functionCode: ALL_FUNCTIONS.TASK.APPROVAL,
        iconName: 'approval',
        labelKey: 'TASK_LABEL_MASK_AS_NEED_APPROVAL',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 12,
        quickActionIndex: 11,
        key: EActionOnTask.LOCK_UPDATE_DEADLINE,
        functionCode: ALL_FUNCTIONS.TASK.LOCK_UPDATE_DEADLINE,
        iconName: 'LockDeadline',
        labelKey: 'TASK_LABEL_LOCK_UPDATE_DEADLINE',
        permissionList: [
            {
                functionCode:
                    systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED_SYSTEM,
                functionValue: '1',
            },
            {
                functionCode:
                    systemConfig.ADVANCED_TIME_ADV_DEADLINE_TIME_LOCKING_ALLOWED,
                functionValue: '1',
            },
        ],
        needCheckPermissionOnTask: false,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 13,
        quickActionIndex: 12,
        key: EActionOnTask.ASSIGN_USER_LIST,
        functionCode: ALL_FUNCTIONS.TASK.ASSIGN_USER_LIST,
        iconName: 'SubTask',
        labelKey: 'TASK_LABEL_ASSIGN_USER_LIST',
        permissionList: [
            {
                functionCode:
                    systemConfig.ADVANCED_ASSIGNEE_SYSTEM_ENABLE_AUTOMATIC_CREATI,
                functionValue: '1',
            },
            {
                functionCode:
                    systemConfig.ADVANCED_ASSIGNEE_ENABLE_AUTOMATIC_CREATION_OF_S,
                functionValue: '1',
            },
        ],
        needCheckPermissionOnTask: false,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 14,
        quickActionIndex: 13,
        key: EActionOnTask.PROVIDE_EVIDENCE_WHEN_FINISH,
        functionCode: ALL_FUNCTIONS.TASK.PROVIDE_EVIDENCE_WHEN_FINISH,
        iconName: 'Evidence',
        labelKey: 'TASK_LABEL_FINISH_WITH_EVIDENCE',
        permissionList: [
            {
                functionCode:
                    systemConfig.ADVANCED_COMPLETI_COMPLETION_PROOF_ACTIVATED,
                functionValue: '1',
            },
            {
                functionCode:
                    systemConfig.ADVANCED_COMPLETI_SYSTEM_COMPLETION_PROOF_ACTIVA,
                functionValue: '1',
            },
        ],
        needCheckPermissionOnTask: true,
        hasQuickAction: false,
        isIgnoreReadonly: false,
    },
    {
        index: 97,
        quickActionIndex: 97,
        key: EActionOnTask.VIEW_DETAIL,
        functionCode: null,
        iconName: 'info',
        labelKey: 'COMMON_LABEL_VIEW_DETAIL',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: false,
        isIgnoreReadonly: true,
    },
    {
        index: 98,
        quickActionIndex: 11,
        key: EActionOnTask.RELOAD,
        functionCode: null,
        iconName: 'reload2',
        labelKey: 'TASK_LIST_LABEL_RELOAD',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: true,
        isIgnoreReadonly: true,
    },
    {
        index: 99,
        quickActionIndex: 99,
        key: EActionOnTask.EXPAND,
        functionCode: null,
        iconName: 'expand3',
        labelKey: 'TASK_LABEL_VIEW_TASK_IN_MODAL_MODE',
        permissionList: [],
        needCheckPermissionOnTask: false,
        hasQuickAction: false,
        isIgnoreReadonly: true,
    },
];

export const FILTER_APPROVAL_TASK_LIST = {
    [EFilterTaskDefault.DEFAULT]: {
        id: EFilterTaskDefault.DEFAULT,
        active: true,
        name: translate('TASK_LABEL_WAITING_FOR_APPROVAL'),
        key: 'WAITING_FOR_APPROVAL',
        labelKey: 'TASK_LABEL_WAITING_FOR_APPROVAL',
        value: '3',
        color: '#f97316',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}waiting-approval.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_NO_DATA_PAGE_WAITING_APPROVAL',
                subTitleKey: '',
            },
        },
    },
    [EFilterTaskDefault.NEED_APPROVAL]: {
        id: EFilterTaskDefault.NEED_APPROVAL,
        active: false,
        key: 'NEED_APPROVAL',
        name: translate('WORKFLOW_LABEL_NEED_APPROVAL'),
        labelKey: 'WORKFLOW_LABEL_NEED_APPROVAL',
        value: '0',
        color: '#4b5563',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}need-approval.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_NO_DATA_PAGE_NEED_APPROVAL',
                subTitleKey: '',
            },
        },
    },
    [EFilterTaskDefault.APPROVED]: {
        id: EFilterTaskDefault.APPROVED,
        active: false,
        name: translate('TASK_LABEL_APPROVED'),
        key: 'APPROVED',
        labelKey: 'TASK_LABEL_APPROVED',
        value: '1',
        color: '#008e8e',
        totalTask: 0,
        isDefault: true,
        visible: true,
        noDataPage: {
            default: {
                imgUrl: `${linkImg}approved.png`,
                imgSize: 'h-40 w-auto',
                titleKey: 'TASK_LIST_NO_DATA_PAGE_APPROVED',
                subTitleKey: '',
            },
        },
    },
};

export const ATTACHMENT_STATUS_BY_KEY = {
    [ETaskFileStatus.MARK_BLACK]: {
        key: ETaskFileStatus.MARK_BLACK,
        iconName: 'tick',
        iconColor: 'black',
    },
    [ETaskFileStatus.MARK_RED]: {
        key: ETaskFileStatus.MARK_RED,
        iconName: 'tick',
        iconColor: 'red',
    },
    [ETaskFileStatus.MARK_GREEN]: {
        key: ETaskFileStatus.MARK_GREEN,
        iconName: 'tick',
        iconColor: 'green',
    },
    [ETaskFileStatus.MARK_BLUE]: {
        key: ETaskFileStatus.MARK_BLUE,
        iconName: 'tick',
        iconColor: 'blue',
    },
    [ETaskFileStatus.MARK_YELLOW]: {
        key: ETaskFileStatus.MARK_YELLOW,
        iconName: 'tick',
        iconColor: 'yellow',
    },
    [ETaskFileStatus.MARK_PINK]: {
        key: ETaskFileStatus.MARK_PINK,
        iconName: 'tick',
        iconColor: 'pink',
    },
};
