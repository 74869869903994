<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, inject } from 'vue';
import GridViewCard from '@/ui/modules/overview/GridViewCard.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
// import TaskCreateLocal from '@/ui/modules/task/create/TaskCreateLocal.vue';
import {
  onListenEventFirebase,
  getOrganizationPathFirebase,
} from '@/ui/plugins/firebases/firebaseRealtimeDatabase';
import domainStore from '@/store/scope';
import overviewTeamStore from '@/store/dashboard/overview-team-store';
import departmentStore from '@/store/department';
import myProfileStore from '@/store/auth/my-profile';
import settingStore from '@/store/setting';
import userStore from '@/store/user';
import { AdminType } from '@/ui/common/constants/constant';
import { currentActiveDepartmentId } from './overview-member-state';
import organizationStore from '@/store/organization';
import RequestNotificationPermission from '@/ui/modules/systems/brower-permissions/RequestNotificationPermission.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import myProfile from '@/store/auth/my-profile';

const props = defineProps<{
  departmentId?: number;
}>();

const _domainStore = domainStore();
const _overviewTeamStore = overviewTeamStore();
const _organizationStore = organizationStore();
const _departmentStore = departmentStore();
const _myProfileStore = myProfileStore();
const _settingStore = settingStore();
const _userStore = userStore();
const isHiddenBlockPage: any = inject('IS_HIDDEN_BLOCK_PAGE');

const myDepartment = computed(() => _myProfileStore.myDepartment);
const departmentManagementEnabled = computed(
  () => _settingStore._organizationSetting.departmentManagement.enabled
);
const visibleForAllDepartmentIds = computed(() => {
  return myDepartment.value?.departmentId
    ? [
        myDepartment.value?.departmentId,
        ..._departmentStore.visibleForAllDepartmentIds?.filter(
          (dId) => dId !== myDepartment.value?.departmentId
        ),
      ]
    : [..._departmentStore.visibleForAllDepartmentIds];
});
const allDepartmentByIds = computed(() => _departmentStore.allDepartmentByIds);
const isLoading = computed(() => _overviewTeamStore.isLoading);
const invitingUsers = computed(() => _organizationStore._pendingUsers);
const initData = async () => {
  const allDomain = _domainStore.allDomains;
  if (allDomain.length == 0) _domainStore.fetchAllDomainProjects();

  let promises: any[] = [
    _overviewTeamStore.getOverviewTeam(props.departmentId),
  ];
  if (myProfile().isAdmin || myProfile().isOwner)
    promises = [...promises, _organizationStore.getPendingUsers()];
  await Promise.all(promises);
  checkHaveNotDepartment();
};
onMounted(() => {
  currentActiveDepartmentId.value = props.departmentId
    ? props.departmentId
    : myDepartment.value?.departmentId
    ? myDepartment.value?.departmentId
    : -1;
});

const isShowHaveNotDepartmentTab = ref(false);
const checkHaveNotDepartment = () => {
  if (!departmentManagementEnabled.value || _myProfileStore.isVisitor) return;

  if (!myDepartment.value?.departmentId) {
    isShowHaveNotDepartmentTab.value = true;
    return;
  }

  if (_myProfileStore.isAdmin) {
    const hasNotDepartmentUsers = _userStore.allActiveUsers?.filter((user) => {
      return user?.departmentIds?.length == 0;
    });

    isShowHaveNotDepartmentTab.value =
      hasNotDepartmentUsers?.length > 0 || invitingUsers.value?.length > 0;
  }
  if (_myProfileStore.isUser) {
    const hasNotDepartmentUsers = _userStore.allActiveUsers?.filter((user) => {
      return (
        user?.departmentIds?.length > 0 && user?.adminType !== AdminType.Visitor
      );
    });

    isShowHaveNotDepartmentTab.value = hasNotDepartmentUsers?.length > 0;
  }
};

const assigneeId = ref(getCurrentUserId());

initData();

const taskCreateLocalRef = ref(null as any);
const openCreateTaskForUser = (userId) => {
  assigneeId.value = userId;
  taskCreateLocalRef.value?.onOpenInterface();
};

// list member realtime
let lastUpdateTime = 0;
let unscribleFbLatestOverviewActivity;
const onFirebase = () => {
  const path = getOrganizationPathFirebase('latestMemberActivity');
  if (!path) return;
  unscribleFbLatestOverviewActivity = onListenEventFirebase(
    path,
    (snapshot) => {
      const snapshotData: {
        actionAt: number;
        actionType: 'INIT' | 'START' | 'PAUSE' | 'CONTINUE' | 'STOP';
        departmentId: number;
        userId: number;
      } = snapshot.val();
      if (lastUpdateTime == 0 || lastUpdateTime > snapshotData?.actionAt) {
        lastUpdateTime = snapshotData?.actionAt;
        return;
      }
      lastUpdateTime = snapshotData?.actionAt;

      if (
        currentActiveDepartmentId.value &&
        snapshotData.departmentId !== currentActiveDepartmentId.value
      )
        return;

      _overviewTeamStore.getOverviewTeamInfo(
        currentActiveDepartmentId.value,
        true
      );

      // _groupStore.fetchMyGroups();
    }
  );
};
const offFirebase = () => {
  unscribleFbLatestOverviewActivity && unscribleFbLatestOverviewActivity();
};
onFirebase();
onUnmounted(() => {
  offFirebase();
});

const onChangeDepartment = (departmentId) => {
  currentActiveDepartmentId.value = departmentId;
  _overviewTeamStore.getOverviewTeamInfo(departmentId, false);
};

const isHiddenWorkingStatus = computed(() => {
  if (
    !departmentManagementEnabled.value ||
    currentActiveDepartmentId.value == undefined ||
    currentActiveDepartmentId.value == myDepartment.value?.departmentId ||
    (!myDepartment.value?.departmentId && currentActiveDepartmentId.value == -1)
  )
    return false;
  if (currentActiveDepartmentId.value == -1) return false;

  return !allDepartmentByIds.value[currentActiveDepartmentId.value]
    ?.departmentSetting?.visibleStatusWorking;
});
const isHiddenWorkingTime = computed(() => {
  if (
    !departmentManagementEnabled.value ||
    currentActiveDepartmentId.value == undefined ||
    currentActiveDepartmentId.value == myDepartment.value?.departmentId ||
    (!myDepartment.value?.departmentId && currentActiveDepartmentId.value == -1)
  )
    return false;
  if (currentActiveDepartmentId.value == -1) return false;

  return !allDepartmentByIds.value[currentActiveDepartmentId.value]
    ?.departmentSetting?.visibleTimeWorking;
});
const isHiddenPersonalData = computed(() => {
  if (
    !departmentManagementEnabled.value ||
    currentActiveDepartmentId.value == undefined ||
    currentActiveDepartmentId.value == myDepartment.value?.departmentId ||
    (!myDepartment.value?.departmentId && currentActiveDepartmentId.value == -1)
  )
    return false;
  if (currentActiveDepartmentId.value == -1) return false;

  return !allDepartmentByIds.value[currentActiveDepartmentId.value]
    ?.departmentSetting?.visiblePersonalData;
});
</script>

<template>
  <div
    v-permission-screen="
      isHiddenBlockPage
        ? false
        : {
            screenCode: ALL_SCREENS.USER.OVERVIEW.OVERVIEW,
            isPage: true,
          }
    "
    class="flex flex-col h-full w-full relative"
  >
    <div class="h-full w-full flex flex-col">
      <div
        v-if="
          !departmentId &&
          departmentManagementEnabled &&
          visibleForAllDepartmentIds?.length > 0
        "
        class="flex px-3 py-2 bg-white shadow"
      >
        <div class="flex-center flex-wrap space-x-1 font-medium">
          <div
            v-for="departmentId in visibleForAllDepartmentIds"
            :key="departmentId"
          >
            <div
              class="
                font-medium
                px-3
                py-2
                text-sm
                hover:bg-gray-100
                rounded
                xl:rounded-full
                flex-center
                cursor-pointer
              "
              :class="
                currentActiveDepartmentId == departmentId
                  ? 'bg-current-50 text-current-800 fill-current'
                  : 'fill-gray-500'
              "
              @click="onChangeDepartment(departmentId)"
            >
              <span>
                {{
                  allDepartmentByIds[departmentId]?.name ||
                  $t('COMMON_LABEL_MY_DEPARTMENT')
                }}
              </span>
            </div>
          </div>
          <div
            v-if="
              isShowHaveNotDepartmentTab &&
              visibleForAllDepartmentIds?.length > 0
            "
          >
            <div
              class="
                font-medium
                px-3
                py-2
                text-sm
                hover:bg-gray-100
                rounded
                xl:rounded-full
                flex-center
                cursor-pointer
              "
              :class="
                currentActiveDepartmentId == -1
                  ? 'bg-current-50 text-current-800 fill-current'
                  : 'fill-gray-500'
              "
              @click="onChangeDepartment(-1)"
            >
              <span>
                {{ $t('DEPARTMENT_LABEL_USER_HAS_NOT_DEPARTMENT') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 min-h-0 w-ful h-full relative">
        <SynPageLoading v-if="isLoading" />
        <GridViewCard
          v-else
          :department-id="departmentId"
          :is-hidden-task="
            !departmentId &&
            departmentManagementEnabled &&
            !(
              myDepartment?.departmentId == currentActiveDepartmentId ||
              (!myDepartment?.departmentId && currentActiveDepartmentId == -1)
            )
          "
          :is-hidden-working-status="!departmentId && isHiddenWorkingStatus"
          :is-hidden-working-time="!departmentId && isHiddenWorkingTime"
          :is-hidden-personal-data="!departmentId && isHiddenPersonalData"
          :is-show-pending-users="
            !departmentId &&
            (!departmentManagementEnabled ||
              (departmentManagementEnabled &&
                currentActiveDepartmentId == -1) ||
              visibleForAllDepartmentIds?.length == 0)
          "
          @open-create-task-for-user="openCreateTaskForUser"
        />
      </div>
    </div>

    <!--    <TaskCreateLocal ref="taskCreateLocalRef" :assignee-id="assigneeId" />-->
  </div>

  <RequestNotificationPermission />
</template>
<!-- v-if="numberOfLogins == 1 && isUpdateInfo" -->
