<script setup lang="ts">
import { computed, inject, provide } from 'vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ShapeTaskEntity from '@/ui/components/drawer/entities/ShapeTaskEntity';
import { ShapeType } from '@/ui/components/drawer/schema';

const props = defineProps<{
  shapeEntity: ShapeTaskEntity;
}>();

const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const selectedShapeIds = computed<string[]>(() => {
  return (
    desktopDrawerInjected?.desktopDrawerInstance.value?.selectedShapeIds || []
  );
});

const onMouseDown = (event, type?: 'RESIZE' | 'MOVE') => {
  desktopDrawerInjected?.updateKeepingShape(props.shapeEntity, type || 'MOVE');

  // have group
  if (props.shapeEntity?.groupId) {
    desktopDrawerInjected?.desktopDrawerInstance.value?.setCurrentGroup(
      props.shapeEntity?.groupId
    );
    return;
  }

  if (event?.shiftKey) {
    desktopDrawerInjected?.desktopDrawerInstance.value?.toggleSelectedShapeIds(
      props.shapeEntity
    );
  } else {
    if (selectedShapeIds.value?.length > 1) return;

    desktopDrawerInjected?.desktopDrawerInstance.value?.setSelectedShapeIds(
      props.shapeEntity
    );
  }
};

const needToHasADragControl = computed<boolean>(() => {
  return (
    props.shapeEntity?.type == ShapeType.TASK ||
    props.shapeEntity?.type == ShapeType.TODO_LIST ||
    props.shapeEntity?.type == ShapeType.MEDIA_AUDIO ||
    props.shapeEntity?.type == ShapeType.MEDIA_LINK ||
    props.shapeEntity?.type == ShapeType.MEDIA_VIDEO
  );
});

const onMouseDownContainer = (event) => {
  if (event && props.shapeEntity?.type !== ShapeType.TODO_LIST) {
    event.preventDefault();
    event.stopPropagation();
  }
};

provide(PROVIDER_IDS.DESKTOP_DRAWER_SHAPE, {
  onMouseDown,
} as TDesktopDrawerShape);
</script>
<template>
  <div
    class="tl-shape"
    :style="{
      opacity: 1,
      zIndex: shapeEntity?.zIndex,
      transform: `matrix(1, 0, 0, 1, ${shapeEntity?.translateX}, ${shapeEntity?.translateY})`,
      clipPath: 'none',
      width: `${shapeEntity?.width}px`,
      height: `${shapeEntity?.height}px`,
      pointerEvents: shapeEntity?.type == 'ARROW' ? 'none' : 'all',
    }"
    @mousedown="onMouseDownContainer"
  >
    <div
      class="w-full h-full relative rounded-md drawer-shape-mask"
      :class="{
        'ring-2 ring-current':
          shapeEntity?.type !== ShapeType.ARROW &&
          selectedShapeIds?.some((id) => id == shapeEntity?.id),
      }"
    >
      <div
        v-if="needToHasADragControl"
        class="
          absolute
          -top-6
          w-full
          flex-center
          cursor-move
          drawer-shape-action
        "
        @mousedown.left="onMouseDown"
      >
        <SynIcon
          name="dragable"
          class="-rotate-90"
          custom-class="w-6 h-6 fill-current-500"
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss">
.drawer-shape-action {
  visibility: hidden;
}
.drawer-shape-mask:hover .drawer-shape-action {
  visibility: unset;
}
</style>
