<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { useDraggable } from '@vueuse/core';
import {
  isOpenCreateTaskDrawer,
  isOpenTaskDrawer,
  newTaskDefaultPayload,
  isOpenTaskPinnedDrawer,
  allAutoCreateProcessIntervalByIds,
  taskCreateNewRef,
  isShowTaskDetailGlobal,
} from '@/ui/modules/task/task-global-state';
import TaskDetailV2 from '@/ui/modules/task/detail/TaskDetailV2.vue';
import taskDetailStore from '@/store/task/detail';
import chatStore from '@/store/chat';
import taskDrawerStore, { MAX_TOTAL_ITEM_IN_DRAWER } from '@/store/task/drawer';
import TabContainer from '@/ui/common/molecules/SynTab/TabContainer.vue';
import { translate } from '@/ui/plugins/i18n/myi18n';
import SortAble from '@/ui/components/draggable/SortAble.vue';
import {
  isEdittingComment,
  isEdittingName,
} from '@/ui/modules/task/detail/task-detail-global-state';
import {
  settingGlobalModal,
  ask,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import taskMultiTabLogic from '@/ui/modules/task/detail/task-multi-tab-logic';
import { formatTaskCode } from '@/ui/hooks/taskHook';
import TaskCreateNew from '@/ui/modules/task/general/TaskCreateNew.vue';
import taskCreateFormStore from '@/store/task/create-form';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
// import dynamicPositionListTask from '@/ui/composables/app/dynamic-position-list-task';
import { ITaskTab } from '@/ui/composables/task/task-detail-drawer/task-detail-drawer.types';
import { removeAwsFiles } from '@/application/services/attachment/AttachmentClass';
import { removeAwsNotes } from '@/application/services/attachment/NoteClass';
import {
  StorageConstant,
  getLocalStorage,
  setLocalStorage,
} from '@/ui/hooks/storageHook';
import { closeSpeedChatDrawer } from '@/ui/modules/messaging/speed-chat/speed-chat.const';

const _taskDetailStore = taskDetailStore();
const _chatStore = chatStore();
const _taskDrawerStore = taskDrawerStore();
const _taskCreateFormStore = taskCreateFormStore();

const tasksDrawerRef = ref(null as any);
const appElm = document.getElementById('app') as HTMLElement;
const route = useRoute();

onMounted(() => {
  appElm.addEventListener('mousedown', onClickOutSideTaskDrawer);
});

onUnmounted(() => {
  appElm.removeEventListener('mousedown', onClickOutSideTaskDrawer);
});

const allTabIds = computed<string[]>(() => _taskDrawerStore.allTabIds);
const recentlyTabs = computed<ITaskTab[]>(() =>
  _taskDrawerStore.allTabIds
    ?.slice(0, MAX_TOTAL_ITEM_IN_DRAWER)
    ?.map((tabId) => allTabByIds.value[tabId])
);
const allTabByIds = computed(() => _taskDrawerStore.allTabByIds);
const hiddenTabs = computed<ITaskTab[]>(
  () =>
    arrayOrderBy(
      _taskDrawerStore.allTabIds
        ?.slice(MAX_TOTAL_ITEM_IN_DRAWER - 1, allTabIds.value?.length || 0)
        ?.map((tabId) => allTabByIds.value[tabId]),
      [(tab) => (tab?.tabType === 'NEW' ? 1 : 2), 'lastTimeAccess'],
      ['asc', 'desc']
    ) as ITaskTab[]
);

const tabActiveInHiddenTab = computed<ITaskTab | undefined>(() => {
  return hiddenTabs.value?.find((o) => o?.id == currentTabId.value);
});
const hiddenDraftTabs = computed<any>(() =>
  hiddenTabs.value?.filter((tab) => tab?.tabType === 'NEW')
);
const currentTabId = computed(() => _taskDrawerStore.currentTabId);

const currentTabInfo = computed(() => {
  if (!currentTabId.value) return {};
  return _taskDrawerStore.allTabByIds[currentTabId.value];
});

const errorCode = ref('');
const isDragingTab = ref(false);
const {
  isAddingTab,
  addTabName,
  addTabRef,
  onSearch,
  onCloseAddNew,
  onOpenAddNewTab,
  editingTabRef,
  editingTab,
  editingTabName,
  onOpenEditName,
  onSaveChange,
  onChangeDrag,
  onSwitchTab,
  onCloseEditName,
} = taskMultiTabLogic(
  errorCode,
  async (addTabName) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(addTabName);
    _taskDrawerStore.pushCurrentIds({ ...taskDetail, tabType: 'DETAIL' });
  },
  async (editingTabName) => {
    const taskDetail = await _taskDetailStore.getDetailByCode(editingTabName);
    // _taskDrawerStore.replaceCurrentIds(currentTabId.value, taskDetail);
    _taskDrawerStore.pushCurrentIds({ ...taskDetail, tabType: 'DETAIL' });
  },
  (oldIndex, newIndex) => {
    _taskDrawerStore.onSortTabs(oldIndex, newIndex);
  },
  (tabId) => {
    if (currentTabId.value == tabId) return;

    _taskDrawerStore.updateCurrentTabId(tabId);
  }
);

const onSwitchToHiddenTab = (tabId) => {
  onSwitchTab(tabId, currentTabId.value);

  // const oldIndex = allTabIds.value?.findIndex((id) => id == tabId);
  // onChangeDrag(oldIndex, 0);
  _taskDrawerStore.updateLastTimeAccessByTabId(tabId);
};

const onChangeTabName = () => {
  errorCode.value = '';
};

const confirmBeforeClose = async () => {
  settingGlobalModal({
    type: 'confirm',
    title: '',
    content:
      translate('TASK_CREATE_NEW_CONFIRM_BEFORE_ABORT') ||
      'Do you want to discard edits or continue editing?',
    cancelLabel: translate('COMMON_LABEL_NO') || 'No',
    confirmLabel: translate('COMMON_LABEL_YES_ABORT') || 'Yes abort',
    confirmable: true,
    closeable: true,
  });

  return ask();
};

const _isIgnoreClickOutsideComponent = (child) => {
  let node = child;
  while (node !== null) {
    if (
      node === parent ||
      node?.classList?.value
        ?.toString()
        ?.split(' ')
        .some((o) => {
          return o == 'do-not-close-task-detail-drawer';
        })
    ) {
      return true;
    }

    node = node.parentNode;
  }
  return false;
};

const onClickOutSideTaskDrawer = async (event) => {
  const taskDetailElm = document.getElementById('task-detail-page');
  const taskCreateNewElm = document.getElementById('task-create-new');
  const taskPinnedElm = document.getElementById('task-pinned-drawer');
  const dashboardLayoutTogglePinnedTasksButtonElm = document.getElementById(
    'dashboard-layout-toggle-pinned-tasks-button'
  );

  if (
    tasksDrawerRef.value?.contains(event.target) ||
    (!isOpenTaskDrawer.value && !isOpenTaskPinnedDrawer.value) ||
    taskDetailElm?.contains(event.target) ||
    taskCreateNewElm?.contains(event.target) ||
    taskPinnedElm?.contains(event.target) ||
    dashboardLayoutTogglePinnedTasksButtonElm?.contains(event.target) ||
    _isIgnoreClickOutsideComponent(event.target)
  )
    return;

  if (isEdittingComment.value || isEdittingName.value) {
    const confirmed = await confirmBeforeClose();
    if (!confirmed) return;
  }

  isOpenTaskDrawer.value = false;
  isOpenCreateTaskDrawer.value = false;
  isOpenTaskPinnedDrawer.value = false;

  onCloseAddNew();
};

const onOpenDrawer = () => {
  isOpenTaskDrawer.value = !isOpenTaskDrawer.value;
  if (isOpenTaskDrawer.value && route.name !== 'Messages') {
    _chatStore.closeBubbleChat();
  }
  checkPositionTabTask();

  if (!currentTabId.value) onClickNewTab(true);

  closeSpeedChatDrawer();
};
const onCloseAllTab = async () => {
  // 1. Confirm before
  let confirmMessage = '';
  if (allTabIds.value?.length > 1)
    confirmMessage =
      translate('TASK_CONFIRM_CLOSE_ALL_TAB') ||
      'Do you want to close all tabs?';

  const allCreateNewTabs = allTabIds.value?.filter(
    (tabId) => allTabByIds.value[tabId]?.tabType == 'NEW'
  );

  if (allTabIds.value?.length > 0 && allCreateNewTabs?.length > 0)
    confirmMessage =
      translate('TASK_CONFIRM_CLOSE_ALL_TAB_NEW') ||
      'You still have creation task in process. Do you want to close all tabs?';

  if (confirmMessage) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: confirmMessage,
      cancelLabel: translate('COMMON_LABEL_NO') || 'No',
      confirmLabel: translate('COMMON_LABEL_YES_ABORT') || 'Yes abort',
      confirmable: true,
      closeable: true,
    });
    const confirmed = await ask();

    if (!confirmed) return;

    for (const tabId of allCreateNewTabs) {
      const formData = await _taskCreateFormStore.getCachedByOwnerId(tabId);

      if (formData?.files?.length > 0) removeAwsFiles(formData?.files);
      if (formData?.notes?.length > 0) removeAwsNotes(formData?.notes);
    }
  }

  _taskDrawerStore.closeAllTab();

  isOpenTaskDrawer.value = false;
  isOpenCreateTaskDrawer.value = false;
};

const onCloseTab = async (tabId) => {
  if (allTabByIds.value[tabId]?.tabType === 'NEW') {
    let formData: any = {};
    if (currentTabId.value == tabId) {
      formData = taskCreateNewRef.value?.getFormData
        ? taskCreateNewRef.value?.getFormData()
        : {};
    } else {
      formData = await _taskCreateFormStore.getCachedByOwnerId(tabId);
    }

    if (
      formData?.files?.length > 0 ||
      formData?.notes?.length > 0 ||
      formData?.name?.length >= 3 ||
      formData?.taskTodos?.length > 0 ||
      formData?.description ||
      formData?.domainId
    ) {
      const confirmed = await confirmBeforeClose();
      if (!confirmed) return;

      if (formData?.files?.length > 0) removeAwsFiles(formData?.files);
      if (formData?.notes?.length > 0) removeAwsNotes(formData?.notes);
    }
  }
  _taskDrawerStore.onCloseTab(tabId);
  _taskCreateFormStore.removeCacheByLocalId(tabId);
};

const onOpenCreateNewTask = () => {
  onCloseAddNew();
};

watch(
  () => isOpenCreateTaskDrawer.value,
  () => {
    if (isOpenCreateTaskDrawer.value) onClickNewTab(true, false);
  }
);

const hiddenTabDropdownRef = ref<any>(null);

const onClickNewTab = (isCreateNew = false, isClearDataDraft = true) => {
  onOpenAddNewTab(isCreateNew);

  hiddenTabDropdownRef.value?.onForceClose();

  _taskDrawerStore.currentTabId = `${Date.now()}`;

  if (isClearDataDraft) {
    newTaskDefaultPayload.value = undefined;
  }
  if (isCreateNew) {
    isOpenTaskDrawer.value = true;
    setTimeout(() => {
      taskCreateNewRef.value?.onFocusInputName();
    }, 300);
  }

  closeSpeedChatDrawer();
};

window.addEventListener('keydown', (e) => {
  if (e.ctrlKey && e.keyCode === 74) {
    // console.log('CTRL + J');
    e.preventDefault();
    onClickNewTab(true);
  }
});

// const onCreateSuccess = (localId, newTask) => {
//   _taskDrawerStore.replaceCurrentIds(localId, newTask);
// };

const onCheckEditTab = async (tabInfo) => {
  if (currentTabId.value?.toString() == tabInfo?.id.toString())
    onOpenEditName(tabInfo);
};

const onSaveEditTabName = async () => {
  // console.log(
  //   '🚀 Hyrin ~ onSaveEditTabName ~ editingTabName.value',
  //   editingTabName.value
  // );
  if (!editingTabName.value) return;
  const newTaskCode = editingTabName.value;
  if (currentTabInfo.value?.tabType === 'NEW') {
    const taskCode = newTaskCode?.split('-').pop() || '';

    settingGlobalModal({
      type: 'confirm',
      title: '',
      content:
        translate('TASK_CREATE_NEW_CONFIRM_BEFORE_ABORT_EDIT', {
          taskCode,
        }) || 'Do you want to discard edits or continue editing?',
      cancelLabel: translate('COMMON_LABEL_CANCEL') || 'Cancel',

      confirmLabel:
        translate('TASK_CREATE_NEW_GO_TO_TASK', { taskCode }) || 'Go to task',
      confirmable: true,
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return;
  }

  onSaveChange(newTaskCode);
};

// const listTask = document.getElementById('listTask');

const checkPositionTabTask = async () => {
  await nextTick();
  // console.log(allTabIds.value, '111111111111111');

  // console.log(document.getElementById('0'));
};

// dynamicPositionListTask(listTask.value);

const isCreatingTask = ref(false);
const isShowHiddenTab = ref<boolean>(false);
const elementId = 'TASK_DETAIL_DRAWER';

const taskDetailDrawerDrag = ref<HTMLElement | null>(null);

// `style` will be a helper computed for `left: ?px; top: ?px;`
const drawerTop = ref<number>(
  getLocalStorage(StorageConstant.CURRENT_TASK_DETAIL_DRAWER_TOP)
    ? parseInt(getLocalStorage(StorageConstant.CURRENT_TASK_DETAIL_DRAWER_TOP))
    : 200
);
const { y, isDragging } = useDraggable(taskDetailDrawerDrag, {
  initialValue: {
    y: drawerTop.value,
  },
});
const draggingIndex = ref<number>(0);

const body = document.body,
  html = document.documentElement;

const maxHeight = Math.max(
  body.scrollHeight,
  body.offsetHeight,
  html.clientHeight,
  html.scrollHeight,
  html.offsetHeight
);
watch(
  () => y.value,
  (newY, oldY) => {
    if (draggingIndex.value == 0) return;
    if (draggingIndex.value == 1) {
      draggingIndex.value += 1;
      return;
    }

    const translateY = newY - oldY;

    if (
      !oldY ||
      (drawerTop.value <= 1 && translateY < 0) ||
      (drawerTop.value + 168 > maxHeight && translateY > 0)
    )
      return;

    drawerTop.value += translateY;

    setLocalStorage(
      StorageConstant.CURRENT_TASK_DETAIL_DRAWER_TOP,
      drawerTop.value
    );
  }
);
watch(
  () => isDragging.value,
  (isDragging) => {
    draggingIndex.value = isDragging ? 1 : 0;
  }
);
</script>

<template>
  <div
    :id="elementId"
    ref="tasksDrawerRef"
    v-element-active="{
      id: elementId,
      isForceOpen: isOpenTaskDrawer && !isShowTaskDetailGlobal,
    }"
    class="
      fixed
      right-0
      bottom-0
      flex
      z-30
      transition
      duration-300
      transform
      bg-gray-200
      shadow
    "
    :class="[
      isOpenTaskDrawer ? 'translate-x-0 ease-out' : 'translate-x-full ease-in',
      isCreatingTask ? 'cursor-wait' : '',
    ]"
    style="width: 650px; max-width: 100%; height: calc(100% - 50px)"
  >
    <TabContainer>
      <template #header>
        <ul class="text-sm" style="max-height: 4rem">
          <SortAble
            v-model:isDraging="isDragingTab"
            :disabled="isAddingTab || !!editingTab || isCreatingTask"
            class="flex-1 flex flex-wrap list-tab-container"
            :options="{
              preventOnFilter: false,
              onMove: 1,
            }"
            @on-change-drag="onChangeDrag"
          >
            <template #prefix>
              <div
                v-if="isAddingTab"
                class="relative flex-center hover-to-show__parent float-left"
              >
                <input
                  ref="addTabRef"
                  v-model="addTabName"
                  placeholder="#code"
                  class="
                    w-20
                    outline-none
                    px-2
                    py-1
                    border-x-0 border-t-0 border-b-2
                    focus:border-current focus:ring-0
                    rounded-t
                  "
                  @keyup.enter="onSearch"
                  @input="onChangeTabName"
                />
                <div
                  v-if="addTabName"
                  class="
                    hover-to-show__children
                    absolute
                    right-1
                    w-4
                    h-4
                    rounded-full
                    bg-white
                    flex-center
                    hover:text-black hover:bg-gray-300
                  "
                  @click.stop="
                    addTabName = '';
                    addTabRef?.focus();
                  "
                >
                  <SynIcon name="close" custom-class="w-3 h-3" />
                </div>
              </div>
              <div
                v-else
                class="h-8 w-8 flex-center hover:bg-current-50"
                :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
                @click.stop="!isDragingTab && onClickNewTab(false)"
              >
                <SynIcon
                  class="fill-current"
                  custom-class="h-3 w-3"
                  name="plus"
                />
              </div>
            </template>
            <template #list-item>
              <li
                v-for="tab in recentlyTabs"
                :key="tab?.id"
                :title="tab?.name || tab?.taskName"
                :class="[
                  tab?.id == currentTabId && !isAddingTab
                    ? 'bg-white font-semibold text-current-500'
                    : 'text-current-900 bg-gray-200 hover:bg-gray-100',
                  isAddingTab ? 'li-created' : 'li-detail',
                ]"
                class="hover-to-show__parent flex-center shadow-sm"
                style="width: 5rem"
                @click="onSwitchTab(tab?.id, currentTabId)"
                @auxclick="onCloseTab(tab?.id)"
              >
                <span
                  v-if="currentTabId !== tab?.id"
                  class="h-2/3 border-l border-gray-300"
                ></span>
                <div
                  v-if="editingTab == tab?.id"
                  class="
                    flex-1
                    h-full
                    relative
                    flex-center
                    hover-to-show__parent
                  "
                >
                  <input
                    ref="editingTabRef"
                    v-model="editingTabName"
                    v-cusFocus="true"
                    placeholder="#code"
                    class="
                      w-20
                      outline-none
                      p-1
                      ring-1
                      focus:ring-current
                      rounded
                    "
                    @keyup.enter="onSaveEditTabName"
                    @blur="onCloseEditName"
                    @input="onChangeTabName"
                  />
                  <div
                    v-if="editingTabName"
                    class="
                      hover-to-show__children
                      absolute
                      right-1
                      w-4
                      h-4
                      rounded-full
                      bg-white
                      flex-center
                      hover:text-black hover:bg-gray-300
                    "
                    @click.stop="
                      editingTabName = '';
                      editingTabRef?.focus();
                    "
                  >
                    <SynIcon name="close" custom-class="w-3 h-3" />
                  </div>
                </div>
                <div
                  v-else
                  class="
                    flex-1 flex
                    items-center
                    justify-between
                    relative
                    py-1.5
                  "
                  style="min-width: 4rem"
                >
                  <span
                    class="px-2 text-overflow-hidden-line"
                    :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
                    @dblclick="onOpenEditName(tab)"
                    @click="onCheckEditTab(tab)"
                  >
                    {{ tab?.tabCode?.split('-')?.pop() || tab?.tabCode }}
                  </span>
                  <span
                    class="
                      w-4
                      h-4
                      rounded-full
                      bg-white
                      flex-center
                      hover:text-black hover:bg-gray-300
                    "
                    :class="[
                      tab?.id == currentTabId && !isAddingTab
                        ? 'mr-1'
                        : 'hover-to-show__children absolute right-1',
                      isCreatingTask ? 'cursor-wait' : 'cursor-pointer',
                    ]"
                    @click.stop="onCloseTab(tab?.id)"
                  >
                    <SynIcon name="close" custom-class="w-3 h-3" />
                  </span>

                  <span
                    v-if="tab?.tabType === 'NEW' && tab?.id !== currentTabId"
                    class="
                      h-2
                      w-2
                      absolute
                      top-1
                      left-1
                      rounded-full
                      bg-orange-500
                    "
                  >
                  </span>
                </div>
              </li>
            </template>
          </SortAble>
          <div class="absolute right-0 bottom-0" style="width: 5.5rem">
            <VigDropdown
              v-if="hiddenTabs?.length > 0"
              ref="hiddenTabDropdownRef"
              :arrow="false"
              :z-index="60"
              @on-dropdown-open="isShowHiddenTab = true"
              @on-dropdown-close="isShowHiddenTab = false"
            >
              <template #dropdown-toggle>
                <div
                  :title="`${$t('TASK_LABEL_SEEN_TASKS_OTHER')} (${
                    hiddenTabs?.length
                  })`"
                  class="
                    w-full
                    cursor-pointer
                    flex
                    items-center
                    justify-between
                    shadow-sm
                    hover:bg-gray-100
                    relative
                    px-1
                  "
                  :class="[
                    tabActiveInHiddenTab?.tabCode
                      ? 'bg-white text-current-500 font-semibold'
                      : 'text-gray-700 bg-gray-200',
                  ]"
                  style="min-width: 5.5rem; height: 2rem"
                >
                  <span
                    v-if="hiddenDraftTabs?.length > 0"
                    class="
                      absolute
                      top-1
                      left-1
                      w-1.5
                      h-1.5
                      bg-orange-500
                      ring-2 ring-orange-400 ring-opacity-30
                      rounded-full
                    "
                  ></span>
                  <template v-if="tabActiveInHiddenTab?.tabCode">
                    <span>
                      {{
                        tabActiveInHiddenTab?.tabCode?.split('-')?.pop() ||
                        tabActiveInHiddenTab?.tabCode
                      }}
                    </span>
                  </template>
                  <span v-else class="inline-flex items-center">
                    {{ $t('NOTIFICATION_LABEL_TYPE_OTHER') }}
                    <span class="text-xs">
                      ({{
                        hiddenTabs?.length > 999 ? `999+` : hiddenTabs?.length
                      }})
                    </span>
                  </span>
                  <SynIcon
                    :name="'sort-down'"
                    custom-class="h-2.5 w-2.5 fill-gray-500"
                    :class="!isShowHiddenTab ? '-rotate-90' : ''"
                  />
                </div>
              </template>
              <template #dropdown-menu>
                <div
                  class="
                    flex flex-col
                    rounded-md
                    space-y-1
                    overflow-auto
                    small-scrollbar
                  "
                  style="max-height: 620px; width: 20rem"
                >
                  <div
                    v-for="tab in hiddenTabs"
                    :key="tab?.id"
                    :title="tab?.name || tab?.taskName"
                    class="
                      flex flex-col
                      pl-4
                      py-1
                      hover:bg-gray-100
                      rounded
                      relative
                      cursor-pointer
                      hover-to-show__parent
                      dropdown-item
                    "
                    :class="[
                      tab?.id == currentTabId
                        ? 'bg-gray-100 text-current-500'
                        : 'text-gray-700',
                    ]"
                    @click="onSwitchToHiddenTab(tab?.id)"
                  >
                    <span
                      class="
                        hover-to-show__children
                        absolute
                        right-1
                        top-1
                        flex-center
                        h-5
                        w-5
                        hover:bg-gray-200
                        rounded-full
                        cursor-pointer
                      "
                      @click.stop="onCloseTab(tab?.id)"
                    >
                      <SynIcon name="close" custom-class="w-3 h-3" />
                    </span>
                    <div class="w-full flex items-center justify-between">
                      <span class="font-semibold">
                        {{ tab?.tabCode?.split('-')?.pop() || tab?.tabCode }}
                      </span>
                    </div>
                    <div
                      class="w-full flex items-center justify-between space-x-2"
                    >
                      <div class="flex-1">
                        <span
                          class="
                            text-sm text-gray-400 text-overflow-hidden-line
                          "
                        >
                          {{ tab?.name || tab?.taskName }}
                        </span>
                      </div>
                      <span class="text-xs text-gray-400 w-max">
                        {{ dayjs(tab?.lastTimeAccess).fromNow() }}
                      </span>
                    </div>
                    <span
                      v-if="tab?.tabType === 'NEW' && tab?.id !== currentTabId"
                      class="
                        h-2
                        w-2
                        absolute
                        top-1
                        left-1
                        rounded-full
                        bg-orange-500
                      "
                    >
                    </span>
                  </div>
                </div>
              </template>
            </VigDropdown>
          </div>
        </ul>
      </template>
      <template #actions>
        <div class="flex-center gap-1">
          <div
            :title="$t('COMMON_LABEL_CLOSE') || 'Close'"
            class="
              w-6
              h-6
              rounded
              flex-center
              fill-gray-800
              hover:fill-gray-500 hover:bg-gray-300 hover:bg-opacity-50
            "
            :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
            @click.stop="onOpenDrawer"
          >
            <SynIcon name="minus-line" custom-class="w-2.5 h-2.5" />
          </div>
          <div
            :title="$t('COMMON_LABEL_CLOSE') || 'Close'"
            class="
              w-6
              h-6
              rounded
              flex-center
              fill-gray-600
              hover:fill-red-500 hover:bg-red-200 hover:bg-opacity-50
            "
            :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
            @click.stop="onCloseAllTab"
          >
            <SynIcon name="close" custom-class="w-4 h-4" />
          </div>
        </div>
      </template>
      <template #body>
        <div
          v-if="errorCode"
          class="h-full flex-center py-6 px-4 bg-opacity-40 rounded-xl flex-col"
        >
          <syn-animation
            name="searchNotFound"
            stype="width: 150px; height:150px"
          />
          <div class="flex flex-col space-y-4 pt-8 text-center">
            <span
              class="font-semibold text-2xl text-current-500"
              v-html="
                $t(errorCode, {
                  code: formatTaskCode(addTabName || editingTabName) || '',
                }) ||
                $t('TASK_LABEL_CAN_NOT_FIND_OUT_TASK') ||
                'Something wrong'
              "
            >
            </span>
            <span
              class="text-gray-500"
              v-html="
                $t('TASK_LABEL_NOT_FOUND', {
                  taskName: formatTaskCode(addTabName || editingTabName),
                }) || 'We are sorry.'
              "
            >
            </span>
            <div class="w-full flex-center">
              <SynButton type-text :label="$t('COMMON_LABEL_REFRESH')" />
            </div>
          </div>
        </div>
        <template v-else-if="isOpenTaskDrawer">
          <template v-if="!isAddingTab && currentTabInfo?.tabType === 'DETAIL'">
            <TaskDetailV2
              :key="currentTabInfo?.id || currentTabInfo?.code"
              :task-id="currentTabInfo?.id"
              :task-code="currentTabInfo?.code"
              @on-open-create-new="onOpenAddNewTab(true)"
              @on-close="isOpenTaskDrawer = false"
            />
          </template>

          <div v-else-if="currentTabId" class="h-full flex-center">
            <TaskCreateNew
              ref="taskCreateNewRef"
              :key="currentTabId"
              :local-id="currentTabId"
              @on-open-interface="onOpenCreateNewTask"
              @on-cancel="onCloseTab(currentTabId)"
            />
          </div>
          <SynAnimation v-else name="my-tasks" />
        </template>
      </template>
    </TabContainer>
    <div
      v-vig-tooltip="$t('TASK_LABEL_VIEW_DETAIL_TASKS')"
      class="
        absolute
        -left-8
        w-8
        bg-secondary
        text-current-800
        shadow-xl
        flex-center
        rounded-l-lg
      "
      :class="[isCreatingTask ? 'cursor-wait' : 'cursor-pointer']"
      :style="{
        height: '7.5rem',
        top: `${drawerTop}px`,
      }"
      @click.stop.prevent="onOpenDrawer"
      @mousedown.stop.prevent="() => {}"
      @mouseup.stop.prevent="() => {}"
      @mousemove.stop.prevent="() => {}"
    >
      <div
        class="
          w-full
          h-full
          flex-center
          hover-to-show__parent
          hover-to-hidden__parent
        "
      >
        <div
          class="absolute transform -rotate-90 font-semibold flex items-center"
        >
          <span> Tasks </span>
        </div>

        <SynIcon
          custom-class="absolute top-4 w-2 h-2 fill-current transform"
          :class="isOpenTaskDrawer ? '' : 'rotate-180'"
          name="play"
        />
        <!-- ADD NEW BUTTON -->
        <div
          v-if="Object.keys(allAutoCreateProcessIntervalByIds)?.length > 0"
          class="
            absolute
            bottom-1
            shadow
            flex-center
            w-6
            h-6
            rounded
            bg-orange-500
            hover:bg-orange-600
          "
        >
          <SynIcon
            name="spinner"
            custom-class="w-3 h-3 fill-white border-none animate-spin"
          />
        </div>
        <div
          v-else
          v-vig-tooltip="{
            content: `
            <div class='px-2 py-2 space-y-0.5'>
                <div class='min-w-max space-x-2 text-center bg-white text-current-500 rounded flex items-center justify-between'>
                <span class='text-md'>${$t('TASK_CREATE_NEW_TITLE')}</span>
                <span class='bounceX h-6 w-6 flex-center rounded-full bg-current-50'>→</span>
              </div>
              <div class='flex items-center space-x-1 text-2xs text-gray-700'>
                <div class='p-0.5 border bg-gray-100 border-gray-300 rounded'>
                   <span><kbd>Ctrl</kbd></span>
                </div>
                <div class='py-0.5 px-1 border bg-gray-100 border-gray-300 rounded'>
                   <span><kbd>J</kbd></span>
                </div>
              </div>
            </div>
`,
            arrow: true,
            placement: 'left',
            delay: 0,
            theme: 'light',
            animation: 'scale',
            interactive: true,
            zIndex: 40,
            hideAfter: true,
            showOnCreate: true,
          }"
          class="
            absolute
            bottom-1
            shadow
            flex-center
            w-6
            h-6
            rounded
            bg-orange-500
            hover:bg-orange-600
          "
          @click.stop="onClickNewTab(true)"
        >
          <SynIcon custom-class="w-2 h-2 fill-white text-white" name="plus" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bounceX {
  animation: bounce_run 1s infinite;
}

@keyframes bounce_run {
  0%,
  100% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.list-tab-container .li-detail:nth-child(8) {
  margin-right: 2.9rem;
}

.list-tab-container .li-created:nth-child(7) {
  margin-right: 2.9rem;
}
</style>
