<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import getUserInfo from '@/ui/helpers/user-helper';
import UserById from '@/ui/components/user/UserById.vue';
import CommentReactionList from '@/ui/modules/task/components/activity-comment/CommentReactionList.vue';
import CommentReactionButton from '@/ui/modules/task/components/activity-comment/CommentReactionButton.vue';
import ListReactionsInCommentModal from '@/ui/modules/task/components/activity-comment/ListReactionsInCommentModal.vue';
import CommentListFiles from '@/ui/modules/task/components/activity-comment/CommentListFiles.vue';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';
import ActionInComment from '@/ui/modules/task/components/activity-comment/ActionInComment.vue';
import taskDetailStore from '@/store/task/detail';
import InformationPopupFromLink from '@/ui/components/popup/InformationPopupFromLink.vue';
import { ALL_FUNCTIONS } from '@/ui/hooks/permission/permission-by-function';
import {
  ETaskCommentReactionType,
  ETaskFileStatus,
} from '@/application/types/task/task.types';
import SetMarkComment from '@/ui/modules/task/components/comment/SetMarkComment.vue';

const props = withDefaults(
  defineProps<{
    message: any;
    isEdit?: boolean;
    inListPinned?: boolean;
    isLoading?: any;
    bgMsg?: string;
    isShowLineDate?: boolean;
    users?: any[];
    readonly?: boolean;
    canReply?: boolean;
    isHighlight?: boolean;
  }>(),
  {
    bgMsg: 'bg-gray-50',
    isShowLineDate: true,
    isLoading: false,
    canReply: false,
    users: undefined,
  }
);
const emits = defineEmits([
  'onReactionAdd',
  'onReactionsClick',
  'onEditComment',
  'onRemoveComment',
  'onPinComment',
  'onReactionRemove',
  'onFileClick',
  'onMessageDownload',
  'onClickTextComment',
  'onReplyClick',
  'onAddMark',
]);

const _taskDetailStore = taskDetailStore();
const files = computed(() => props.message.attachments?.files || []);
const notes = computed(() => props.message.attachments?.notes || []);

const messageElmId = computed<string>(() => {
  return props.message?.pinned
    ? `detail-task-comment-pinned-${props.message?.id}`
    : `detail-task-comment-${props.message?.id}`;
});

const navigatePoint = computed<{
  type: string;
  id: string;
  isScroll: boolean;
  isScrollBottom: boolean;
}>(() => _taskDetailStore.navigatePoint);
const userInfo = computed(
  () =>
    props.users?.find((user) => user?.id === props.message?.createdBy) ||
    getUserInfo(props.message?.createdBy)
);
const currentId = getCurrentUserId();
const allAttachments = computed(() => {
  return arrayOrderBy(
    [
      ...files.value?.map((file) => {
        return {
          ...file,
          url_full: file.fileUrl,
          contentType: file?.type,
          name: file?.fileName,
        };
      }),
      ...notes?.value?.map((note) => {
        return {
          ...note,
          url_full: note.imagePath,
          contentType: 'image/jpeg',
          name: 'tictop-note.jpeg',
        };
      }),
    ],
    ['creationTime'],
    ['asc']
  );
});

onMounted(() => {
  setTimeout(() => {
    if (
      navigatePoint.value?.type == 'comment' &&
      navigatePoint.value?.id == props.message?.id
    ) {
      const el = document.getElementById(messageElmId.value) as any;
      if (el)
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
    }
  }, 500);
});
const reactionListByCmtId = ref({} as any);
const isHighLightCmt = computed(() => {
  if (
    _taskDetailStore?.navigatePoint?.id &&
    _taskDetailStore?.navigatePoint?.isScroll
  ) {
    return _taskDetailStore?.navigatePoint?.id;
  }
  return null;
});

const onOpenViewReactionsModal = (reactions, id) => {
  if (props.readonly) return;

  reactionListByCmtId.value.reactions = reactions;
  reactionListByCmtId.value.commentId = id;
};

const onRemoveReaction = (value) => {
  emits('onReactionRemove', {
    reaction: value,
    commentId: reactionListByCmtId.value.commentId,
  });
  reactionListByCmtId.value = {};
};

const informationPopupRef = ref<any>(null);
const onHoverTextMessage = async (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onHoverTextMessage == 'function'
  ) {
    informationPopupRef.value?.onHoverTextMessage(event);
  }
};

const onMouseLeaveTextMessage = (event) => {
  if (
    informationPopupRef.value &&
    typeof informationPopupRef.value?.onMouseLeaveTextMessage == 'function'
  ) {
    informationPopupRef.value?.onMouseLeaveTextMessage(event);
  }
};

const reactionTypeEmotionList = computed<any[]>(() => {
  return props.message?.reactions?.filter(
    (o) => !o?.type || o?.type == ETaskCommentReactionType.EMOTION
  );
});
const reactionTypeMark = computed<any>(() => {
  const results = props.message?.reactions?.filter(
    (o) => o?.type == ETaskCommentReactionType.MARK
  );

  return results?.length > 0 ? results[0] : null;
});
</script>

<template>
  <!--DATE-->
  <div
    v-if="isShowLineDate && message?.isFirstCommentDay"
    class="flex-center relative w-full pb-1 pt-3 message-item"
    :class="[bgMsg]"
  >
    <div class="border-t w-3/4 border-gray-200" :class="`${bgMsg}`">
      <span
        class="
          absolute
          inset-0
          text-center
          flex-center
          text-xs
          font-medium
          text-gray-500
        "
      >
        <SynLabelDateTime
          class="px-3"
          :class="`${bgMsg}`"
          format="date"
          :datetime="message?.createdDate"
        />
      </span>
    </div>
  </div>

  <!--MESSAGE-->
  <div
    :id="messageElmId"
    :class="[
      'pb-1',
      { 'border-b border-gray-100': !message?.isLastCommentDay },
    ]"
  >
    <div
      class="flex items-start w-full space-x-2 px-2 task-message-item"
      :class="[
        message?.pinned ? 'border-l-2 border-l-orange-500' : '',
        bgMsg,
        !message?.pinned &&
        isHighLightCmt === `detail-task-comment-${message?.id}`
          ? 'colorScrollComment cmt-focus'
          : '',
        isHighlight ? 'bg-orange-50 bg-opacity-70' : '',
      ]"
    >
      <div class="w-6 h-6 pt-4 pr-1 pl-2">
        <UserById
          :user-id="message?.createdBy"
          :user="userInfo"
          is-hidden-name
          avatar-class="w-6 h-6"
        />
      </div>

      <!-- MSG   -->
      <div class="flex-1 hover-to-show__parent relative pl-3">
        <div class="rounded-r-xl rounded-bl-xl rounded-tl-md py-2 px-1">
          <!--  CREATOR    -->
          <div class="flex items-center justify-between space-x-2 text-sm">
            <div class="flex items-center space-x-2 py-0.5">
              <span
                v-if="!userInfo?.name"
                class="font-medium italic text-gray-500"
              >
                Tictop User
              </span>

              <span v-else class="font-medium">
                {{
                  userInfo?.name ||
                  `${userInfo?.lastName} ${userInfo?.firstName}`
                }}
              </span>

              <span v-if="message?.createdBy === currentId" class="text-xs"
                >{{ `(${$t('COMMON_LABEL_ME')})` || '(Me)' }}
              </span>
              <span
                v-vig-tooltip="
                  dayjs(message?.createdDate)?.format('MMM DD, YYYY, hh:mm A')
                "
                class="text-xs text-gray-500"
              >
                {{ dayjs(message?.createdDate).fromNow() }}
              </span>
              <span
                v-if="message?.isEdited"
                class="text-gray-500 italic text-xs"
                >{{ `(${$t('COMMOM_LABEL_EDITED')})` }}
              </span>
            </div>

            <div class="flex-center pr-2">
              <VigButton
                v-if="canReply && !readonly"
                v-vig-tooltip="$t('TASK_COMMENT_REPLY')"
                v-permission-function="{
                  functionCode: ALL_FUNCTIONS.TASK.COMMENT_REPLY,
                  onContinue: () => $emit('onReplyClick'),
                }"
                ghost
                color="gray"
                padding="p-1"
                class="hover-to-show__children"
                @click="$emit('onReplyClick')"
              >
                <SynIcon name="Reply" custom-class="w-3 h-3"></SynIcon>
              </VigButton>
              <div class="flex items-center gap-2 h-4">
                <CommentReactionList
                  :reactions-list="reactionTypeEmotionList || []"
                  :readonly="readonly"
                  @click="
                    onOpenViewReactionsModal(
                      reactionTypeEmotionList,
                      message?.id
                    )
                  "
                />
                <CommentReactionButton
                  v-if="!readonly"
                  :reactions-list="reactionTypeEmotionList || []"
                  @on-emoji-click="
                    (data) =>
                      $emit('onReactionAdd', { content: data, id: message?.id })
                  "
                />
                <div
                  :class="
                    reactionTypeMark?.content ? '' : 'hover-to-show__children'
                  "
                >
                  <SetMarkComment
                    :current-status="reactionTypeMark?.content"
                    @toggle-change-status="
                      $emit('onAddMark', {
                        content:
                          reactionTypeMark?.content ||
                          ETaskFileStatus.MARK_GREEN,
                        uncheck: !!reactionTypeMark?.content,
                        id: message?.id,
                      })
                    "
                    @on-change-status="
                      (status) =>
                        $emit('onAddMark', { content: status, id: message?.id })
                    "
                  />
                </div>
              </div>
              <ActionInComment
                v-if="!readonly"
                :is-my-comment="message?.createdBy === currentId"
                :message="message"
                :is-loading="isLoading"
                @on-edit-comment="$emit('onEditComment', message?.id)"
                @on-pin-comment="$emit('onPinComment')"
                @on-remove-comment="$emit('onRemoveComment')"
              />
            </div>
          </div>

          <!--  CONTENT    -->
          <div
            class="
              leading-5
              whitespace-pre-line
              relative
              pt-1
              text-gray-700
              pr-4
            "
          >
            <div
              v-if="!isEdit"
              class="msg-text pr-4"
              style="word-break: break-word"
              @click="$emit('onClickTextComment', $event)"
              @mouseover="onHoverTextMessage"
              @mouseleave="onMouseLeaveTextMessage"
              v-html="message.content"
            ></div>
            <slot name="input-edit"></slot>
          </div>

          <template v-if="message?.removedAllAttachments && !isEdit">
            <span
              class="
                py-3
                px-3
                flex-center
                bg-gray-100
                rounded
                text-xs text-gray-500
                mr-4
              "
              >{{ $t('COMMENT_LABEL_REMOVED_ALL_ATTACHMENTS') }}</span
            >
          </template>

          <!--  FILES - NOTE    -->
          <div
            v-if="(files?.length > 0 || notes?.length > 0) && !isEdit"
            class="mt-2"
          >
            <CommentListFiles
              :files="allAttachments"
              size="sm"
              quality-image="original"
              @on-file-click="(file) => $emit('onFileClick', file)"
              @on-message-download="
                (files) => $emit('onMessageDownload', files)
              "
            />
          </div>
        </div>
      </div>
    </div>

    <!--REPLIES-->
    <div class="pl-14">
      <slot name="replies"></slot>
    </div>
  </div>

  <InformationPopupFromLink ref="informationPopupRef" />

  <ListReactionsInCommentModal
    v-if="
      reactionListByCmtId?.reactions?.length > 0 &&
      reactionListByCmtId.commentId
    "
    :reactions="
      reactionListByCmtId?.reactions?.filter(
        (o) => !o?.type || o?.type == ETaskCommentReactionType.EMOTION
      )
    "
    @on-cancel="
      (reactionListByCmtId.reactions = []),
        (reactionListByCmtId.commentId = null)
    "
    @on-reaction-remove="onRemoveReaction"
  />
</template>

<style scoped>
@keyframes change {
  0% {
    background-color: white;
  }
  35% {
    background-color: #e0f1f2;
  }
  70% {
    background-color: #f8ffff;
  }
  100% {
    background-color: white;
  }
}

.cmt-focus {
  @apply ring ring-current ring-opacity-30 z-40;
}

.colorScrollComment {
  animation-name: change;
  animation-duration: 1s;
}
</style>
