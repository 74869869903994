<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TaskList from '@/ui/modules/task/table/TaskList.vue';
import BarNotificationGroup from '@/ui/components/notification/BarNotificationGroup.vue';
import taskListStore from '@/store/task/task-list-store';
import userStore from '@/store/user';
import groupStore from '@/store/group';
import { isEqual } from '@/ui/hooks/commonFunction';
import { decryptGroupId } from '@/ui/plugins/crypto/crypto';
import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import GroupDetailModal from '@/ui/modules/group/detail/GroupDetailModal.vue';
import TaskTabs from '@/ui/modules/task/components/TaskTabs.vue';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';
import { TaskListTab } from '@/domain/enums/taskEnum';
import customDocumentTitle from '@/ui/composables/app/document-title';
import CustomExportModal from '@/ui/modules/task/export/CustomExportModal.vue';
import AvatarByTaskList from '@/ui/modules/task/components/task-list/AvatarByTaskList.vue';
import FilterTabs from '@/ui/modules/task/components/task-list/FilterTabs.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TaskBoard from '@/ui/modules/task/board/TaskBoard.vue';
import GroupTaskStatisticPage from '@/ui/modules/task/statistic/GroupTaskStatisticPage.vue';
import tableListTaskComposable from '@/ui/composables/task/table-list-task';
import ActionsListTask from '@/ui/modules/task/filter/ActionsListTask.vue';
import UpdateMultipleActions from '@/ui/modules/task/table/UpdateMultipleActions.vue';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import {
  EFilterTaskDefault,
  ETaskViewType,
} from '@/application/constants/task.const';
import { ETaskListModule } from '@/application/types/task/task.types';
import TaskCalendarView from '@/ui/pages/tasks/calendar/TaskCalendarView.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import remoteConfigStore from '@/store/remoteConfig';

const _taskListStore = taskListStore();
const _userStore = userStore();
const _groupStore = groupStore();
const _taskTabViewStore = taskTabViewStore();
const _remoteConfigStore = remoteConfigStore();

const groupId = computed(() => parseInt(decryptGroupId(route.params?.id)));
const viewTask = computed(() => _taskTabViewStore.customizeView);
const isGroupPrivate = computed(() => currentGroup.value?.isPrivate);
const isGroupDelete = computed(
  () => currentGroup.value?.code == 'DELETED_GROUP'
);
const selectedTab = computed<TaskListTab>(() => _taskListStore.activeTab);
const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const isGroupMember = computed(() => currentGroup.value?.isMember);
const isLoading = computed(() => _taskListStore.isLoading);
const totalTasks = computed(() => _taskListStore.taskAllList?.length || 0);
const isVisitor = computed(() => _userStore.isVisitor);
const isAdmin = computed(() => _userStore.isAdmin);
const isTasksTab = computed(
  () =>
    selectedTab.value == TaskListTab.MyList ||
    selectedTab.value == TaskListTab.MyAssign
);
const actionHidden = computed(() => {
  let res = [] as any;
  if (!isTasksTab.value) res?.push('export_task');
  if (isVisitor.value) res = [...res, 'profile', 'information'];
  else res = [...res, 'profile'];
  return res;
});

const webTaskHiddenCreateFormBottom = computed<boolean>(() => {
  return _remoteConfigStore.webTaskHiddenCreateFormBottom;
});
const isCanCreateTask = computed(() => {
  return webTaskHiddenCreateFormBottom.value;
});

const route = useRoute();
const { changeByRouter } = customDocumentTitle();
const currentGroup = ref({} as any);
const isShowInfoGroup = ref(false);
const currentTabInfoGroup = ref('INFO_GROUP');
const taskListRef = ref(null as any);
const isShowCustomExportModal = ref(false);

const { initListData, onChangeParentTab, onChangeChildTab } =
  tableListTaskComposable();

watch(
  () => route.query?.tab,
  (tab) => {
    if (!tab) return;

    _processTabByRouteParams(tab);
  }
);

const isInitialized = ref<boolean>(false);
const initData = async (isReload?) => {
  _taskListStore.$reset();
  _taskTabViewStore.getUserCustomTaskFilters(
    ETaskListModule.GROUP,
    groupId.value
  );

  if (!isReload && route.query?.filterTab == 'TODO')
    taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DO_TODAY);

  if (
    route.query?.tab &&
    parseInt(route.query?.tab?.toString()) !== TaskListTab.MyList
  )
    _processTabByRouteParams(route.query?.tab);
  else {
    currentGroup.value = await _groupStore.getDetailGroupById(groupId.value);
    await initListData(
      decryptGroupId(route.params?.id),
      ETaskListModule.GROUP,
      {
        isReload,
      }
    );
    changeByRouter(
      route?.name?.toString(),
      currentGroup.value?.name?.toString(),
      'Group'
    );
  }

  isInitialized.value = true;
};

const resourceFixedMember = computed(() => ({
  id: currentGroup.value?.id,
  isGroup: true,
}));

const { onListenFirebase, onListenMultiTask } = taskListRealtime();
onMounted(async () => {
  _taskTabViewStore.setCustomizeViewActive();
  const groupId = decryptGroupId(route.params?.id);
  onListenFirebase('group', groupId);
  onListenMultiTask('group', groupId);
});

watch(
  () => route.params,
  (newValue, oldValue) => {
    if (!isInitialized.value) return;
    if (!newValue?.id || !oldValue?.id || route.name !== 'GroupTasks') return;
    if (
      !isEqual(newValue, oldValue) &&
      newValue?.id &&
      currentGroup?.value?.id
    ) {
      const newGroupId = decryptGroupId(newValue?.id);
      onListenFirebase('group', newGroupId);
      onListenMultiTask('group', newGroupId);
      initData();

      changeByRouter(
        route?.name?.toString(),
        currentGroup.value?.name?.toString(),
        'Group'
      );
    }
  }
);

// DETAIL GROUP
const onShowSettingGroup = (location) => {
  currentTabInfoGroup.value = location;
  isShowInfoGroup.value = true;
};

const onResetFilterMyTasks = () => {
  initData(true);
};

const onApplyFilter = () => {
  taskListRef.value.initData();
};

const onDeleteSuccess = () => {
  useRouter().push({
    name: 'OverviewGroups',
  });
};
const _processTabByRouteParams = (tab: any) => {
  const currentTab = tab || TaskListTab.MyList;

  onChangeParentTab(TaskListTab[TaskListTab[currentTab]]);
};

initData();
</script>

<template>
  <div
    class="
      h-full
      w-full
      flex flex-col
      overflow-y-hidden overflow-x-hidden
      bg-white
    "
  >
    <!-- HEADER -->
    <div class="flex justify-between items-center">
      <div class="flex items-end justify-between px-2 h-full w-full">
        <div class="flex space-x-2">
          <AvatarByTaskList
            class="py-1"
            :avatar="currentGroup.avatarUrl"
            :name="currentGroup?.name"
            :id-contact="currentGroup?.id"
            :total-tasks="totalTasks"
            type="GROUP"
            :contact-object="currentGroup"
            :total-member="currentGroup?.groupUsers?.length"
            :is-loading="isLoading"
            @on-show-profile="onShowSettingGroup('INFO_GROUP')"
            @on-show-member="onShowSettingGroup('MEMBERS')"
          />

          <!-- NOTIFICATION BAR -->
          <div class="ml-2 hidden lg:flex items-start h-full">
            <BarNotificationGroup :group="currentGroup" />
          </div>
        </div>

        <div class="flex items-center">
          <TaskTabs
            is-group
            :active-tab="selectedTab"
            @tab-selected="onChangeParentTab"
          />
        </div>

        <div></div>
      </div>
    </div>

    <!-- MULTIPLE SELECT & FILTERS -->
    <UpdateMultipleActions v-if="selectedTaskIds?.length > 0" />
    <div v-else-if="isTasksTab" class="flex w-full border-t border-gray-100">
      <FilterTabs
        :key="isLoading?.toString()"
        :list-task-owner-id="currentGroup?.id"
        type="GROUP"
        :is-show-tab="selectedTab === TaskListTab.MyList"
        :view-mode-list="[
          ETaskViewType.LIST,
          ETaskViewType.BOARD,
          ETaskViewType.CALENDAR,
        ]"
        :source-type="ETaskListModule.GROUP"
        @on-change-tab="onChangeChildTab"
      >
        <template #actions>
          <div class="flex items-center">
            <ActionsListTask
              v-if="!isGroupPrivate || isGroupMember"
              :is-hidden-search-filter="!isTasksTab"
              :group-id="currentGroup?.id"
              :current-tab="selectedTab"
              :action-hidden="actionHidden"
              :is-hidden-filter="true"
              :is-show-reload="true"
              show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_GROUP"
              @on-show-profile="onShowSettingGroup('INFO_GROUP')"
              @on-export-task="isShowCustomExportModal = true"
              @on-apply-filter="onApplyFilter"
              @on-reset-filter="onResetFilterMyTasks"
              @on-reload="onResetFilterMyTasks"
            />
          </div>
        </template>
        <template #filter>
          <FilterTaskButton
            show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_GROUP"
            :group-id="currentGroup?.id"
            @on-reset-filter="onResetFilterMyTasks"
            @on-apply-filter="onApplyFilter"
          />
        </template>
      </FilterTabs>
    </div>

    <div class="relative flex-1 min-h-0">
      <div
        v-if="!isGroupPrivate || isGroupMember || (isGroupDelete && isAdmin)"
        class="w-auto h-full"
      >
        <div v-if="isLoading" class="flex flex-col h-full">
          <SynAnimation name="my-tasks" />
        </div>
        <template v-else>
          <div
            v-if="selectedTab === TaskListTab.Resources"
            style="height: calc(100vh - 7.3rem)"
          >
            <GedPage :fixed-member="resourceFixedMember" />
          </div>
          <div v-else-if="selectedTab === TaskListTab.Statistic">
            <GroupTaskStatisticPage :group-id="currentGroup?.id" />
          </div>
          <template v-else>
            <template v-if="viewTask === ETaskViewType.BOARD">
              <TaskBoard
                location="GROUP_TASKS"
                class="px-1 pb-2"
                :current-contact-id="groupId"
                :type="'GROUP'"
              />
            </template>
            <template v-else-if="viewTask === ETaskViewType.CALENDAR">
              <div
                v-permission-screen="{
                  screenCode: ALL_SCREENS.GROUP.TASK.CALENDAR,
                  isPage: true,
                }"
                class="w-full h-full"
              >
                <TaskCalendarView
                  :owner-id="groupId"
                  :source-type="ETaskListModule.GROUP"
                />
              </div>
            </template>
            <div
              v-else-if="selectedTab === TaskListTab.MyList"
              :style="
                !isCanCreateTask
                  ? 'height: 100%'
                  : isGroupMember
                  ? 'height: calc(100vh - 14rem)'
                  : 'height: calc(100vh - 10rem)'
              "
              class="bg-white h-full overflow-hidden"
            >
              <template v-if="viewTask === ETaskViewType.LIST">
                <TaskList
                  ref="taskListRef"
                  type="group"
                  location="DOMAIN_TASKS"
                  :info-owner="currentGroup"
                  :is-filter-in-table="true"
                  @reload="onResetFilterMyTasks"
                />
              </template>
            </div>
          </template>
        </template>
      </div>

      <div v-else class="w-auto pt-4" style="height: calc(100vh - 14rem)">
        <SynAnimation
          name="private"
          stype="width: 100px; height: 100px"
          :label="$t('HEADER_LABEL_CANNOT_VIEW_TASK_IN_PRIVATE_GROUP')"
        />
      </div>
      <TaskCreateLocal
        v-if="isGroupMember && isCanCreateTask"
        is-group
        :group-id="currentGroup?.id"
        :group-domains="currentGroup?.groupDomains"
        :is-show-apart="selectedTab !== TaskListTab.Resources"
      />
    </div>
  </div>

  <GroupDetailModal
    v-if="isShowInfoGroup"
    :group-id="groupId"
    position="list"
    :current-tab="currentTabInfoGroup"
    @cancel="isShowInfoGroup = false"
    @on-delete-success="onDeleteSuccess"
  />

  <CustomExportModal
    v-if="isShowCustomExportModal"
    module-name="Group"
    :group-id="currentGroup.id"
    :owner-name="currentGroup.name"
    :current-tab="selectedTab"
    @on-close="isShowCustomExportModal = false"
    @on-reset-filter="onResetFilterMyTasks"
  />
</template>
