<script setup lang="ts">
import { computed, ref } from 'vue';
import { copyToClipBroard } from '@/ui/hooks/commonFunction';
import { AlertType, openNotification } from '@/ui/hooks/commonHook';
import { translate } from '@/ui/plugins/i18n/myi18n';
import {
  isOpenCreateTaskDrawer,
  newTaskDefaultPayload,
} from '@/ui/modules/task/task-global-state';
import {
  downloadMessageFiles,
  prepareMessage,
} from '@/ui/plugins/firebases/firestore-database/constants';
import { ChatMessageAction } from '@/domain/enums/chat-enums';
import MessageDetailEntity from '@/domain/entities/messages/MessageDetailEntity';
import CalendarCreateNewDropDown from '@/ui/modules/calendar/general/CalendarCreateNewDropDown.vue';
import MessageDocModel from '@/ui/plugins/firebases/firestore-database/models/MessageDocModel';
import { copyImageToClipBroard } from '@/ui/components/file-viewer/copyToClipboard';
import { getObjectDataByUrl } from '@/ui/plugins/awss3/aws-s3-services';
import $constants from '@/ui/plugins/constants';

const props = defineProps<{
  messageId: string;
  conversationId: string;
  conversation: any;
  organizationId: number;
  isMyMessage: boolean;
  contact: any;
  message: any;
  files: any;
  isDisabled: boolean;
  allowedActions?: ChatMessageAction[];
}>();

const emits = defineEmits([
  'isHasAction',
  'onMessageReply',
  'onMessageForward',
  'onMessageCreateTask',
  'onMessageDelete',
  'onMessagePinToggle',
  'onOpenCreateNewEvent',
]);

const isShowMenu = ref(false);

const openMenu = () => {
  isShowMenu.value = true;
  emits('isHasAction', true);
};
const closeMenu = () => {
  isShowMenu.value = false;
  emits('isHasAction', false);
};

const isPageMessage = computed(() =>
  window.location.href?.includes('messages')
);
const canDeleteMessage = computed(
  () => props.isMyMessage || props.contact?.isAdminGroup
);

const canCopyImage = computed(
  () =>
    props.files?.length === 1 &&
    props.files[0]?.contentType?.startsWith($constants.FILE.TYPE.IMAGE)
);

window.addEventListener('scroll', closeMenu, true);

const placementPage = computed(() => {
  if (isPageMessage.value) {
    if (props.isMyMessage) {
      return 'top-end';
    } else {
      return 'top-start';
    }
  } else {
    return 'top-end';
  }
});

const onMessageFilesDownloadClick = () => {
  if (!props.files?.length) return;

  downloadMessageFiles(props.files);

  isShowMenu.value = false;
};

const onMessageImageCopyClick = async () => {
  try {
    const fileUrl = props.files?.length === 1 ? props.files[0]?.url_full : null;

    if (!fileUrl) throw false;

    const imageDataFromS3 = await getObjectDataByUrl(fileUrl, 'arraybuffer');

    const copied = await copyImageToClipBroard(imageDataFromS3?.data);

    if (copied) {
      openNotification({
        title: translate('COMMON_LABEL_COPIED'),
        body: 2000,
      });
    }
  } catch {
    openNotification({
      type: AlertType.warning,
      body: translate('COMMON_LABEL_CANNOT_COPY'),
      duration: 2000,
    });
  }

  isShowMenu.value = false;
};

const onMessageCopyText = () => {
  const textCopy = props.message?.text || props.message?.comment;
  if (!textCopy) return;

  const elementMessage = document.createElement('html');
  elementMessage.innerHTML = textCopy.replaceAll('<br>', '\n ');

  copyToClipBroard(elementMessage.innerText);

  openNotification({
    title: translate('COMMON_LABEL_SUCCESS'),
    body: translate('CHAT_LABEL_COPY_TEXT_SUCCESS'),
    duration: 3000,
  });

  isShowMenu.value = false;
};

const onMessageCreateTask = async () => {
  if (!props.organizationId || !props.conversationId || !props.messageId) {
    return;
  }

  let replyToMsg = {};
  const replyToMessageId = props.message?.replyTo;
  if (replyToMessageId) {
    const doc = await _getConversationMessagesId(
      props.conversationId,
      replyToMessageId
    );
    if (doc?.exists() && doc?.data()) {
      replyToMsg = prepareMessage(replyToMessageId, doc?.data());
    }
  }

  const CurrentMessageEntity = new MessageDetailEntity({
    ...props.message,
    id: props.messageId,
    organizationId: props.organizationId,
    conversationId: props.conversationId,
    replyToMsg,
  });

  newTaskDefaultPayload.value = await CurrentMessageEntity.getDataForCreateTask(
    props.contact
  );

  isOpenCreateTaskDrawer.value = true;
  console.log(
    '🚀 Tictop ~ isOpenCreateTaskDrawer.value:',
    isOpenCreateTaskDrawer.value
  );

  isShowMenu.value = false;

  emits('onMessageCreateTask');
};

const _getConversationMessagesId = (conversationId, messageId) => {
  if (!props.organizationId || !messageId || !conversationId) return;

  return new MessageDocModel(conversationId, messageId).getMessage();
};
</script>
<template>
  <VigDropdown
    :arrow="false"
    hide-on-click="toggle"
    :placement="placementPage"
    @on-dropdown-open="openMenu()"
    @on-dropdown-close="closeMenu()"
  >
    <template #dropdown-toggle>
      <div
        class="p-1 flex-center rounded-full hover:hover:bg-gray-100 w-7 h-7"
        :class="isShowMenu ? 'bg-gray-100' : ''"
      >
        <SynIcon
          name="DotsVertical"
          custom-class="w-3.5 h-3.5 fill-gray-500 text-gray-500"
        />
      </div>
    </template>
    <template v-if="isShowMenu" #dropdown-menu>
      <div class="bg-white p-1 rounded text-sm w-max h-max">
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.FORWARD) &&
            !message?.call
          "
          class="
            py-2
            pl-2
            pr-4
            dropdown-item
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600 text-sm
          "
          @click="[$emit('onMessageForward'), (isShowMenu = false)]"
        >
          <SynIcon
            name="Forward"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{ $t('CHAT_FORWARD') || 'Forward' }}</span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.PIN) && !message?.call
          "
          class="
            py-2
            pl-2
            pr-4
            dropdown-item
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600 text-sm
          "
          @click="[$emit('onMessagePinToggle'), (isShowMenu = false)]"
        >
          <SynIcon
            :name="message?.pinned ? 'Unpin' : 'Pin'"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{
            message?.pinned
              ? $t('CHAT_UNPIN') || 'Unpin'
              : $t('CHAT_PIN') || 'Pin'
          }}</span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.CREATE_TASK) &&
            !message?.call
          "
          class="
            py-2
            pl-2
            pr-4
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600
            dropdown-item
            text-sm
          "
          @click="onMessageCreateTask"
        >
          <SynIcon
            name="Plus"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{
            $t('COMMON_LABEL_USE_CREATE_TASK') || 'Use this to create task'
          }}</span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.COPY) &&
            (message?.text || message?.comment)
          "
          class="
            py-2
            pl-2
            pr-4
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600
            dropdown-item
            text-sm
          "
          @click="onMessageCopyText"
        >
          <SynIcon
            name="Duplicate"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{ $t('COMMON_LABEL_COPY_TEXT') || 'Copy text' }}</span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.DOWNLOAD_FILE) &&
            canCopyImage
          "
          class="
            py-2
            pl-2
            pr-8
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600
            dropdown-item
            text-sm
          "
          @click="onMessageImageCopyClick"
        >
          <SynIcon custom-class="w-3.5 h-3.5 fill-gray-500" name="Image" />
          <span>
            {{ $t('COMMON_LABEL_COPY_IMAGE') }}
          </span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.DOWNLOAD_FILE) &&
            files?.length > 0
          "
          class="
            py-2
            pl-2
            pr-8
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600
            dropdown-item
            text-sm
          "
          @click="onMessageFilesDownloadClick"
        >
          <SynIcon
            name="Download2"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{
            $t('CHAT_DOWNLOAD_FILES', { number: files?.length }) ||
            'Save to your computer'
          }}</span>
        </button>
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.REPLY) &&
            !message?.call &&
            !isDisabled &&
            !conversation?.isGroupChatDisallowed
          "
          class="
            py-2
            pl-2
            pr-4
            dropdown-item
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-gray-600 text-sm
          "
          @click="[$emit('onMessageReply'), (isShowMenu = false)]"
        >
          <SynIcon
            name="Reply"
            custom-class="w-3.5 h-3.5"
            class="fill-gray-500"
          />
          <span>{{ $t('CHAT_REPLY') || 'Reply' }}</span>
        </button>

        <CalendarCreateNewDropDown
          v-if="
            allowedActions?.includes(ChatMessageAction.REMINDER) &&
            !message?.call
          "
          :message="message"
          @create-new-day-off="$emit('onOpenCreateNewEvent', 'DAYOFF')"
          @create-new-event="$emit('onOpenCreateNewEvent', 'EVENT')"
          @create-new-reminder="$emit('onOpenCreateNewEvent', 'REMINDER')"
        />
        <button
          v-if="
            allowedActions?.includes(ChatMessageAction.DELETE) &&
            canDeleteMessage
          "
          class="
            py-2
            pl-2
            pr-4
            w-full
            whitespace-nowrap
            hover:bg-gray-100
            flex
            items-center
            space-x-3
            text-red-600
            dropdown-item
            text-sm
          "
          @click="[$emit('onMessageDelete'), (isShowMenu = false)]"
        >
          <SynIcon name="Trash" custom-class="w-3.5 h-3.5 fill-red-500" />
          <span>{{ $t('COMMON_LABEL_DELETE') || 'Delete' }}</span>
        </button>
      </div>
    </template>
  </VigDropdown>
</template>
