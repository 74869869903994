<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import myProfileStore from '@/store/auth/my-profile';
import TaskList from '@/ui/modules/task/table/TaskList.vue';
import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import TaskTabs from '@/ui/modules/task/components/TaskTabs.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import userStore from '@/store/user';
import $constants from '@/ui/plugins/constants';
import { AdminType } from '@/ui/common/constants/constant';
import taskListStore from '@/store/task/task-list-store';
import { TaskListTab } from '@/domain/enums/taskEnum';
import customDocumentTitle from '@/ui/composables/app/document-title';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import CustomExportModal from '@/ui/modules/task/export/CustomExportModal.vue';
import AvatarByTaskList from '@/ui/modules/task/components/task-list/AvatarByTaskList.vue';
import FilterTabs from '@/ui/modules/task/components/task-list/FilterTabs.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TaskBoard from '@/ui/modules/task/board/TaskBoard.vue';
import UserTaskStatisticPage from '@/ui/modules/task/statistic/UserTaskStatisticPage.vue';
import ManagerTabsModal from '@/ui/modules/task/tabs-list-task/manager-tabs/ManagerTabsModal.vue';
import tableListTaskComposable from '@/ui/composables/task/table-list-task';
import SelectTaskDoTodayModal from '@/ui/modules/task/table/SelectTaskDoTodayModal.vue';
import ActionsListTask from '@/ui/modules/task/filter/ActionsListTask.vue';
import UpdateMultipleActions from '@/ui/modules/task/table/UpdateMultipleActions.vue';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import {
  EFilterTaskDefault,
  ETaskViewType,
} from '@/application/constants/task.const';
import CustomImportModal from '@/ui/modules/task/import/CustomImportModal.vue';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ETaskListModule } from '@/application/types/task/task.types';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';
import { ResourceTab } from '@/domain/enums/resource-enums';
import ToolsSidebar from '@/ui/modules/tools/tools-sidebar/ToolsSidebar.vue';
import PersonalUpgradeModal from '@/ui/modules/organization/PersonalUpgradeModal.vue';
import permissionStore from '@/store/permission';
import TaskCalendarView from '@/ui/pages/tasks/calendar/TaskCalendarView.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import remoteConfigStore from '@/store/remoteConfig';

const route = useRoute();
const _userStore = userStore();
const _taskListStore = taskListStore();
const _taskTabViewStore = taskTabViewStore();
const _remoteConfigStore = remoteConfigStore();

const { changeByRouter } = customDocumentTitle();

const customizeView = computed(() => _taskTabViewStore.customizeView);
const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const isLoading = computed(() => _taskListStore.isLoading);
const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const selectedTab = computed<TaskListTab>(() => _taskListStore.activeTab);
const currentUser = computed(() =>
  _userStore.getDetailByUserId(getCurrentUserId())
);
const isDeactiveUser = computed(() => currentUser.value?.isDeactive);
const totalTasks = computed(() => _taskListStore.taskAllList.length);
const myProfile = computed(() => myProfileStore().myProfile);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resourceFixedMember = computed(() => ({ id: myProfile.value?.id }));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resourceDefaultCategory = computed(() =>
  myProfile?.value.id !== getCurrentUserId()
    ? $constants.FILE.CATEGORY.TASK
    : $constants.FILE.CATEGORY.STORAGE
);
const webTaskHiddenCreateFormBottom = computed<boolean>(() => {
  return _remoteConfigStore.webTaskHiddenCreateFormBottom;
});
const isCanCreateTask = computed(() => {
  return (
    webTaskHiddenCreateFormBottom.value &&
    (selectedTab.value === TaskListTab.MyAssign ||
      selectedTab.value === TaskListTab.MyList) /* ||
      selectedTab.value === TaskListTab.Resources*/ &&
    !isDeactiveUser.value &&
    currentUser.value?.adminType !== AdminType.Visitor
  );
});

const isTasksTab = computed(
  () =>
    selectedTab.value == TaskListTab.MyList ||
    selectedTab.value == TaskListTab.MyAssign ||
    selectedTab.value == TaskListTab.WaitingApprovalTasks
);

const isShowProfile = ref(false);
const isLoadingDetailUser = ref(false);
const taskListRef = ref(null as any);
const isShowCustomExportModal = ref(false);
const isShowCustomImportModal = ref(false);
const isManagerTabs = ref(false);
const isOpenSelectTaskDoToday = ref(false);
const formCreateTaskRef = ref();
const activeToolsTab = ref();

const { initListData, onChangeParentTab, onChangeChildTab } =
  tableListTaskComposable();
const { onListenFirebase, onListenMultiTask } = taskListRealtime();

let { unsubscribeCallbacks } = listenFirebaseRealtime();

const isReady = ref<boolean>(false);

watch(
  () => route.query?.tab,
  (tab) => {
    if (!tab) return;

    _processTabByRouteParams(tab);
  }
);

const handleRealtimeStatistic = () => {
  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange(() => {
      if (!isReady.value) {
        isReady.value = true;
        return;
      }
      Promise.all([
        _taskListStore.getTaskSummaryStatistics(
          ETaskListModule.USER,
          getCurrentUserId()
        ),
        _taskListStore.getAllAssigneeTaskStatisticByUser(getCurrentUserId()),
      ]);
    })
  );
};

onMounted(() => {
  _taskTabViewStore.setCustomizeViewActive();
  myProfileStore().getUserTaskSchedule();
  handleRealtimeStatistic();

  const userId = getCurrentUserId();
  if (!userId) return;
  onListenFirebase('user', userId);
  onListenMultiTask('user', userId);
  changeByRouter(
    route?.name?.toString(),
    currentUser.value?.name?.toString(),
    'Team'
  );
});

const currentLastUpdatedTime = ref<number>(0);

const currentSourceType = computed(() => {
  return selectedTab.value == TaskListTab.MyAssign
    ? ETaskListModule.I_ASSIGNED
    : selectedTab.value == TaskListTab.WaitingApprovalTasks
    ? ETaskListModule.WAITING_MY_APPROVAL
    : ETaskListModule.USER;
});

const initData = async (isReload?) => {
  _taskTabViewStore.getUserCustomTaskFilters(
    ETaskListModule.USER,
    getCurrentUserId()
  );

  if (!isReload && route.query?.filterTab == 'TODO')
    taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DO_TODAY);

  if (
    route.query?.tab &&
    parseInt(route.query?.tab?.toString()) !== TaskListTab.MyList
  )
    _processTabByRouteParams(route.query?.tab);
  else {
    initListData(getCurrentUserId(), currentSourceType.value, {
      isReload,
    });
  }
};

watch(
  () => _taskListStore.lastUpdatedTime,
  (newValue) => {
    if (isLoading.value || !newValue) return;
    if (!currentLastUpdatedTime.value) {
      currentLastUpdatedTime.value = Date.now();
      return;
    }

    if (newValue > currentLastUpdatedTime.value) {
      initListData(getCurrentUserId(), currentSourceType.value);
      currentLastUpdatedTime.value = Date.now();
    }
  }
);

const onApplyFilter = () => {
  taskListRef.value.initData();
};

const onResetFilterMyTasks = () => {
  if (selectedTab.value == TaskListTab.Profile) {
    isLoadingDetailUser.value = !isLoadingDetailUser.value;
  } else {
    initData(true);
  }
};

const onManagerTab = () => {
  isManagerTabs.value = !isManagerTabs.value;
};

const onOpenFormCreateTask = () => {
  formCreateTaskRef.value?.onOpenInterface();
};

const onSelectTaskDoToday = () => {
  isOpenSelectTaskDoToday.value = true;
};

const _processTabByRouteParams = (tab: any) => {
  const currentTab = tab || TaskListTab.MyList;

  onChangeParentTab(TaskListTab[TaskListTab[currentTab]]);
};
const isPersonalModel = computed(
  () => permissionStore().organizationModel === 'PERSONAL'
);

const isOpenModalInvite = ref<boolean>(false);
const onInviteUser = () => {
  isOpenModalInvite.value = true;
};

initData();
</script>

<template>
  <div
    class="
      h-full
      w-full
      flex flex-col
      overflow-y-hidden overflow-x-hidden
      bg-white
    "
  >
    <!-- HEADER -->
    <div class="flex justify-between items-center border-b border-gray-100">
      <div class="flex items-end justify-between h-full w-full px-2">
        <AvatarByTaskList
          class="py-1"
          :contact-object="currentUser"
          type="MEMBER"
          :name="currentUser?.name"
          :avatar="currentUser?.profilePictureThumbsUrl"
          :total-tasks="totalTasks"
          :id-contact="currentUser?.id || currentUser?.userId"
          :is-loading="isLoading"
          @on-show-profile="isShowProfile = true"
        />

        <div class="flex items-center">
          <TaskTabs
            :active-tab="selectedTab"
            :tab-list="['TASKS', 'WAITING_APPROVAL_TASKS', 'RESOURCES']"
          />
        </div>

        <div>
          <template v-if="isPersonalModel">
            <SynButton
              v-vig-tooltip="{
                content: `
              <div class='rounded px-2 py-4 w-48 text-center bg-white text-current-500'>
                <span class='text-md'>${$t('SETUP_TEAM_INVITE_DES')}</span>
              </div>`,
                arrow: true,
                placement: 'bottom',
                delayArr: [0, 500],
                theme: 'light',
                animation: 'scale',
                interactive: true,
                showOnCreate: true,
                zIndex: 40,
                hideAfter: true,
              }"
              v-permission="'FunctionOrganizationInvitation'"
              custom-class="rounded text-xs py-0.5 h-10"
              color="current"
              @click="onInviteUser()"
            >
              <div class="flex-center space-x-2 py-0.5">
                <SynIcon name="AddMember" custom-class="fill-white w-6 h-6" />
                <span>{{ $t('USERMANAGEMENT_LABEL_INVITE_USER') }}</span>
              </div>
            </SynButton>
          </template>
        </div>
      </div>
    </div>

    <!-- MULTIPLE SELECT & FILTERS -->
    <UpdateMultipleActions v-if="selectedTaskIds?.length > 0" />
    <div v-else-if="isTasksTab" class="flex w-full">
      <FilterTabs
        :key="isLoading?.toString()"
        :list-task-owner-id="myProfile?.id"
        :is-show-tab="isTasksTab"
        :is-hidden-custom-tab="selectedTab !== TaskListTab.MyList"
        :is-approval-list="selectedTab == TaskListTab.WaitingApprovalTasks"
        :view-mode-list="
          selectedTab == TaskListTab.WaitingApprovalTasks
            ? [ETaskViewType.LIST]
            : [ETaskViewType.LIST, ETaskViewType.BOARD, ETaskViewType.CALENDAR]
        "
        :source-type="currentSourceType"
        @on-change-tab="onChangeChildTab"
      >
        <template #actions>
          <div class="flex items-center">
            <ActionsListTask
              :list-task-owner-id="myProfile?.id"
              :user-id="
                selectedTab == TaskListTab.MyAssign ? undefined : myProfile?.id
              "
              :assignor-id="
                selectedTab == TaskListTab.MyAssign ? myProfile?.id : undefined
              "
              :current-children-tab="childrenTabActive"
              :current-tab="selectedTab"
              :is-hidden-search-filter="!isTasksTab"
              :is-hidden-filter="true"
              is-show-reload
              :action-hidden="
                !isTasksTab
                  ? ['information', 'export_task', 'manager_tab']
                  : ['information', 'manager_tab']
              "
              is-allow-import-task
              show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_USER"
              @on-export-task="isShowCustomExportModal = true"
              @on-import-task="isShowCustomImportModal = true"
              @on-reset-filter="onResetFilterMyTasks"
              @on-apply-filter="onApplyFilter"
              @on-manager-tab="onManagerTab"
              @on-reload="onResetFilterMyTasks"
            />
          </div>
        </template>
        <template #filter>
          <FilterTaskButton
            :list-task-owner-id="myProfile?.id"
            :user-id="
              selectedTab == TaskListTab.MyAssign ? undefined : myProfile?.id
            "
            :assignor-id="
              selectedTab == TaskListTab.MyAssign ? myProfile?.id : undefined
            "
            :current-children-tab="childrenTabActive"
            :current-tab="selectedTab"
            show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_USER"
            @on-apply-filter="onApplyFilter"
            @on-reset-filter="onResetFilterMyTasks"
          />
        </template>
      </FilterTabs>
    </div>
    <!--    <div v-else class="py-1"></div>-->

    <div class="relative flex-1 min-h-0">
      <div class="relative h-full">
        <div
          v-if="isLoading && selectedTab !== TaskListTab.Resources"
          class="flex flex-col h-full"
        >
          <SynAnimation name="my-tasks" />
        </div>
        <template v-else>
          <div v-if="selectedTab === TaskListTab.Resources" class="h-full flex">
            <ToolsSidebar
              v-model:active-tab="activeToolsTab"
              :class="{
                hidden: activeToolsTab?.id === ResourceTab.GoogleDocuments,
              }"
            />

            <div class="flex-1 overflow-hidden">
              <GedPage
                v-if="activeToolsTab?.id === ResourceTab.Files"
                is-my-resource
                :fixed-member="resourceFixedMember"
                :default-category="resourceDefaultCategory"
                class="pt-1 shadow"
              />
            </div>
          </div>

          <!--          <div v-if="selectedTab === TaskListTab.Resources">-->
          <!--            <div class="flex-1 overflow-hidden">-->
          <!--              <GedPage-->
          <!--                is-my-resource-->
          <!--                :fixed-member="resourceFixedMember"-->
          <!--                :default-category="resourceDefaultCategory"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-else-if="selectedTab === TaskListTab.Statistic" class="h-full">
            <UserTaskStatisticPage :user-id="myProfile?.id" />
          </div>

          <template v-else>
            <template v-if="customizeView === ETaskViewType.BOARD">
              <TaskBoard
                class="px-1"
                location="MY_TASKS"
                :is-show-all="selectedTab === TaskListTab.MyList"
                :type="selectedTab === TaskListTab.MyAssign ? 'IA' : 'USER'"
                :current-contact-id="myProfile?.id"
              />
            </template>
            <template v-else-if="customizeView === ETaskViewType.CALENDAR">
              <div
                v-permission-screen="{
                  screenCode: ALL_SCREENS.GROUP.TASK.CALENDAR,
                  isPage: true,
                }"
                class="w-full h-full"
              >
                <TaskCalendarView
                  :owner-id="currentUser?.id"
                  :source-type="ETaskListModule.USER"
                />
              </div>
            </template>
            <div
              v-else
              :style="
                isCanCreateTask ? 'height: calc(100vh - 14rem)' : 'height: 100%'
              "
              class="bg-white h-full overflow-hidden"
            >
              <template v-if="customizeView === ETaskViewType.LIST">
                <TaskList
                  ref="taskListRef"
                  location="MY_TASKS"
                  :type="
                    selectedTab === TaskListTab.MyList
                      ? 'member'
                      : selectedTab === TaskListTab.WaitingApprovalTasks
                      ? 'waiting_approval'
                      : 'my_assign'
                  "
                  createable-task
                  :is-filter-in-table="true"
                  @reload="onResetFilterMyTasks"
                  @on-open-form-create-task="onOpenFormCreateTask"
                  @on-select-task-do-today="onSelectTaskDoToday"
                />
              </template>
            </div>
          </template>
        </template>
      </div>
      <TaskCreateLocal
        v-if="isCanCreateTask"
        ref="formCreateTaskRef"
        :assignee-id="myProfile?.id"
      />
    </div>
  </div>

  <SelectTaskDoTodayModal
    v-if="isOpenSelectTaskDoToday"
    @on-cancel="isOpenSelectTaskDoToday = false"
  />

  <ModalProfileUser
    v-if="isShowProfile"
    :profile-id="currentUser?.userId || currentUser?.id"
    @cancel="isShowProfile = false"
  />

  <CustomExportModal
    v-if="isShowCustomExportModal"
    module-name="User"
    :user-id="myProfile?.id"
    :owner-name="currentUser.name"
    :current-tab="selectedTab"
    @on-close="isShowCustomExportModal = false"
    @on-reset-filter="onResetFilterMyTasks"
  />

  <CustomImportModal
    v-if="isShowCustomImportModal"
    @on-close="isShowCustomImportModal = false"
  />

  <ManagerTabsModal v-if="isManagerTabs" @on-cancel="isManagerTabs = false" />

  <PersonalUpgradeModal
    v-if="isOpenModalInvite"
    is-inviting
    @on-cancel="isOpenModalInvite = false"
  />
</template>
