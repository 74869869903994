import { ref } from 'vue';
import { chain, toArray } from 'lodash';
import { formatDate } from '@/ui/plugins/filters';
import { diff } from '@/ui/hooks/hook-date';
import dayjs from 'dayjs';
import {
    MESSAGES_GROUP_MINUTE,
    MESSAGES_PAGE_SIZE,
} from '@/ui/modules/messaging/chat-panel/chat-panel-config';
import { Unsubscribe } from '@firebase/firestore';
import myProfileStore from '@/store/auth/my-profile';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { prepareMessage } from '@/ui/plugins/firebases/firestore-database/constants';
import ChatService from '@/application/services/ChatService';

export default function useChatMessages(organizationId, conversationId): any {
    let _unsubscribeAddedMessages: Unsubscribe;
    let _unsubscribeUpdatedMessages: Unsubscribe;
    let _messageIdsInLocal: string[] = [];
    let _messageIdsFromServer: string[] = [];
    let _lastMsgDocFromServer: any;
    let _lastMsgDocFromCache: any;
    let _lastSnapshotFromServer: number | null;

    const messageIds = ref<Array<string>>([]);
    const messagesObj = ref<{ [msgId: string]: ChatMessageModel }>({});
    const startDateByMsgId = ref<{ [msgId: string]: Date }>({});
    const positionByMsgId = ref<{
        [msgId: string]: { isFirst?: boolean; isLast?: boolean };
    }>({});
    const canLoadMoreMessages = ref<boolean>();
    const isLoadingMsgFromServer = ref<boolean>();

    const resetMessages = () => {
        if (_unsubscribeAddedMessages) _unsubscribeAddedMessages();
        if (_unsubscribeUpdatedMessages) _unsubscribeUpdatedMessages();
        _messageIdsInLocal = [];
        _messageIdsFromServer = [];
        _lastMsgDocFromServer = null;
        _lastMsgDocFromCache = null;
        _lastSnapshotFromServer = null;

        canLoadMoreMessages.value = false;
        isLoadingMsgFromServer.value = false;
    };

    const initMessagesFromCache = async (converId, lastDeletedDate) => {
        const snapshots = await ChatService.getMessagesFromLocal(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            MESSAGES_PAGE_SIZE
        );

        if (converId !== conversationId.value) return;

        const msgObj = {};
        const msgIds: any = [];

        snapshots?.forEach((doc) => {
            msgObj[doc.id] = prepareMessage(doc.id, doc.data());
            msgIds.push(doc.id);

            _lastMsgDocFromCache = doc;
        });

        messagesObj.value = msgObj;
        _messageIdsInLocal = _processMessageIds(msgIds);
        _processMessageDatesAndPositions();
        messageIds.value = _messageIdsInLocal.slice(0, MESSAGES_PAGE_SIZE);
        _processCanLoadMore();

        _initMoreMessagesFromCache(converId, lastDeletedDate).then().catch();
    };

    const initMessagesFromServer = async (converId, lastDeletedDate) => {
        const snapshots = await ChatService.getMessagesFromServer(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            MESSAGES_PAGE_SIZE
        );

        if (converId !== conversationId.value) return;

        snapshots?.forEach((doc) => {
            messagesObj.value[doc.id] = prepareMessage(doc.id, doc.data());

            if (!_messageIdsInLocal?.includes(doc.id)) {
                _messageIdsInLocal.push(doc.id);
            }
            if (!_messageIdsFromServer?.includes(doc.id)) {
                _messageIdsFromServer.push(doc.id);
            }

            _lastMsgDocFromServer = doc;
        });

        _messageIdsInLocal = _processMessageIds(_messageIdsInLocal);
        _processMessageDatesAndPositions();
        messageIds.value = _messageIdsInLocal.slice(0, MESSAGES_PAGE_SIZE);
        _processCanLoadMore();

        _lastSnapshotFromServer = snapshots?.size;
    };

    const subscribeMessages = (
        converId,
        lastDeletedDate,
        onNewMsg: () => void
    ) => {
        if (_unsubscribeAddedMessages) _unsubscribeAddedMessages();
        if (_unsubscribeUpdatedMessages) _unsubscribeUpdatedMessages();

        _unsubscribeAddedMessages = ChatService.subscribeAddedMessages(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            1,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                let newMsgId: string = '';

                snapshot.docChanges().forEach((change) => {
                    const msgId = change?.doc?.id;
                    const msgData = prepareMessage(msgId, change?.doc?.data());
                    if (
                        lastDeletedDate &&
                        msgData.createdDate < lastDeletedDate.toDate()
                    ) {
                        return;
                    }

                    switch (change?.type) {
                        case 'added': {
                            messagesObj.value[msgId] = msgData;

                            if (!_messageIdsInLocal.includes(msgId)) {
                                _messageIdsInLocal.push(msgId);
                                newMsgId = msgId;
                            }
                            break;
                        }
                    }
                });

                _messageIdsInLocal = _processMessageIds(_messageIdsInLocal);
                _processMessageDatesAndPositions();

                if (newMsgId) {
                    messageIds.value = _messageIdsInLocal.slice(
                        0,
                        messageIds.value?.length + 1
                    );

                    if (onNewMsg) onNewMsg();
                }

                _processCanLoadMore();
            }
        );

        _unsubscribeUpdatedMessages = ChatService.subscribeUpdatedMessages(
            organizationId.value,
            converId,
            null,
            lastDeletedDate,
            1,
            (snapshot) => {
                if (converId !== conversationId.value) return;

                snapshot.docChanges().forEach((change) => {
                    const msgId = change?.doc?.id;
                    const msgData = prepareMessage(msgId, change?.doc?.data());
                    if (
                        lastDeletedDate &&
                        msgData.createdDate < lastDeletedDate.toDate()
                    ) {
                        return;
                    }

                    switch (change?.type) {
                        case 'added':
                        case 'modified': {
                            messagesObj.value[msgId] = msgData;

                            if (_messageIdsInLocal.includes(msgId)) {
                                _messageIdsInLocal =
                                    _processMessageIds(_messageIdsInLocal);
                                _processMessageDatesAndPositions();
                                _processCanLoadMore();
                            }
                            break;
                        }
                    }
                });
            }
        );
    };

    const loadMoreMessages = (converId, lastDeletedDate) => {
        if (canLoadMoreMessages.value) {
            messageIds.value = _messageIdsInLocal.slice(
                0,
                messageIds.value?.length + MESSAGES_PAGE_SIZE
            );

            _processCanLoadMore();
        }

        // Load more messages from server
        if (
            messageIds.value?.length >=
            _messageIdsFromServer?.length - MESSAGES_PAGE_SIZE * 2
        ) {
            _loadMoreMessagesFromServer(converId, lastDeletedDate)
                .then()
                .catch();
        }
    };

    const addNewSentMessageToList = (converId, message) => {
        if (!message) return;

        // Process message date
        message.createdDate = new Date();

        messagesObj.value[message?.id] = prepareMessage(message?.id, message);
        _messageIdsInLocal.push(message?.id);

        _messageIdsInLocal = _processMessageIds(_messageIdsInLocal);

        _processMessageDatesAndPositions();

        messageIds.value = _messageIdsInLocal.slice(
            0,
            messageIds.value?.length + 1
        );
    };

    const updateMessageInList = (converId, message) => {
        if (!message) return;

        messagesObj.value[message?.id] = prepareMessage(message?.id, message);
    };

    const removeMessage = async (converId, msgId) => {
        const userId = myProfileStore().myProfile?.id;

        if (!converId || !userId) return;

        const updMsg = await ChatService.removeMessage(
            organizationId.value,
            converId,
            msgId,
            userId
        );

        // Delete message locally
        messagesObj.value[msgId] = { ...messagesObj.value[msgId], ...updMsg };
    };

    const _initMoreMessagesFromCache = async (converId, lastDeletedDate) => {
        try {
            const snapshots = await ChatService.getMessagesFromLocal(
                organizationId.value,
                converId,
                _lastMsgDocFromCache,
                lastDeletedDate,
                1000 - MESSAGES_PAGE_SIZE
            );

            if (converId !== conversationId.value) return;

            snapshots?.forEach((doc) => {
                messagesObj.value[doc.id] = prepareMessage(doc.id, doc.data());
                if (!_messageIdsInLocal?.includes(doc.id)) {
                    _messageIdsInLocal.push(doc.id);
                }
            });

            _messageIdsInLocal = _processMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            _processCanLoadMore();
        } catch (e) {
            console.log(e);
        }
    };

    const _loadMoreMessagesFromServer = async (converId, lastDeletedDate) => {
        if (isLoadingMsgFromServer.value || !_lastSnapshotFromServer) return;

        isLoadingMsgFromServer.value = true;

        try {
            const snapshots = await ChatService.getMessagesFromServer(
                organizationId.value,
                converId,
                _lastMsgDocFromServer,
                lastDeletedDate,
                MESSAGES_PAGE_SIZE * 5
            );

            if (converId !== conversationId.value) return;

            snapshots?.forEach((doc) => {
                messagesObj.value[doc.id] = prepareMessage(doc.id, doc.data());

                if (!_messageIdsInLocal?.includes(doc.id)) {
                    _messageIdsInLocal.push(doc.id);
                }
                if (!_messageIdsFromServer?.includes(doc.id)) {
                    _messageIdsFromServer.push(doc.id);
                }

                _lastMsgDocFromServer = doc;
            });

            _messageIdsInLocal = _processMessageIds(_messageIdsInLocal);
            _processMessageDatesAndPositions();
            _processCanLoadMore();

            _lastSnapshotFromServer = snapshots?.size;
        } catch (e) {
            console.log(e);
        }

        isLoadingMsgFromServer.value = false;

        if (_lastSnapshotFromServer) {
            loadMoreMessages(converId, lastDeletedDate);
        }
    };

    const _processMessageIds = (msgIds) => {
        return chain(msgIds)
            .uniq()
            .orderBy((msgId) => messagesObj.value[msgId]?.createdDate, 'desc')
            .value();
    };

    const _processMessageDatesAndPositions = () => {
        const startDates = {};
        const msgPositions = {};

        let prevMsgId, prevMsgTime, prevMsgUser;

        _messageIdsInLocal.forEach((msgId, currIdx) => {
            const currMessage = messagesObj.value[msgId];

            const currMsgUser = currMessage?.createdBy;
            const currMsgTime = currMessage?.createdDate;
            const currMsgFiles = Object.keys(currMessage?.files || {});
            const currMsgReplyTo = currMessage?.replyTo || '';
            const currMsgPinned = currMessage?.pinned;
            const currMsgCreatedTask = currMessage?.createdTasks?.length;

            // Process date
            const formattedDate = formatDate(currMsgTime, 'YYYY-MM-DD');

            if (
                !startDates[formattedDate] ||
                diff(
                    currMsgTime,
                    startDates[formattedDate]?.createdDate,
                    'milliseconds'
                ) < 0
            ) {
                startDates[formattedDate] = {
                    id: msgId,
                    createdDate: currMsgTime,
                };
            }

            // Process position
            msgPositions[msgId] = {};

            if (
                !prevMsgTime ||
                !prevMsgUser ||
                dayjs(prevMsgTime).diff(currMsgTime, 'minute') >
                    MESSAGES_GROUP_MINUTE ||
                currMsgUser !== prevMsgUser ||
                msgPositions[prevMsgId].isFirst ||
                currMsgFiles?.length ||
                currMsgReplyTo ||
                currMsgPinned ||
                currMsgCreatedTask
            ) {
                msgPositions[msgId].isLast = true;

                if (currIdx > 0 && !msgPositions[prevMsgId].isFirst) {
                    msgPositions[prevMsgId] = {
                        ...msgPositions[prevMsgId],
                        isFirst: true,
                    };
                }
            }

            if (
                currIdx === _messageIdsInLocal?.length - 1 ||
                currMsgFiles?.length ||
                currMessage?.task ||
                currMessage?.dayoff ||
                currMsgReplyTo ||
                currMsgPinned
            ) {
                msgPositions[msgId].isFirst = true;
            }

            prevMsgId = msgId;

            // Update last currMessage time & user
            prevMsgTime = currMsgTime;
            prevMsgUser = currMsgUser;
        });

        positionByMsgId.value = msgPositions;

        startDateByMsgId.value = chain(toArray(startDates))
            .keyBy('id')
            .mapValues('createdDate')
            .value();
    };

    const _processCanLoadMore = () => {
        canLoadMoreMessages.value =
            messageIds.value?.length < _messageIdsInLocal?.length;
    };

    return {
        messageIds,
        messagesObj,
        startDateByMsgId,
        positionByMsgId,
        canLoadMoreMessages,
        isLoadingMsgFromServer,

        resetMessages,
        initMessagesFromCache,
        initMessagesFromServer,
        subscribeMessages,
        loadMoreMessages,
        addNewSentMessageToList,
        updateMessageInList,
        removeMessage,
    };
}
