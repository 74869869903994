<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ThreeDotActions from '@/ui/components/ThreeDotActions/ThreeDotActions.vue';
import WelcomeModal from './WelcomeModal.vue';
import GroupById from '@/ui/components/group/GroupById.vue';
import UserById from '@/ui/components/user/UserById.vue';

import {
  getConfigScheduleColor,
  getUrgencyValue,
  isValidTaskCode,
} from '@/ui/hooks/taskHook';
import taskStore from '@/store/task';
import taskListStore from '@/store/task/task-list-store';
import TaskService from '@/application/services/task/TaskService';
import PrincipleService from '@/application/services/PrincipleService';
import principleStore from '@/store/principle';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import PriorityTask from '@/ui/modules/task/components/PriorityTask.vue';
import { TaskListTab } from '@/domain/enums/taskEnum';
import stepperComposables from '@/ui/composables/app/stepper-composables';
import settingStore from '@/store/setting';
import VigSearchBox from '@/ui/common/molecules/VigSearchBox/VigSearchBox.vue';
import startWorkingStore from '@/store/task/start-working-store';
import { ignoreUnicode } from '@/ui/plugins/utils';
import ColumnFilter from '@/ui/modules/task/table/ColumnFilter.vue';
import DomainById from '@/ui/modules/domains/component/DomainById.vue';
import SynTh from '@/ui/common/molecules/SynTableCustom/SynTh.vue';
import SynIcon from '@/ui/common/atoms/SynIcon/SynIconBasic.vue';
import TaskDetailDrawerMini from '@/ui/modules/task/detail/TaskDetailDrawerMini.vue';
import taskDrawerStore from '@/store/task/drawer';
import taskTabViewStore from '@/store/task/task-tab-view';
import { EFilterTaskDefault } from '@/application/constants/task.const';
import { ETaskListModule } from '@/application/types/task/task.types';
import {
  ask,
  settingGlobalModal,
} from '@/ui/common/molecules/SynModal/syn-confirm-modal-service';
import { translate } from '@/ui/plugins/i18n/myi18n';

defineEmits<{
  (e: 'onCancel'): void;
  (e: 'onStart'): void;
}>();

const router = useRouter();
const route = useRoute();
const _taskStore = taskStore();
const _taskListStore = taskListStore();
const _principleStore = principleStore();
const _settingStore = settingStore();
const _startWorkingStore = startWorkingStore();
const _taskDrawerStore = taskDrawerStore();
const _taskTabViewStore = taskTabViewStore();

const currentTaskId = computed(() => _taskDrawerStore.currentTabId);
const startLocalTime = computed(() => _principleStore.startLocalTime);
const isLoadingFetchTotoTask = ref(false);
const _taskService = TaskService.getInstance();
const startWorkingManagement = computed(
  () => _settingStore._organizationSetting.startWorkingManagement
);
const taskDetailPanelRef = ref(null as any);
const isOpenTaskDrawer = ref(false);

const isDisableButtonSubmit = computed(
  () =>
    startWorkingManagement.value.mustSelectTask &&
    planToTreatTasks.value.filter((el: any) => el.isPlannedToTreat).length < 1
);

// const filterDataByKey = computed(() => _taskListStore.filterDataByKey);
// const isLoadingTaskList = computed(() => _taskListStore.isLoading);

// const startWorkingStatus = ref('WELCOME');
const {
  currentStep: startWorkingStatus,
  stepperByKey: checkinStatusList,
  onHandleGoToPreviousStep,
  onHandleGoToNextStep,
} = stepperComposables(
  {
    WELCOME: {
      key: 'WELCOME',
      next: 'SELECT_TASKS',
      previous: 'CANCEL',
      previousText: '',
      confirmText: 'COMMON_LABEL_CONTINUE',
      nextAction: async () => {
        await fetchTodoTaskList();
      },
    },
    SELECT_TASKS: {
      key: 'SELECT_TASKS',
      previous: 'WELCOME',
      next: 'SELECT_TASKS_TO_FINISH',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'COMMON_LABEL_CONTINUE',
      nextAction: () => {
        _startWorkingStore.updateListTaskToFinish();
        _startWorkingStore.setFilterDataOrigin('SELECT_TASKS_TO_FINISH');
        _startWorkingStore.resetFilterToFinish();
        keySearchToFinish.value = '';
      },
      previousAction: () => {
        keySearch.value = '';
        _startWorkingStore.setFilterDataOrigin('');
      },
    },
    SELECT_TASKS_TO_FINISH: {
      key: 'SELECT_TASKS_TO_FINISH',
      previous: 'SELECT_TASKS',
      next: 'STARTED',
      previousText: 'COMMON_LABEL_PREVIOUS',
      confirmText: 'LABEL_FINISH_CHOOSE_TASK',
      nextAction: () => {
        handleStartWD();
      },
      previousAction: () => {
        _startWorkingStore.resetFilterToTreatTask();
        keySearch.value = '';
        _startWorkingStore.setFilterDataOrigin('');
      },
    },
  },
  'WELCOME'
);

const onClickSort = (key, name) => {
  _startWorkingStore.genSortDataList({ key, name }, startWorkingStatus.value);
};

// const onSortTaskDefault = (tasks) => {
//   return arrayOrderBy(tasks, ['isPlannedToTreat', 'urgency'], ['asc', 'desc']);
// };

// const onSortTaskToFinishDefault = (tasks) => {
//   return arrayOrderBy(tasks, ['isSelected', 'urgency'], ['desc', 'desc']);
// };

const onFilterByKeyOnColumn = (key, filters, status) => {
  _startWorkingStore.setFilterDataByKey(key, filters);
  _startWorkingStore.onFilterTaskOnColumn(status);
};

const onResetFilterOnColumn = (key, status) => {
  _startWorkingStore.resetFilterDataOrigin(key);
  _startWorkingStore.onFilterTaskOnColumn(status);
};

// search
const keySearch = ref('');
const keySearchToFinish = ref('');
const typeStatus = computed(() => _startWorkingStore.typeStatus);

const resetSearch = () => {
  keySearch.value = '';
  keySearchToFinish.value = '';
};

const handleFinishTask = (task, index) => {
  _startWorkingStore.handleFinishTask(task, index);
  //refresh list
};

const onCreateTaskGlobal = () => {
  // createTaskGlobal.value = {
  //   groupId: 0,
  //   userId: getCurrentUserId(),
  //   name: '',
  //   avatar: '',
  //   files: [],
  // };
  // isOpenCreateTaskGlobal.value = true;
  // onCreateGlobalSuccess.value = _startWorkingStore.updateTodoListAfterCreate;
  taskDetailPanelRef?.value?.onClickNewTab(true);
};

const currentTaskDrawerId = ref<any>(null);

const isLoading = ref(false);

const currentTabId = computed(() => _taskDrawerStore.currentTabId);
const currentTabInfo = computed(() => {
  if (!currentTabId.value) return {};
  return _taskDrawerStore.allTabByIds[currentTabId.value];
});
const handleStartWD = async () => {
  console.log('🚀 Tictop ~ currentTabInfo.value:', currentTabInfo.value);
  if (
    isOpenTaskDrawer.value &&
    currentTabInfo.value &&
    !isValidTaskCode(currentTabInfo.value?.tabCode)
  ) {
    settingGlobalModal({
      type: 'confirm',
      title: '',
      content: translate('START_WORKING_IN_PROCESS_CREATE_TASK_WARNING', {
        taskCode: currentTabInfo.value?.tabCode || '',
      }),
      confirmable: true,
      confirmLabel: translate('LABEL_FINISH_CHOOSE_TASK') || 'Confirm',
      closeable: true,
    });

    const confirmed = await ask();
    if (!confirmed) return false;
  }
  isLoading.value = true;

  await _taskService.saveTaskListPlannedToday(
    planToFinishTasks.value.map((o: any) => {
      return {
        isSelected: o.isSelected,
        taskId: o.id,
        planId: o.planId,
      };
    })
  );
  await onStartWorkingDay();
  // _dashboardStore.updateUserWorkingStatusList(
  //   getCurrentUserId(),
  //   UserWorkingStatusType.Online
  // );
  isLoading.value = false;
  // await _dashboardStore.getMemberOnday();
  _principleStore.changeCheckinStatus('STARTED');
  _principleStore.changeStarting(false);
  _principleStore.fetchUserWorkingStatus();

  _taskTabViewStore.changeTabActive(EFilterTaskDefault.DO_TODAY);

  if (route?.name == 'MyTasks') {
    _taskListStore.$reset();

    _taskListStore.setDefaultInitData(TaskListTab.MyList);
    _taskListStore.getTaskSummaryStatistics(
      ETaskListModule.USER,
      getCurrentUserId()
    );
    _taskListStore.setFilterPayload({
      listOwnerId: getCurrentUserId(),
    });
    await _taskListStore.fetchTasks(true, EFilterTaskDefault.DO_TODAY);

    // Assigned tab
    // _taskListStore.setFilterAssignedPayload({
    //   assignorId: getCurrentUserId(),
    // });
    // _taskListStore.fetchAssignedTasks();

    return;
  }

  router.push({ name: 'MyTasks' });
};

const onStartWorkingDay = () => {
  const startWD = new Date(startLocalTime.value) || new Date();
  PrincipleService.getInstance().startStopWorkingDay({
    type: 1,
    time: startWD,
    location: _principleStore.startWorkingLocation,
  });
};

const filterDataByKey = computed(() => _startWorkingStore.filterDataByKey);
const planToTreatTasksDefault = computed(
  () => _startWorkingStore.planToTreatTasksDefault
);
const planToFinishTasksDefault = computed(
  () => _startWorkingStore.planToFinishTasksDefault
);
const planToTreatTasks = computed(() => {
  if (
    !_startWorkingStore.planToTreatTasks ||
    _startWorkingStore.planToTreatTasks?.length == 0
  )
    return [];
  const result = _startWorkingStore.planToTreatTasks;
  const searchText = ignoreUnicode(keySearch.value);
  if (result && result.length > 0) {
    result.forEach(
      (el) => (el.urgency = getUrgencyValue(el.priority, el.important))
    );
  }
  // if (!searchText || result?.length == 0) return onSortTaskDefault(result);
  // return onSortTaskDefault(
  //   result.filter((o: any) => ignoreUnicode(o.name).includes(searchText))
  // );
  if (!searchText || result?.length == 0) return result;
  return result.filter((o: any) => ignoreUnicode(o.name).includes(searchText));
});
const planToFinishTasks = computed(() => {
  const result = _startWorkingStore.planToFinishTasks;
  const searchText = ignoreUnicode(keySearchToFinish.value);
  // if (!searchText || result?.length == 0)
  //   return onSortTaskToFinishDefault(result);
  // return onSortTaskToFinishDefault(
  //   result.filter((o: any) => ignoreUnicode(o.name).includes(searchText))
  // );
  if (!searchText || result?.length == 0) return result;
  return result.filter((o: any) => ignoreUnicode(o.name).includes(searchText));
});

const fetchTodoTaskList = async () => {
  isLoadingFetchTotoTask.value = true;
  try {
    await _startWorkingStore.fetchListTask();
  } catch (error) {
    console.log('🚀 Tictop ~ error:', error);
  }
  isLoadingFetchTotoTask.value = false;
};

const sortDataOrderByKey = computed(
  () => _startWorkingStore.sortDataOrderByKey
);

const handleSelectedTask = (task) => {
  _startWorkingStore.handleSelectedTask(task);
};

const handleSelectedTaskToFinish = (task) => {
  _startWorkingStore.handleSelectedTaskToFinish(task);
};

const getConfigScheduleName = (data) => {
  return _taskStore.getConfigScheduleName(data);
};

const onOpenTaskDetail = (task) => {
  _taskDrawerStore.pushCurrentIds({ ...task, tabType: 'DETAIL' }, false);
  taskDetailPanelRef?.value?.onOpenPanel();
};

const isTaskHasJustCreate = (task) => {
  const createTime = new Date(task?.creationTime).getTime();
  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const overTime = Date.now() + timeOffset - createTime; // milliseconds

  return overTime < 180 * 60 * 1000;
};

// const onClosePanel = () => {
//   console.log('click outside');
//   taskDetailPanelRef?.value?.onClosePanel();
// };

const onHandleNextStep = () => {
  switch (startWorkingStatus.value) {
    case 'SELECT_TASKS_TO_FINISH':
      handleStartWD();
      break;

    default:
      onHandleGoToNextStep();

      break;
  }
};
</script>

<template>
  <WelcomeModal
    v-if="startWorkingStatus == 'WELCOME'"
    :is-loading="isLoadingFetchTotoTask"
    @cancel="$emit('onCancel')"
    @on-next="onHandleGoToNextStep"
  />

  <SynModal
    v-if="
      (startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
        planToFinishTasksDefault.length > 0) ||
      (startWorkingStatus == 'STARTED' && planToFinishTasks.length > 0) ||
      startWorkingStatus == 'SELECT_TASKS'
    "
    z-index="z-40"
    disable-click-outside
    disable-esc-to-close
    container-class="w-3/4"
    container-style="height: 90%;"
    style-body="overflow-hidden py-0"
    has-element-active
    @cancel="$emit('onCancel')"
  >
    <template #header>
      <div @click="taskDetailPanelRef?.onClosePanel()">
        <span v-if="startWorkingStatus == 'SELECT_TASKS'">
          {{ $t('START_WORKING_LABEL_SELECT_TASKS') }}
        </span>
        <span
          v-if="
            startWorkingStatus == 'SELECT_TASKS_TO_FINISH' ||
            startWorkingStatus == 'STARTED'
          "
        >
          {{ $t('START_WORKING_LABEL_SELECT_TASKS_TO_FINISH') }}
        </span>
      </div>
    </template>
    <template #body>
      <div
        class="flex-1 min-h-0 flex w-full overflow-y-hidden overflow-x-auto"
        @click="taskDetailPanelRef?.onClosePanel()"
      >
        <div
          class="
            h-full
            w-56
            sticky
            top-0
            border-r border-current-50
            2xl:block
            hidden
          "
          style="min-height: 100%; width: 20%"
        >
          <div
            class="
              absolute
              top-1/4
              text-xl
              px-4
              text-center text-md
              md:text-md
              font-mono
              text-current-400
            "
          >
            <span>
              {{
                startWorkingStatus == 'SELECT_TASKS'
                  ? $t('HEADER_LABEL_WELCOME_CHOOSE_TASK')
                  : $t('HEADER_LABEL_WELCOME_FINISH_TASK')
              }}
            </span>
          </div>
          <div
            class="w-full h-full bg-current-50 bg-opacity-70 flex-center"
            style="max-height: 100%"
          >
            <!-- <img
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              :src="rocket"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            />
            <img
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              :src="finishTask"
              style="width: 200px; height: 200px;"
              class="absolute bottom-0 right-1"
            /> -->
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS'"
              name="rocket"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-4"
            />
            <SynAnimation
              v-if="startWorkingStatus == 'SELECT_TASKS_TO_FINISH'"
              name="startWD3"
              stype="width: 200px; height: 200px;"
              class="absolute bottom-4"
            />
          </div>
        </div>

        <div
          class="
            h-full
            overflow-y-auto overflow-x-auto
            flex-auto
            small-scrollbar
          "
        >
          <!-- select_tasks_todo -->
          <SynTableCustom
            v-if="startWorkingStatus == 'SELECT_TASKS'"
            class="rounded"
          >
            <template #header>
              <SynTr>
                <SynTh class="w-16"></SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_URGENCY')"
                  :is-sort="planToTreatTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['urgency']"
                  @on-sort="
                    onClickSort('urgency', $t('TASK_TABLE_LABEL_URGENCY'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="urgency"
                      :data-filters="filterDataByKey['urgency']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn('urgency', data, 'SELECT_TASKS')
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('urgency', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>

                <SynTh
                  :label="$t('TASK_TABLE_LABEL_TASKS')"
                  :is-sort="planToTreatTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['taskName']"
                  @on-sort="
                    onClickSort('taskName', $t('TASK_TABLE_LABEL_TASKS'))
                  "
                >
                  <template
                    v-if="
                      (planToTreatTasksDefault.length > 13 && !keySearch) ||
                      keySearch
                    "
                    #search
                  >
                    <div class="w-full flex items-center">
                      <VigSearchBox
                        v-model="keySearch"
                        class="font-normal ml-3 w-72"
                        input-class="text-sm"
                        :placeholder="
                          $t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK_NAME')
                        "
                        @click.stop="taskDetailPanelRef?.onClosePanel()"
                      >
                        <template v-if="keySearch" #suffix>
                          <SynIcon
                            has-action
                            name="close"
                            @click="resetSearch()"
                          />
                        </template>
                      </VigSearchBox>
                    </div>
                  </template>
                </SynTh>
                <SynTh
                  v-permission-model
                  :label="$t('TASK_CREATE_FORM_DOMAIN')"
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="domain"
                      :data-filters="filterDataByKey['domainId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'domainId',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('domainId', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh v-permission-model :label="$t('COMMON_LABEL_FROM')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="user"
                      :data-filters="filterDataByKey['creatorId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'creatorId',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('creatorId', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_DEADLINE')"
                  :is-sort="planToTreatTasksDefault.length <= 13 ? false : true"
                  :order-data="sortDataOrderByKey['scheduleTime']"
                  @on-sort="
                    onClickSort('scheduleTime', $t('TASK_TABLE_LABEL_DEADLINE'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToTreatTasksDefault.length > 13"
                      type="date"
                      :data-filters="filterDataByKey['scheduleTime']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'scheduleTime',
                            data,
                            'SELECT_TASKS'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn('scheduleTime', 'SELECT_TASKS')
                      "
                    />
                  </template>
                </SynTh>
                <SynTh class="w-12"></SynTh>
              </SynTr>
            </template>

            <template #body>
              <SynTr
                v-if="isLoadingFetchTotoTask"
                class="w-full h-full border-b-none"
              >
                <SynTd
                  v-for="item in 6"
                  :key="item"
                  class="p-2 py-4 justify-center"
                >
                  <div
                    class="p-2 w-full bg-gray-200 rounded-md animate-pulse"
                  ></div>
                </SynTd>
              </SynTr>
              <template v-else-if="planToTreatTasks?.length > 0">
                <SynTr
                  v-for="(task, index) in planToTreatTasks"
                  :key="task.id"
                  class="
                    border-b border-gray-200
                    hover:bg-gray-50
                    overflow-y-auto
                    small-scrollbar
                  "
                  style="height: 3rem"
                  :is-high-light="currentTaskDrawerId === task?.id"
                  @click="handleSelectedTask(task)"
                >
                  <SynTd class="text-center relative">
                    <span
                      v-if="currentTaskId == task?.id"
                      :class="
                        isOpenTaskDrawer ? 'border-current' : 'border-gray-300'
                      "
                      class="absolute left-0 top-0 h-full border"
                    />
                    <SynIcon
                      class="cursor-pointer fill-current text-current"
                      name="checkbox"
                      :is-active="task?.isPlannedToTreat || false"
                      @click.stop="handleSelectedTask(task)"
                    />
                  </SynTd>
                  <SynTd class="">
                    <!--                    <PriorityTask :urgency="task?.urgency" :is-label="false" />-->
                    <div class="h-full relative" style="width: fit-content">
                      <PriorityTask :urgency="task?.urgency" is-hidden-label />
                      <SynIcon
                        v-if="task.isPrivate"
                        name="Private"
                        custom-class="w-2.5 h-2.5 fill-current absolute"
                        style="top: 14px; right: -12px"
                      />
                    </div>
                  </SynTd>
                  <SynTd class="relative" style="width: 430px">
                    <span
                      v-if="isTaskHasJustCreate(task)"
                      class="
                        absolute
                        top-0
                        right-0
                        bg-current
                        text-white
                        px-1.5
                        rounded-b-md
                      "
                      style="font-size: 0.6rem"
                    >
                      New
                    </span>
                    <div class="flex space-y-1 break-words w-full flex-wrap">
                      <div
                        :title="task.name"
                        class="
                          cursor-pointer
                          text-overflow-hidden
                          hover:text-current-500
                          overflow-hidden
                          w-full
                          text-xs
                          2xl:text-sm
                        "
                        @click.stop="onOpenTaskDetail(task)"
                      >
                        <span class="font-semibold text-current-800">
                          {{
                            `${
                              task.subprojectName
                                ? task.subprojectName + ' - '
                                : ''
                            }`
                          }}
                        </span>
                        <span v-encode-html="{ text: task.name }"> </span>
                      </div>
                      <GroupById
                        v-if="task?.groupId"
                        :group-id="task.groupId"
                        class="px-2 text-2xs w-full justify-end"
                        avatar-class="w-4 h-4"
                      />
                    </div>
                  </SynTd>
                  <SynTd v-permission-model>
                    <div class="flex">
                      <DomainById
                        :domain-id="task?.domainId"
                        class="px-2 text-xs 2xl:text-sm"
                      />
                    </div>
                  </SynTd>
                  <SynTd v-permission-model>
                    <div class="flex">
                      <UserById
                        :user-id="task?.creatorId"
                        class="px-2 text-xs"
                        avatar-class="w-6 h-6"
                        is-hidden-name
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <SynTag
                      class="flex justify-start w-max"
                      size="small"
                      :class="getConfigScheduleColor(task)"
                    >
                      <span
                        v-if="task.assigneeId && getConfigScheduleName(task)"
                      >
                        {{ getConfigScheduleName(task) }}
                      </span>
                      <SynLabelDateTime
                        v-else-if="task.scheduleTime"
                        format="date"
                        :datetime="task.scheduleTime"
                      />
                      <span v-else>
                        {{
                          $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule'
                        }}
                      </span>
                    </SynTag>
                  </SynTd>
                  <SynTd class="p-2 text-center px-4" @click.stop>
                    <ThreeDotActions type="action">
                      <div
                        class="
                          space-x-2
                          flex
                          justify-start
                          items-center
                          text-sm
                          list-li
                        "
                        @click="handleFinishTask(task, index)"
                      >
                        <SynIcon
                          is-active
                          custom-class="fill-gray-500 w-4 h-4"
                          name="check-circle"
                        />
                        <span class="text-gray-600">
                          {{ $t('COMMON_LABEL_FINISH') }}
                        </span>
                      </div>
                    </ThreeDotActions>
                  </SynTd>
                </SynTr>
              </template>
              <template v-else>
                <SynTr class="h-full border-none">
                  <SynTd :colspan="6" class="">
                    <SynAnimation
                      :name="
                        typeStatus == 'LIST' ? 'searchNotFound' : 'searchIcon'
                      "
                      stype="width: 100px; height: 100px"
                      :label="
                        typeStatus == 'LIST'
                          ? $t('HEADER_LABEL_WELCOME_NOT_FOUND_TASK')
                          : $t('COMMON_LABEL_ALERT_NO_SEARCH')
                      "
                    />
                  </SynTd>
                </SynTr>
                <SynTr
                  v-if="typeStatus == 'LIST' && planToTreatTasks.length < 1"
                  class="h-full border-none w-full"
                >
                  <SynTd :colspan="6">
                    <div class="w-full flex-center">
                      <SynButton
                        name-icon="plus"
                        type-outline
                        :label="$t('COMMON_LABEL_CREATE_TASK')"
                        @click.stop="onCreateTaskGlobal"
                      >
                      </SynButton>
                    </div>
                  </SynTd>
                </SynTr>
              </template>
            </template>
          </SynTableCustom>

          <!-- select_task_finish -->
          <SynTableCustom
            v-else-if="
              (startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
                planToFinishTasksDefault.length > 0) ||
              (startWorkingStatus == 'STARTED' &&
                planToFinishTasksDefault.length > 0)
            "
            class="rounded"
          >
            <template #header>
              <SynTr class="h-12">
                <SynTh class="w-16"></SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_URGENCY')"
                  :is-sort="
                    planToFinishTasksDefault.length <= 13 ? false : true
                  "
                  :order-data="sortDataOrderByKey['urgency']"
                  @on-sort="
                    onClickSort('urgency', $t('TASK_TABLE_LABEL_URGENCY'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="urgency"
                      :data-filters="filterDataByKey['urgency']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'urgency',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'urgency',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_TASKS')"
                  :is-sort="planToFinishTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['taskName']"
                  @on-sort="
                    onClickSort('taskName', $t('TASK_TABLE_LABEL_TASKS'))
                  "
                >
                  <template
                    v-if="
                      (planToFinishTasksDefault.length > 13 && !keySearch) ||
                      keySearch
                    "
                    #search
                  >
                    <div class="w-full flex items-center">
                      <VigSearchBox
                        v-model="keySearchToFinish"
                        class="font-normal ml-3 w-72"
                        input-class="text-sm"
                        :placeholder="
                          $t('COMMON_LABEL_SEARCH_PLACEHOLDER_TASK_NAME')
                        "
                        @click.stop="() => {}"
                      >
                        <template v-if="keySearchToFinish" #suffix>
                          <SynIcon
                            has-action
                            name="close"
                            @click="resetSearch()"
                          />
                        </template>
                      </VigSearchBox>
                    </div>
                  </template>
                </SynTh>
                <SynTh
                  v-permission-model
                  :label="$t('TASK_CREATE_FORM_DOMAIN')"
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="domain"
                      :data-filters="filterDataByKey['domainId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'domainId',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'domainId',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh v-permission-model :label="$t('COMMON_LABEL_FROM')">
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="user"
                      :data-filters="filterDataByKey['creatorId']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'creatorId',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'creatorId',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
                <SynTh
                  :label="$t('TASK_TABLE_LABEL_DEADLINE')"
                  :is-sort="planToFinishTasksDefault.length > 13"
                  :order-data="sortDataOrderByKey['scheduleTime']"
                  @on-sort="
                    onClickSort('scheduleTime', $t('TASK_TABLE_LABEL_DEADLINE'))
                  "
                >
                  <template #custom>
                    <ColumnFilter
                      v-if="planToFinishTasksDefault.length > 13"
                      type="date"
                      :data-filters="filterDataByKey['scheduleTime']"
                      @on-ok="
                        (data) =>
                          onFilterByKeyOnColumn(
                            'scheduleTime',
                            data,
                            'SELECT_TASKS_TO_FINISH'
                          )
                      "
                      @on-reset-filter="
                        onResetFilterOnColumn(
                          'scheduleTime',
                          'SELECT_TASKS_TO_FINISH'
                        )
                      "
                    />
                  </template>
                </SynTh>
              </SynTr>
            </template>
            <template #body>
              <template v-if="planToFinishTasksDefault.length > 0">
                <SynTr
                  v-for="task in planToFinishTasks"
                  :key="task.id"
                  class="border-b border-gray-200 hover:bg-gray-50"
                  style="height: 3rem"
                  @click="handleSelectedTaskToFinish(task)"
                >
                  <SynTd class="text-center relative">
                    <span
                      v-if="currentTaskId == task?.id"
                      :class="
                        isOpenTaskDrawer ? 'border-current' : 'border-gray-300'
                      "
                      class="absolute left-0 top-0 h-full border"
                    />
                    <SynIcon
                      class="cursor-pointer fill-current text-current"
                      name="checkbox"
                      :is-active="task?.isSelected || false"
                      @click.stop="handleSelectedTaskToFinish(task)"
                    />
                  </SynTd>
                  <SynTd class="">
                    <div class="h-full" style="width: fit-content">
                      <PriorityTask :urgency="task?.urgency" is-hidden-label />
                    </div>
                  </SynTd>
                  <SynTd class="max-w-md">
                    <div class="flex space-y-1 break-words w-full flex-wrap">
                      <div
                        v-vig-tooltip="task?.name"
                        class="
                          cursor-pointer
                          text-overflow-hidden
                          hover:text-current-500
                          overflow-hidden
                          w-full
                          text-xs
                          2xl:text-sm
                        "
                        @click.stop="onOpenTaskDetail(task)"
                      >
                        <span class="font-semibold text-current-800">
                          {{
                            `${
                              task?.subprojectName
                                ? task?.subprojectName + ' - '
                                : ''
                            }`
                          }}
                        </span>
                        <span class="text-overflow-hidden">
                          {{ task?.name }}
                        </span>
                      </div>

                      <GroupById
                        v-if="task?.groupId"
                        :group-id="task?.groupId"
                        class="px-2 text-2xs w-full justify-end"
                        avatar-class="w-4 h-4"
                      />
                    </div>
                  </SynTd>
                  <SynTd v-permission-model>
                    <div class="flex">
                      <DomainById
                        :domain-id="task?.domainId"
                        class="px-2 text-xs 2xl:text-sm"
                      />
                    </div>
                  </SynTd>
                  <SynTd v-permission-model>
                    <div class="flex">
                      <UserById
                        :user-id="task?.creatorId"
                        class="px-2 text-xs"
                        avatar-class="w-6 h-6"
                        is-hidden-name
                      />
                    </div>
                  </SynTd>
                  <SynTd>
                    <SynTag
                      class="flex justify-start w-max"
                      size="small"
                      :class="getConfigScheduleColor(task?.taskSchedule)"
                    >
                      <span
                        v-if="task.assigneeId && getConfigScheduleName(task)"
                      >
                        {{ getConfigScheduleName(task) }}
                      </span>
                      <SynLabelDateTime
                        v-else-if="task.scheduleTime"
                        format="date"
                        :datetime="task.scheduleTime"
                      />
                      <span v-else>
                        {{
                          $t('TASK_DETAIL_LABEL_NO_SCHEDULE') || 'No schedule'
                        }}
                      </span>
                    </SynTag>
                  </SynTd>
                </SynTr>
              </template>
              <template
                v-if="
                  (planToFinishTasks?.length == 0 && keySearchToFinish) ||
                  (planToFinishTasks?.length == 0 && filterDataByKey)
                "
              >
                <SynTr
                  class="h-full border-none hover:bg-current-50"
                  hover="hover:bg-current-50"
                >
                  <SynTd :colspan="6" class="">
                    <SynAnimation
                      :name="'searchIcon'"
                      stype="width: 100px; height: 100px"
                      :label="$t('COMMON_LABEL_ALERT_NO_SEARCH')"
                    />
                  </SynTd>
                </SynTr>
              </template>
            </template>
          </SynTableCustom>

          <TaskDetailDrawerMini
            ref="taskDetailPanelRef"
            @is-open-drawer-mini="(value) => (isOpenTaskDrawer = value)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex w-full items-center justify-between">
        <div class="justify-start">
          <SynButton
            class="w-max"
            name-icon="plus"
            type-outline
            :label="$t('COMMON_LABEL_CREATE_TASK')"
            @click="onCreateTaskGlobal"
          />
        </div>

        <div class="flex flex-col">
          <p class="font-medium text-center">
            <span class="text-gray-500">
              {{
                $t('HEADER_LABEL_WELCOME_START_TIME') || 'Today, you start at'
              }}:
            </span>

            <span class="text-current-500 px-1">
              <SynLabelDateTime format="time" :datetime="startLocalTime" />
            </span>
          </p>
          <!--          <marquee class="w-full" direction="right" scrollamount="12">-->
          <!--            <div class="bg-current w-1/5" style="height: 0.18rem"></div>-->
          <!--          </marquee>-->
          <div class="bar"></div>
        </div>

        <div class="flex items-center justify-end space-x-2">
          <SynButton
            type-outline
            :label="
              checkinStatusList[startWorkingStatus]?.previousText
                ? $t(checkinStatusList[startWorkingStatus]?.previousText)
                : ''
            "
            @click="onHandleGoToPreviousStep"
          />

          <SynButton
            v-if="checkinStatusList[startWorkingStatus]?.confirmText"
            class="w-max"
            :disabled="isDisableButtonSubmit"
            :is-loading="isLoading"
            :label="$t(checkinStatusList[startWorkingStatus]?.confirmText)"
            @click="onHandleNextStep"
          />
        </div>
      </div>
    </template>
  </SynModal>

  <SynModal
    v-if="
      startWorkingStatus == 'SELECT_TASKS_TO_FINISH' &&
      planToFinishTasksDefault.length < 1
    "
    container-class="h-max w-max"
    is-hidden-header
    z-index="z-100"
    :style-body="'py-0 flex relative'"
    has-element-active
    @cancel="$emit('onCancel')"
  >
    <template #body>
      <div class="px-4 py-8">
        <SynAnimation name="startWD3" stype="width: 250px; height: 250px;" />
        <span class="text-xl px-4 text-current-700">
          {{ $t('START_WORKING_TITLE_FINISH_CHOOSE_TASK') }}
        </span>
      </div>
    </template>
    <template #footer>
      <div class="p-4 flex justify-end pt-2 items-center space-x-2">
        <SynButton
          type-text
          color="gray"
          :label="$t('COMMON_LABEL_BACK')"
          @click="onHandleGoToPreviousStep"
        />

        <SynButton
          :label="$t('LABEL_FINISH_CHOOSE_TASK')"
          :is-loading="isLoading"
          @click="onHandleNextStep"
        />
      </div>
    </template>
  </SynModal>
</template>

<style scoped>
.bar {
  width: 100%;
  height: 3px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: all linear;
}

.bar::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -30%;
  width: 20%;
  height: 100%;
  background-color: #34a5a5;
  animation: run 1.8s linear infinite;
  transition: all linear;
}

@keyframes run {
  to {
    left: 100%;
  }
}
</style>
