<script setup lang="ts">
import {
  isOpenSpeedChatDrawer,
  closeSpeedChatDrawer,
} from '@/ui/modules/messaging/speed-chat/speed-chat.const';
import ConversationsList from '@/ui/modules/messaging/conversations-list/ConversationsList.vue';
import { computed, ref, onMounted, onUnmounted } from 'vue';
import myProfileStore from '@/store/auth/my-profile';
import chatStore from '@/store/chat';
import chatExternalStore from '@/store/chat/chat-external';
import chatSupportingStore from '@/store/chat/chat-supporting';
import userStore from '@/store/user';
import permissionStore from '@/store/permission';
import ModalChatPersonalWarning from '@/ui/modules/messaging/modal-chat-personal-warning/ModalChatPersonalWarning.vue';
import { activeAndFocusOnConversationId } from '../chat-widget/chat-widget-public-state';

const _chatStore = chatStore();
const _chatExternalStore = chatExternalStore();
const _myProfileStore = myProfileStore();
const _chatSupportingStore = chatSupportingStore();
const currentOrgId = computed(() => _myProfileStore.myProfile?.organizationId);

const conversationActive = computed(() => {
  const converId = _chatStore.conversationActive?.id;
  const converInfo =
    _chatStore.getConversationInfo(converId) || _chatStore.conversationActive;
  return converInfo
    ? {
        ..._chatStore.conversationActive,
        organizationId: converInfo?.externalChat
          ? _chatExternalStore.externalOrgId
          : converInfo?.supportingChat
          ? _chatSupportingStore.supportingOrgId
          : currentOrgId.value,
      }
    : null;
});
const allUserByIds = computed(() => userStore().allUserByIds);
const conversationById = computed(() => _chatStore.conversationById);
const userConversationIds = computed(() => _chatStore.userConversationIds);
const isPersonalModel = computed<boolean>(
  () => permissionStore().organizationModel === 'PERSONAL'
);
const isLoadingConversations = computed(() => !_chatStore.usersChatInfo);
const isPersonalWarning = ref(false);
const userConversations = computed(() => _chatStore.userConversations);

const onConversationClick = async (conversationId, index) => {
  const discardAndContinue = await _chatStore.handleCheckFormHaveAction();
  if (!discardAndContinue) return;

  _processConversationActive(conversationId);

  _chatStore.setIdConversationActive(conversationId);
  _chatStore.setLastSelectedConversation({
    id: conversationId,
    ...userConversations.value[conversationId],
  });
  if (index !== null) {
    setTimeout(() => {
      const element = document.getElementById(
        `vig-search-box-item-id_message-page_${index}`
      );
      element.scrollIntoView(true);
    });
  }

  // router.push({ name: 'Messages', params: { conversationId } });
};

const isNewChat = ref();
const onNewChatClick = () => {
  if (isPersonalModel.value) {
    isPersonalWarning.value = true;
    return;
  }

  isNewChat.value = true;
};

const _addNewActiveConversation = (newConversation) => {
  activeAndFocusOnConversationId.value = `${newConversation?.id}`;

  _chatStore.activeBubbleChat(newConversation);
  _chatStore.setConversationActive(newConversation);
};

const _processConversationActive = (conversationId) => {
  if (conversationId === conversationActive.value?.id) return;

  if (!conversationId) return _chatStore.setConversationActive(null);

  _addNewActiveConversation({
    id: conversationId,
    ...userConversations.value[conversationId],
  });
};

const onClose = () => {
  closeSpeedChatDrawer();
};

const onClickOutSideTaskDrawer = async (event) => {
  const taskPinnedElm = document.getElementById('speed-chat-drawer');

  if (!isOpenSpeedChatDrawer.value || taskPinnedElm?.contains(event.target))
    return;

  onClose();
};

const appElm = document.getElementById('app') as HTMLElement;
onMounted(() => {
  appElm.addEventListener('mousedown', onClickOutSideTaskDrawer);
});

onUnmounted(() => {
  appElm.removeEventListener('mousedown', onClickOutSideTaskDrawer);
});
</script>

<template>
  <div
    id="speed-chat-drawer"
    class="
      absolute
      top-0
      left-0
      flex
      h-full
      z-30
      transition
      duration-300
      transform
      pl-2
      bg-white
    "
    :class="[
      isOpenSpeedChatDrawer
        ? 'translate-x-0 ease-out'
        : '-translate-x-full ease-in',
    ]"
    :style="{
      maxWidth: '500px',
      minWidth: '400px',
      width: '36vw',
      ...(isOpenSpeedChatDrawer
        ? { boxShadow: 'rgba(47, 48, 48, 0.18) 0px 0px 18px' }
        : {}),
    }"
  >
    <div
      class="w-full flex flex-col rounded relative"
      :style="
        isOpenSpeedChatDrawer
          ? 'box-shadow: 0px 0px 20px 11px rgba(0, 0, 0, 0.1)'
          : ''
      "
    >
      <button
        v-if="isOpenSpeedChatDrawer"
        :title="$t('COMMON_LABEL_CANCEL')"
        class="
          w-6
          h-6
          bg-gray-300
          rounded-full
          cursor-pointer
          hover:bg-gray-200
          absolute
          top-0
          -right-2
          flex-center
          vig-modal-close
          do-not-close-dropdown
          z-50
        "
        @click="onClose"
      >
        <SynIcon name="close" custom-class="w-4 h-4 do-not-close-dropdown" />
      </button>
      <!-- HEADER -->
      <div class="flex-1 min-h-0 overflow-y-hidden w-full rounded">
        <ConversationsList
          is-speed-chat
          :organization-id="currentOrgId"
          :active-conversation="conversationActive"
          :members="allUserByIds"
          :conversation-by-id="conversationById"
          :conversation-ids="userConversationIds"
          :is-personal-org="isPersonalModel"
          :is-loading="isLoadingConversations"
          can-create-new-chat
          can-view-group-chat-task
          @on-conversation-click="onConversationClick"
          @on-new-chat-click="onNewChatClick"
        />
      </div>
    </div>
  </div>
  <ModalChatPersonalWarning
    v-if="isPersonalWarning"
    @on-close="isPersonalWarning = false"
  />
</template>
