<script setup lang="ts">
import { computed, ref } from 'vue';
import attachmentFilesComposable from '@/ui/composables/task/attachments/attachment-files-composables';
import remoteConfigStore from '@/store/remoteConfig';
import AttachmentGroupButton from '@/ui/modules/task/components/AttachmentGroupButton.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import TaskTodoListCreateNew from '@/ui/modules/task/components/check-list/TaskTodoListCreateNew.vue';
import { TaskTemplateEntity } from '@/domain/entities/task/TaskTemplateEntity';
import { ITaskTodo } from '@/application/types/task/task.types';
import AttachmentUploading from '@/ui/modules/task/components/AttachmentUploading.vue';

const props = withDefaults(
  defineProps<{
    files?: any[];
    notes?: any[];
    readonly?: boolean;
    totalAttachment?: number;
    isHiddenChecklist?: boolean;
    originalData?: any;
    localId?: any;
    inputFileId?: string;
    isUploadRightAway?: boolean;
  }>(),
  {
    files: () => [],
    notes: () => [],
    totalAttachment: 0,
  }
);

const emit = defineEmits<{
  (e: 'update:attachments', attachments: { files: any[]; notes: any[] }): void;
  (e: 'update:checklist', checklist: ITaskTodo[] | null): void;
  (e: 'update:uploadingFiles', data: any[] | null): void;
}>();
const _remoteConfigStore = remoteConfigStore();
const ATTACHMENT_INPUT_ID =
  props.inputFileId || `ATTACHMENT_INPUT_ID_${props.localId || Date.now()}`;

const webTaskUploadContentTypes = computed(
  () => _remoteConfigStore.webTaskUploadContentTypes
);

const {
  allAttachments,
  onChooseFiles,
  isOpenRecording,
  onCreateAudioDone,
  onRemoveFile,
  onAddFiles,
  onReset: onResetAttachments,
  // Note
  isOpenNote,
  drawNoteData,
  onOpenCreateNote,
  onSaveNote,
  onRemoveNote,
  onEditNote,
  onPasteFiles,
  onCloseNote,
  reUpload,
  initComposables,
} = attachmentFilesComposable('TASK', {
  defaultFiles: props.files || [],
  defaultNotes: props.notes || [],
  isUploadRightAway: props.isUploadRightAway,
  onChange: ({ uploadingFiles, attachmentFiles, noteList }) => {
    emit('update:attachments', {
      files: [...attachmentFiles],
      notes: [...noteList],
    });
    emit('update:uploadingFiles', [...uploadingFiles]);
  },
});

const initComponent = (_options: {
  files: any[];
  notes: any[];
  taskTodos: any[];
  isUploadRightAway: boolean;
}) => {
  currentTaskTemplate.value =
    new TaskTemplateEntity({
      ...props.originalData,
      files: _options.files || props.originalData?.files,
      notes: _options.notes || props.originalData?.notes,
      taskTodos: _options.taskTodos || props.originalData?.taskTodos,
    }) || ({} as TaskTemplateEntity);

  initComposables(_options);

  if (
    taskTodoListAllRef.value &&
    typeof taskTodoListAllRef.value.initComponent == 'function'
  )
    taskTodoListAllRef.value.initComponent(currentTaskTemplate.value);
};

const currentTaskTemplate = ref<TaskTemplateEntity>(
  new TaskTemplateEntity(props.originalData) || ({} as TaskTemplateEntity)
);

// watch(
//   () => currentTaskTemplate.value?.taskTodos,
//   () => {
//     emit('update:checklist', currentTaskTemplate.value.taskTodos);
//   },
//   { deep: true }
// );

const onRemoveAttachment = (file) => {
  if (file.isNote) return onRemoveNote(file);
  else return onRemoveFile(file);
};

const pastedImage = ref<any>();

const onSaveNoteComment = (nodeData) => {
  pastedImage.value = null;
  onSaveNote(nodeData);
};
const isPreview = ref<boolean>(false);
const currentFileIndex = ref<number>(0);
const onOpenFiles = (file) => {
  if (file.isNote && !props.readonly) {
    onEditNote(file);
    return;
  }

  currentFileIndex.value = allAttachments.value.findIndex(
    (o) => o.localId == file.localId
  );
  isPreview.value = true;
};

const isHasJustCreatedFile = (file) => {
  const createTime = new Date(file?.creationTime).getTime();
  const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const overTime = Date.now() + timeOffset - createTime;
  return overTime < 15 * 60 * 1000;
};

const onSaveUpdateNote = async () => {};

const onChangeDescription = () => {};

const modalMultipleFileViewerRef = ref(null as any);
const onOpenCommentModal = () => {
  modalMultipleFileViewerRef.value?.onOpenEditDescription();
};

const totalAttachmentFiles = computed<number>(() => {
  return allAttachments.value?.length || props.totalAttachment;
});

const taskTodoListAllRef = ref<any>();
const onAddTodoList = () => {
  taskTodoListAllRef.value?.onClickNewTab();
};

const latestInitChecklist = ref<number>(0);
const onSaveTodoList = (multipleTodoList, needToInitAgain?) => {
  currentTaskTemplate.value.taskTodos = multipleTodoList;
  emit('update:checklist', currentTaskTemplate.value.taskTodos);

  if (needToInitAgain) latestInitChecklist.value = Date.now();
};

const onUpdateTaskTodo = () => {
  emit('update:checklist', currentTaskTemplate.value.taskTodos);
};

const onReset = () => {
  const inputImage: HTMLInputElement = document.getElementById(
    ATTACHMENT_INPUT_ID
  ) as HTMLInputElement;

  if (inputImage) inputImage.value = '';

  onResetAttachments();
};

// const filteredUploadingFiles = computed<any[]>(() => {
//   return uploadingFiles.value?.filter(
//     (file) => !allAttachments.value?.some((f) => f?.localId !== file?.localId)
//   );
// });

const reUploadFile = (file) => {
  reUpload(file?.localId);
};

defineExpose({
  onPasteFiles,
  onAddFiles,
  onReset,
  onSaveTodoList,
  initComponent,
});
</script>

<template>
  <section class="px-4 pt-2">
    <div class="w-full flex items-center">
      <span class="text-xs w-28 text-gray-500 flex items-center h-8">
        {{
          `${$t('TASK_DETAIL_LABEL_ATTACHMENT') || 'Attachments'} ${
            totalAttachmentFiles > 0 ? '(' + totalAttachmentFiles + ')' : ''
          }`
        }}
      </span>

      <div
        v-if="!readonly && !totalAttachmentFiles"
        class="flex-center space-x-2"
      >
        <AttachmentGroupButton
          :has-permission="!readonly"
          :input-file-id="ATTACHMENT_INPUT_ID"
          type="LANDSCAPE"
          :is-hidden-checklist="isHiddenChecklist"
          @handle-click-note="onOpenCreateNote"
          @handle-click-audio="isOpenRecording = true"
          @on-add-todo-list="onAddTodoList"
        />
      </div>
    </div>
    <div
      v-if="totalAttachmentFiles > 0"
      class="w-full items-center flex flex-wrap gap-2"
    >
      <div
        v-for="(file, index) in allAttachments"
        :key="index"
        class="relative w-max h-16 hover-to-hidden__parent"
      >
        <template v-if="isUploadRightAway && !file?.path">
          <AttachmentUploading
            :uploading-files="[file]"
            :upload-error="file?.isUploadedFail"
            class="mr-2 mb-2"
            @re-upload="reUploadFile(file)"
          />
        </template>
        <template v-else>
          <UploadPreview
            style="margin: 0"
            :file="file"
            :readonly="readonly"
            :is-note="file.isNote"
            is-hidden-info
            @on-select="onOpenFiles(file)"
            @remove="file.isNote ? onRemoveNote(file) : onRemoveFile(file)"
          />
          <div
            v-if="isHasJustCreatedFile(file)"
            class="
              new-sticker
              absolute
              top-1
              -left-0
              rounded
              w-max
              text-white
              px-1
              bg-current
              -rotate-45
              hover-to-hidden__children
            "
            style="font-size: 0.6rem"
          >
            {{ $t('COMMON_LABEL_NEW') }}
          </div>
        </template>
      </div>
      <!-- <template v-if="filteredUploadingFiles.length > 0">
        <AttachmentUploading
          :uploading-files="filteredUploadingFiles"
          class="mr-2 mb-2"
        />
      </template> -->

      <div v-if="!readonly">
        <div class="translate-y-2">
          <AttachmentGroupButton
            :input-file-id="ATTACHMENT_INPUT_ID"
            :is-hidden-checklist="isHiddenChecklist"
            @handle-click-note="onOpenCreateNote"
            @handle-click-audio="isOpenRecording = true"
            @on-add-todo-list="onAddTodoList"
          />
        </div>
      </div>
    </div>
  </section>
  <section v-if="!isHiddenChecklist" class="px-4 pt-2">
    <TaskTodoListCreateNew
      ref="taskTodoListAllRef"
      :key="latestInitChecklist"
      v-model:task-todos="currentTaskTemplate.taskTodos"
      hidden-footer
      :readonly="readonly"
      @on-save="onSaveTodoList"
      @update:task-todos="onUpdateTaskTodo"
    />
  </section>

  <input
    :id="ATTACHMENT_INPUT_ID"
    type="file"
    multiple
    class="hidden opacity-0"
    :accept="webTaskUploadContentTypes"
    @change="onChooseFiles"
  />

  <RecorderModal
    v-if="isOpenRecording"
    @on-done="onCreateAudioDone"
    @on-cancel="isOpenRecording = false"
  />

  <ModalMultipleFileViewer
    v-if="isPreview && allAttachments?.length > 0"
    ref="modalMultipleFileViewerRef"
    :start-index="currentFileIndex"
    :files="allAttachments"
    :editable="!readonly"
    :current-task="{}"
    @on-close="isPreview = false"
    @on-save-note="onSaveUpdateNote"
    @on-save-description="onChangeDescription"
    @on-remove="onRemoveAttachment"
  >
    <template #title>
      <span> </span>
    </template>
    <template #footer-action>
      <AttachmentGroupButton
        v-if="!readonly"
        class="flex-none bg-opacity-50 mt-2"
        :input-file-id="ATTACHMENT_INPUT_ID"
        @handle-click-note="onOpenCreateNote"
        @handle-click-audio="isOpenRecording = true"
        @handle-click-comment="onOpenCommentModal"
      />
    </template>
  </ModalMultipleFileViewer>
  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    :pasted-image="pastedImage"
    @on-close="onCloseNote"
    @on-save="onSaveNoteComment"
  />
</template>
