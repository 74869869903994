import { AdminType } from '@/ui/common/constants/constant';
import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
import {
    clearLocalStorage,
    StorageConstant,
    getLocalStorage,
    getCurrentUserId,
} from '@/ui/hooks/storageHook';
import alertStore from '@/store/alert';
import { logout } from '@/application/api/auth';
import { unregisterFirebaseFcm } from '../plugins/firebases/firebaseFcm';
import router from '@/ui/router';
import clearAllStore from '@/store/ClearAll';
import { encryptNumberToString } from '../plugins/crypto/custom-crypto';
// import createTaskFormIndexDB from '@/indexedDB/task/create-task-form';
import tictopDatabaseSingleton from '@/indexedDB';
import permissionStore from '@/store/permission';
import { isOpenUserBar } from './useSidebar';
import organizationStore from '@/store/organization';
import myProfileStore from '@/store/auth/my-profile';

export const getWishByStartHour = (hour) => {
    if (hour < 7.5) {
        return 'HEADER_LABEL_WELCOME_WISH_1';
    }
    if (hour < 8) {
        return 'HEADER_LABEL_WELCOME_WISH_2';
    }
    if (hour < 8.5) {
        return 'HEADER_LABEL_WELCOME_WISH';
    }
    if (hour < 9) {
        return 'HEADER_LABEL_WELCOME_WISH_3';
    }
    if (hour < 10) {
        return 'HEADER_LABEL_WELCOME_WISH_4';
    }
    return 'HEADER_LABEL_WELCOME_WISH_5';
};

export const getUserStatus = (workInfo) => {
    if (!workInfo?.online && workInfo?.startWD && !workInfo?.stopWD) {
        return 'absent';
    }
    if (!workInfo?.online) {
        return 'offline';
    }
    return 'online';
};

export enum AlertType {
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning',
}

export enum NotificationPositionType {
    RightBottom = 'right-bottom',
    TopCenter = 'top-center',
    TopCenterRight = 'top-center-right',
    TopRight = 'top-right',
}

export enum NotificationTypeEnum {
    task = 'task',
    chat = 'chat',
    call = 'call',
    calendar = 'calendar',
    dayoff = 'dayoff',
    working_status = 'working_status',
    group = 'group',
    group_new = 'group_new',
    organization_new = 'organization_new',
}

export interface IBaseNotification {
    id?: number;
    type?: AlertType;
    title?: string;
    body?: any;
    duration?: number;
    position?: NotificationPositionType;
    metaData?: any;
    notificationValue?: any;
    notificationType?: NotificationTypeEnum;
    onClick?: (notif, e) => any;
}

export const openNotification = (configs: IBaseNotification) => {
    alertStore().pushDataAlerts({
        type: AlertType.success,
        duration: 5000,
        position: NotificationPositionType.TopCenterRight,
        ...configs,
    });
};

export const closeNotification = (notif: IBaseNotification) => {
    alertStore().removeDataAlerts(notif);
};

export const clearAllSession = () => {};

export const loginAgain = async () => {
    callServeLogout();
    const allMyOrganizationIds = organizationStore().organizationIds;
    allMyOrganizationIds.forEach((organizationId) => {
        const dbName = `tictop/organizations/${organizationId}/main`;
        window.indexedDB.deleteDatabase(dbName);
    });
    await clearAllLocalData();

    router.push({ name: 'Login' });
};
export const clearAllLocalData = async (isSwitchingOrg?) => {
    try {
        // 1. Firebase messaging
        await unregisterFirebaseFcm();

        // 2. Local storage
        clearLocalStorage({
            isSwitchingOrg: !!isSwitchingOrg,
        });

        // 3. Pinia store
        clearAllStore();
        // await createTaskFormIndexDB.getInstance().clearAll();

        // 4. IndexedDB
        tictopDatabaseSingleton.clearInstance();

        // 5. Others
        isOpenUserBar.value = false;
    } catch (error) {
        console.log('🚀 Hyrin ~ clearAllLocalData ~ error', error);
    }
};

const callServeLogout = () => {
    const deviceToken = getLocalStorage(StorageConstant.DEVICE_TOKEN);
    return logout({
        userId: getCurrentUserId(),
        jwtToken: getLocalStorage(StorageConstant.TOKEN),
        refreshToken: getLocalStorage(StorageConstant.REFRESH_TOKEN),
        removingDeviceToken: deviceToken,
    });
};

export const gotoDefaultPageByUserRole = (router) => {
    let routerName = 'MyTasks';
    switch (permissionStore().organizationModel) {
        case 'COMPANY':
        case 'TEAM':
            {
                routerName =
                    hasRolePermission(AdminType.Owner) ||
                    hasRolePermission(AdminType.Admin)
                        ? 'OverviewMonitorPage'
                        : hasRolePermission(AdminType.User)
                        ? 'OverviewMembers'
                        : 'MyTasks';
            }
            break;
        // case 'TEAM':
        //     routerName = hasRolePermission(AdminType.Visitor)
        //         ? 'MyTasks'
        //         : 'OverviewMembers';
        //     break;
        case 'PERSONAL':
            routerName = 'MyTasks';
            break;

        default:
            routerName = 'MyTasks';

            break;
    }
    router.push({
        name: routerName,
    });
};
export const getDomainUrl = () => {
    const location = getLocalStorage(StorageConstant.LOCATION);
    return location === 'EU'
        ? import.meta.env.VITE_DOMAIN_EU
        : import.meta.env.VITE_DOMAIN_DEFAULT;
};
export const getAllDomainUrl = () => {
    return {
        eu: 'app.tictop.eu',
        default: 'tictop.app',
        local: 'localhost:8080',
        alpha: 'tictop-alpha.com',
    };
};

export const taskListRouteByType = (
    type: 'USER' | 'MEMBER' | 'GROUP' | 'DOMAIN',
    id,
    needEncrypt = true,
    filterTab?: string
) => {
    let routeName = 'UserTasks';
    if (
        (type == 'USER' || type == 'MEMBER') &&
        id == myProfileStore().myProfile?.id
    )
        return {
            name: 'MyTasks',
            query: {
                filterTab,
            },
        };

    if (type == 'GROUP') routeName = 'GroupTasks';
    if (type == 'DOMAIN') routeName = 'DomainTasks';

    return {
        name: routeName,
        params: {
            id: needEncrypt ? encryptNumberToString(id) : id,
        },
        query: filterTab
            ? {
                  filterTab,
              }
            : {},
    };
};
export const taskListLinkByType = (
    type: 'USER' | 'GROUP' | 'DOMAIN',
    id,
    needEncrypt = true
) => {
    let linkName = 'tasks/users';
    if (type == 'GROUP') linkName = 'tasks/groups';
    if (type == 'DOMAIN') linkName = 'tasks/domains';

    const ownerId = needEncrypt ? encryptNumberToString(id) : id;

    return `/${linkName}/${ownerId}`;
};
