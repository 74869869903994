<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import myProfileStore from '@/store/auth/my-profile';
import TaskList from '@/ui/modules/task/table/TaskList.vue';
import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import TaskTabs from '@/ui/modules/task/components/TaskTabs.vue';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import { decryptUserId } from '@/ui/plugins/crypto/crypto';
import userStore from '@/store/user';
import { isEqual } from '@/ui/hooks/commonFunction';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';
import $constants from '@/ui/plugins/constants';
import { AdminType } from '@/ui/common/constants/constant';
import taskListStore from '@/store/task/task-list-store';
import { TaskListTab } from '@/domain/enums/taskEnum';
import customDocumentTitle from '@/ui/composables/app/document-title';
import ModalProfileUser from '@/ui/components/user/ModalProfileUser.vue';
import CustomExportModal from '@/ui/modules/task/export/CustomExportModal.vue';
import AvatarByTaskList from '@/ui/modules/task/components/task-list/AvatarByTaskList.vue';
import FilterTabs from '@/ui/modules/task/components/task-list/FilterTabs.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import TaskBoard from '@/ui/modules/task/board/TaskBoard.vue';
import UserTaskStatisticPage from '@/ui/modules/task/statistic/UserTaskStatisticPage.vue';
import ManagerTabsModal from '@/ui/modules/task/tabs-list-task/manager-tabs/ManagerTabsModal.vue';
import tableListTaskComposable from '@/ui/composables/task/table-list-task';
import SelectTaskDoTodayModal from '@/ui/modules/task/table/SelectTaskDoTodayModal.vue';
import ActionsListTask from '@/ui/modules/task/filter/ActionsListTask.vue';
import UpdateMultipleActions from '@/ui/modules/task/table/UpdateMultipleActions.vue';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import {
  EFilterTaskDefault,
  ETaskViewType,
  // LIST_FILTER_TASK_DEFAULT,
  // TabTask,
} from '@/application/constants/task.const';
import listenFirebaseRealtime from '@/ui/composables/app/listen-firebase-realtime';
import OrganizationDynamicModel from '@/ui/plugins/firebases/realtime-database/model/OrganizationDynamicModel';
import { ETaskListModule } from '@/application/types/task/task.types';
// import CustomImportModal from '@/ui/modules/task/import/CustomImportModal.vue';
import TaskCalendarView from '@/ui/pages/tasks/calendar/TaskCalendarView.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import remoteConfigStore from '@/store/remoteConfig';

const route = useRoute();
const _userStore = userStore();
const _taskListStore = taskListStore();
const _taskTabViewStore = taskTabViewStore();
const _remoteConfigStore = remoteConfigStore();

const { changeByRouter } = customDocumentTitle();

const currentUserId = computed(() =>
  parseInt(decryptUserId(route.params?.id) || getCurrentUserId())
);
const viewTask = computed(() => _taskTabViewStore.customizeView);
const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const isLoading = computed(() => _taskListStore.isLoading);
const selectedTab = computed<TaskListTab>(() => _taskListStore.activeTab);
const currentUser = computed(() =>
  _userStore.getDetailByUserId(currentUserId.value || getCurrentUserId())
);
const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const isDeactiveUser = computed(() => currentUser.value?.isDeactive);
const totalTasks = computed(() => _taskListStore.taskAllList.length);
const myProfile = computed(() => myProfileStore().myProfile);
const resourceFixedMember = computed(() => ({ id: currentUserId.value }));
const resourceDefaultCategory = computed(() =>
  myProfile?.value.id !== currentUserId.value
    ? $constants.FILE.CATEGORY.TASK
    : $constants.FILE.CATEGORY.STORAGE
);
const webTaskHiddenCreateFormBottom = computed<boolean>(() => {
  return _remoteConfigStore.webTaskHiddenCreateFormBottom;
});
const isCanCreateTask = computed(() => {
  return (
    webTaskHiddenCreateFormBottom.value &&
    (selectedTab.value == TaskListTab.MyAssign ||
      selectedTab.value == TaskListTab.MyList ||
      selectedTab.value === TaskListTab.Resources) &&
    !isDeactiveUser.value &&
    currentUser.value?.adminType !== AdminType.Visitor
  );
});

const isTasksTab = computed(
  () =>
    selectedTab.value == TaskListTab.MyList ||
    selectedTab.value == TaskListTab.MyAssign
);

const isShowProfile = ref(false);
const isLoadingDetailUser = ref(false);
const taskListRef = ref(null as any);
const isShowCustomExportModal = ref(false);
// const isShowCustomImportModal = ref(false);
const isManagerTabs = ref(false);
const isOpenSelectTaskDoToday = ref(false);
const formCreateTaskRef = ref();

const { initListData, onChangeParentTab, onChangeChildTab } =
  tableListTaskComposable();

const { onListenFirebase, onListenMultiTask } = taskListRealtime();

let { unsubscribeCallbacks } = listenFirebaseRealtime();
const handleRealtimeStatistic = () => {
  const LatestTaskActivityFirebaseModel = new OrganizationDynamicModel(
    `latestTaskActivity`
  );
  unsubscribeCallbacks.value.push(
    LatestTaskActivityFirebaseModel.onChange(() => {
      Promise.all([
        _taskListStore.getTaskSummaryStatistics(
          ETaskListModule.USER,
          currentUserId.value
        ),
        _taskListStore.getAllAssigneeTaskStatisticByUser(currentUserId.value),
      ]);
    })
  );
};

onMounted(() => {
  _taskTabViewStore.setCustomizeViewActive();
  handleRealtimeStatistic();
  const userId = parseInt(decryptUserId(route.params?.id));
  if (!userId) return;
  onListenFirebase('user', userId);
  onListenMultiTask('user', userId);
  changeByRouter(
    route?.name?.toString(),
    currentUser.value?.name?.toString(),
    'Team'
  );
});

const isInitialized = ref<boolean>(false);
const currentSourceType = computed(() => {
  return selectedTab.value == TaskListTab.MyAssign
    ? ETaskListModule.I_ASSIGNED
    : selectedTab.value == TaskListTab.WaitingApprovalTasks
    ? ETaskListModule.WAITING_MY_APPROVAL
    : ETaskListModule.USER;
});
const initData = async (isReload?) => {
  if (!isReload && route.query?.filterTab == 'TODO')
    taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DO_TODAY);

  await initListData(decryptUserId(route.params?.id), currentSourceType.value, {
    isReload,
  });
  isInitialized.value = true;
};

watch(
  () => route.params,
  (newValue, oldValue) => {
    if (!isInitialized.value) return;

    const newUserId = newValue?.id;
    const oldUserId = oldValue?.id;

    if (!newUserId || !oldUserId || route.name !== 'UserTasks') {
      return;
    }

    if (!isEqual(newUserId, oldUserId) && newUserId && currentUserId.value) {
      if (route.query?.filterTab == 'TODO')
        taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DO_TODAY);

      onListenFirebase('user', decryptUserId(newUserId));
      onListenMultiTask('user', decryptUserId(newUserId));

      initListData(decryptUserId(newUserId), currentSourceType.value);

      changeByRouter(
        route?.name?.toString(),
        currentUser.value?.name?.toString(),
        'Team'
      );
    }
  }
);

const onApplyFilter = () => {
  taskListRef.value.initData();
};

const onResetFilterMyTasks = () => {
  if (selectedTab.value == TaskListTab.Profile) {
    isLoadingDetailUser.value = !isLoadingDetailUser.value;
  } else {
    initData(true);
  }
};

const onManagerTab = () => {
  isManagerTabs.value = !isManagerTabs.value;
};

const onOpenProfile = () => {
  isShowProfile.value = true;
};

const onOpenFormCreateTask = () => {
  formCreateTaskRef.value?.onOpenInterface();
};

const onSelectTaskDoToday = () => {
  isOpenSelectTaskDoToday.value = true;
};

initData();
</script>

<template>
  <div
    class="
      h-full
      w-full
      flex flex-col
      overflow-y-hidden overflow-x-hidden
      bg-white
    "
  >
    <!-- HEADER -->
    <div class="flex justify-between items-center">
      <div class="flex items-end justify-between h-full w-full px-2">
        <AvatarByTaskList
          class="py-1"
          :contact-object="currentUser"
          type="MEMBER"
          :name="currentUser?.name"
          :avatar="currentUser?.profilePictureThumbsUrl"
          :total-tasks="totalTasks"
          :id-contact="currentUser?.id || currentUser?.userId"
          :is-loading="isLoading"
          @on-show-profile="isShowProfile = true"
        />

        <div class="flex items-center">
          <TaskTabs
            :active-tab="selectedTab"
            @tab-selected="onChangeParentTab"
          />
        </div>

        <div></div>
      </div>
    </div>

    <!-- MULTIPLE SELECT & FILTERS -->
    <UpdateMultipleActions v-if="selectedTaskIds?.length > 0" />
    <div v-else-if="isTasksTab" class="flex w-full border-t border-gray-100">
      <FilterTabs
        :key="isLoading?.toString()"
        :list-task-owner-id="currentUserId"
        :is-show-tab="isTasksTab"
        :is-hidden-custom-tab="selectedTab !== TaskListTab.MyList"
        :view-mode-list="[
          ETaskViewType.LIST,
          ETaskViewType.BOARD,
          ETaskViewType.CALENDAR,
        ]"
        :source-type="currentSourceType"
        @on-change-tab="(tab) => onChangeChildTab(tab)"
      >
        <template #actions>
          <div class="flex items-center">
            <ActionsListTask
              :list-task-owner-id="currentUserId"
              :user-id="currentUserId"
              :current-children-tab="childrenTabActive"
              :current-tab="selectedTab"
              :is-hidden-search-filter="!isTasksTab"
              :is-hidden-filter="true"
              is-show-reload
              :action-hidden="
                !isTasksTab ? ['information', 'export_task'] : ['information']
              "
              show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_USER"
              @on-show-profile="onOpenProfile"
              @on-export-task="isShowCustomExportModal = true"
              @on-reset-filter="onResetFilterMyTasks"
              @on-apply-filter="onApplyFilter"
              @on-manager-tab="onManagerTab"
              @on-reload="onResetFilterMyTasks"
            />
          </div>
        </template>
        <template #filter>
          <FilterTaskButton
            :list-task-owner-id="currentUserId"
            :user-id="
              selectedTab == TaskListTab.MyAssign ? undefined : currentUserId
            "
            :assignor-id="
              selectedTab == TaskListTab.MyAssign ? currentUserId : undefined
            "
            :current-children-tab="childrenTabActive"
            :current-tab="selectedTab"
            show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_USER"
            @on-apply-filter="onApplyFilter"
            @on-reset-filter="onResetFilterMyTasks"
          />
        </template>
      </FilterTabs>
    </div>
    <div v-else class="py-1"></div>

    <div class="relative flex-1 min-h-0">
      <div class="relative h-full">
        <div v-if="isLoading" class="flex flex-col h-full">
          <SynAnimation name="my-tasks" />
        </div>
        <template v-else>
          <div v-if="selectedTab === TaskListTab.Resources" class="h-full">
            <GedPage
              :fixed-member="resourceFixedMember"
              :default-category="resourceDefaultCategory"
            />
          </div>

          <div v-if="selectedTab === TaskListTab.Statistic" class="h-full">
            <UserTaskStatisticPage :user-id="currentUserId" />
          </div>
          <template v-else>
            <template v-if="viewTask === ETaskViewType.BOARD">
              <TaskBoard
                location="USER_TASKS"
                class="px-1"
                :type="selectedTab === TaskListTab.MyAssign ? 'IA' : 'USER'"
                :is-show-all="
                  selectedTab === TaskListTab.MyList &&
                  currentUserId == getCurrentUserId()
                "
                :current-contact-id="currentUserId"
              />
            </template>
            <template v-else-if="viewTask === ETaskViewType.CALENDAR">
              <div
                v-permission-screen="{
                  screenCode: ALL_SCREENS.USER.TASK.CALENDAR,
                  isPage: true,
                }"
                class="w-full h-full"
              >
                <TaskCalendarView
                  :owner-id="currentUserId"
                  :source-type="ETaskListModule.USER"
                />
              </div>
            </template>
            <div
              v-else
              :style="
                isCanCreateTask
                  ? selectedTab === TaskListTab.MyAssign
                    ? 'height: calc(100vh - 11.5rem)'
                    : ' height: calc(100vh - 14rem)'
                  : 'height: 100%'
              "
              class="bg-white h-full overflow-hidden"
            >
              <template v-if="viewTask === ETaskViewType.LIST">
                <TaskList
                  ref="taskListRef"
                  location="USER_TASKS"
                  :type="
                    selectedTab === TaskListTab.MyList ? 'member' : 'my_assign'
                  "
                  :createable-task="currentUserId === getCurrentUserId()"
                  :is-filter-in-table="true"
                  @reload="onResetFilterMyTasks"
                  @on-open-form-create-task="onOpenFormCreateTask"
                  @on-select-task-do-today="onSelectTaskDoToday"
                />
              </template>
            </div>
          </template>
        </template>
      </div>
      <TaskCreateLocal
        v-if="isCanCreateTask"
        ref="formCreateTaskRef"
        :is-show-apart="selectedTab !== TaskListTab.Resources"
        :assignee-id="currentUserId"
      />
    </div>
  </div>

  <SelectTaskDoTodayModal
    v-if="isOpenSelectTaskDoToday"
    @on-cancel="isOpenSelectTaskDoToday = false"
  />

  <ModalProfileUser
    v-if="isShowProfile"
    :profile-id="currentUser?.userId || currentUser?.id"
    @cancel="isShowProfile = false"
  />

  <CustomExportModal
    v-if="isShowCustomExportModal"
    module-name="User"
    :user-id="currentUserId"
    :owner-name="currentUser.name"
    :current-tab="selectedTab"
    @on-close="isShowCustomExportModal = false"
    @on-reset-filter="onResetFilterMyTasks"
  />

  <!--  <CustomImportModal-->
  <!--    v-if="isShowCustomImportModal"-->
  <!--    @on-close="isShowCustomImportModal = false"-->
  <!--  />-->

  <ManagerTabsModal v-if="isManagerTabs" @on-cancel="isManagerTabs = false" />
</template>
