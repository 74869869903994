<script setup lang="ts">
import { computed, ref } from 'vue';
import { ATTACHMENT_STATUS_BY_KEY } from '@/application/constants/task.const';

const props = defineProps<{
  currentStatus?: string;
}>();

const emit = defineEmits(['onChangeStatus', 'toggleChangeStatus']);

const currentPin = computed<any>(() => {
  if (!props.currentStatus) return null;
  return ATTACHMENT_STATUS_BY_KEY[props.currentStatus];
});
const isOpenDropdown = ref<boolean>(false);

const onChangeStatus = (pin) => {
  emit('onChangeStatus', pin?.key);
};

const togglePin = () => {
  emit('toggleChangeStatus');
};
</script>
<template>
  <div class="flex-center space-x-1 pin-task-current">
    <div
      class="
        flex-center
        rounded-full
        w-6
        h-6
        bg-gray-100
        cursor-pointer
        relative
      "
      :class="isOpenDropdown ? 'bg-gray-100' : ''"
      @click="togglePin"
    >
      <SynIcon
        v-if="currentPin"
        name="tick"
        is-active
        :custom-class="`h-4 w-4 fill-${currentPin?.iconColor}-500`"
      />
      <SynIcon v-else name="tick" custom-class="w-3 h-3" />
    </div>
    <div class="w-4 h-8 flex-center flex-col pin-task-current__hover-to-show">
      <VigDropdown
        @on-dropdown-open="isOpenDropdown = true"
        @on-dropdown-close="isOpenDropdown = false"
      >
        <template #dropdown-toggle>
          <div class="w-4 h-8 cursor-pointer rounded flex items-center">
            <SynIcon
              name="chevron-right"
              :custom-class="
                isOpenDropdown
                  ? 'w-2 h-2 fill-gray-500 -rotate-90'
                  : 'w-2 h-2 fill-gray-500 rotate-90'
              "
            />
          </div>
        </template>
        <template #dropdown-menu>
          <div class="w-max flex-center space-x-1 p-1">
            <template
              v-for="pin in Object.values(ATTACHMENT_STATUS_BY_KEY)?.filter(
                (pin) => pin.key && pin.key !== currentPin?.key
              )"
              :key="pin?.key"
            >
              <div
                class="
                  flex-center
                  rounded-md
                  w-8
                  h-8
                  p-2
                  hover:bg-gray-100
                  cursor-pointer
                  dropdown-item
                "
                @click="onChangeStatus(pin)"
              >
                <SynIcon
                  name="tick"
                  is-active
                  :custom-class="`h-5 w-5 fill-${pin?.iconColor}-500`"
                />
              </div>
            </template>
          </div>
        </template>
      </VigDropdown>
    </div>
  </div>
</template>
