<script setup lang="ts">
import ShapeMediaEntity from '@/ui/components/drawer/entities/ShapeMediaEntity';
import { computed, inject, ref } from 'vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
  TDesktopDrawerShape,
} from '@/application/constants/system/provider-ids.const';
import ModalMultipleFileViewer from '@/ui/modules/ged/modal-file-viewer/ModalMultipleFileViewer.vue';
import UploadPreview from '@/ui/components/file-viewer/UploadPreview.vue';
import ShapeActions from '@/ui/components/drawer/components/ShapeActions.vue';
import { ShapeType } from '../schema';

const props = defineProps<{
  shapeEntity: ShapeMediaEntity;
}>();

const desktopDrawerShapeInjected = inject<TDesktopDrawerShape>(
  PROVIDER_IDS.DESKTOP_DRAWER_SHAPE
);
const desktopDrawerInjected = inject<TDesktopDrawer>(
  PROVIDER_IDS.DESKTOP_DRAWER
);

const isImage = computed<boolean>(() => {
  return props.shapeEntity?.type == ShapeType.MEDIA_IMAGE;
});
const isAudio = computed<boolean>(() => {
  return props.shapeEntity?.type == ShapeType.MEDIA_AUDIO;
});

const onMouseDown = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event);
};

const isPreview = ref<boolean>(false);
const onClickDetail = () => {
  isPreview.value = true;
};

const onClickRemove = () => {
  desktopDrawerInjected?.desktopDrawerInstance.value?.removeShapeById(
    props.shapeEntity?.id,
    () => {
      desktopDrawerInjected?.addMoreHistory();
    }
  );
};

const onSelectResize = (event) => {
  desktopDrawerShapeInjected?.onMouseDown(event, 'RESIZE');
};

const fileUrl = computed(() => {
  if (props.shapeEntity?.sourceData instanceof File)
    return URL.createObjectURL(props.shapeEntity?.sourceData);
  if (props.shapeEntity?.sourceData?.fileBlob instanceof Blob) {
    return URL.createObjectURL(props.shapeEntity?.sourceData?.fileBlob);
  }
  if (props.shapeEntity?.sourceData?.blob instanceof Blob) {
    return URL.createObjectURL(props.shapeEntity?.sourceData?.blob);
  }
  if (props.shapeEntity?.sourceData?.base64)
    return props.shapeEntity?.sourceData?.base64;
  if (props.shapeEntity?.sourceData?.fileUrl)
    return props.shapeEntity?.sourceData?.fileUrl;
  if (props.shapeEntity?.sourceData?.url_full)
    return props.shapeEntity?.sourceData?.url_full;
  if (props.shapeEntity?.sourceData?.pathUrl)
    return props.shapeEntity?.sourceData?.pathUrl;
  if (props.shapeEntity?.sourceData?.Path)
    return props.shapeEntity?.sourceData?.Path;

  return (
    props.shapeEntity?.sourceData?.imagePath ||
    props.shapeEntity?.sourceData?.src
  );
});
</script>
<template>
  <template v-if="isAudio">
    <div
      class="flex-center relative rounded-md"
      :style="{
        width: `${shapeEntity?.width}px`,
        height: `${shapeEntity?.height}px`,
      }"
    >
      <div
        class="bg-white flex-center w-full h-full rounded-md"
        style="pointer-events: all"
      >
        <VigAudio
          :path="fileUrl"
          type="default"
          size="auto"
          :style="{
            width: `${shapeEntity?.width}px`,
            height: `${shapeEntity?.height}px`,
            margin: 0,
          }"
          is-hidden-close
        />
      </div>
      <ShapeActions
        class="absolute top-0 right-0"
        :source-data="shapeEntity?.sourceData"
        :action-list="['ATTACH_TO_TASK', 'DETAIL', 'REMOVE']"
        @on-click-detail="onClickDetail"
        @on-click-remove="onClickRemove"
      />
    </div>
  </template>
  <template v-else>
    <div
      class="flex-center rounded-md"
      :style="{
        width: `${shapeEntity?.width}px`,
        height: `${shapeEntity?.height}px`,
      }"
    >
      <div
        class="bg-white flex-center w-full h-full rounded-md"
        style="pointer-events: all"
      >
        <UploadPreview
          custom-class="m-0 my-0 mr-0"
          :file="shapeEntity?.sourceData"
          :style="{
            width: `${shapeEntity?.width}px`,
            height: `${shapeEntity?.height}px`,
            margin: 0,
          }"
          image-size="original"
          size="auto"
          is-hidden-close
        />
      </div>
    </div>

    <div
      class="w-full h-full absolute inset-0 rounded-md drawer-shape-mask"
      @mousedown.left="onMouseDown"
    >
      <ShapeActions
        class="absolute top-0 right-0"
        :source-data="shapeEntity?.sourceData"
        :action-list="['ATTACH_TO_TASK', 'DETAIL', 'REMOVE']"
        @on-click-detail="onClickDetail"
        @on-click-remove="onClickRemove"
      />
      <span
        v-if="isImage"
        class="
          absolute
          bottom-0.5
          right-0.5
          pointer-events-auto
          h-4
          w-4
          border-r-4 border-b-4
        "
        style="cursor: nw-resize"
        @mousedown.stop.prevent="onSelectResize"
      >
      </span>
    </div>
  </template>

  <ModalMultipleFileViewer
    v-if="isPreview"
    :start-index="0"
    :files="[shapeEntity?.sourceData]"
    :has-task-actions="false"
    :has-image-actions="false"
    disabled-download
    @on-close="isPreview = false"
  >
    <template #title>
      <span></span>
    </template>
  </ModalMultipleFileViewer>
</template>
