<script setup lang="ts">
import { provide, ref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/ui/router';
import { v4 as uuidv4 } from 'uuid';
import { useMouseInElement } from '@vueuse/core';
// import DesktopDrawerShapeTest from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeTest.vue';
import DesktopDrawerShapeTask from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeTask.vue';
import PROVIDER_IDS, {
  TDesktopDrawer,
} from '@/application/constants/system/provider-ids.const';
import DesktopDrawerShape from '@/ui/components/drawer/custom-shapes/DesktopDrawerShape.vue';
import DesktopDrawerShapeArrow from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeArrow.vue';
import DesktopDrawerShapeText from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeText.vue';
import DesktopDrawerOverlays from '@/ui/components/drawer/DesktopDrawerOverlays.vue';
import DesktopDrawerEntity from '@/ui/components/drawer/entities/DesktopDrawerEntity';
import ShapeGroupEntity from './entities/ShapeGroupEntity';
import { EWhiteboardTool, IDesktopDrawerShape, ShapeType } from './schema';
// import TaskCardList from '@/ui/modules/task/board/task-card/TaskCardList.vue';
import SearchTasks from '@/ui/modules/task/components/search/SearchTasks.vue';
import DesktopDrawerShapeUser from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeUser.vue';
import DesktopDrawerShapeDrawNote from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeDrawNote.vue';
import CreateNoteModal from '@/ui/modules/task/detail/CreateNoteModal.vue';
// import useDrawNote from '@/ui/modules/task/general/note-logic';
import DesktopDrawerShapeTodoList from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeTodoList.vue';
import { ITaskTodoItemStatus } from '@/application/types/task/task.types';
import DesktopIndexedDBSingleton from '@/indexedDB/desktop/DesktopIndexedDBSingleton';
import SearchAndSelectUser from '@/ui/modules/task/components/search/SearchAndSelectUser.vue';
import RecorderModal from '@/ui/common/atoms/SynRecorder/RecorderModal.vue';
// import useRecordAudio from '@/ui/modules/task/general/audio-logic';
import DesktopDrawerShapeMedia from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeMedia.vue';
import remoteConfigStore from '@/store/remoteConfig';
// import useAttachment from '@/ui/modules/task/general/attachment-logic';
import AtomAttachLinkModal from '@/ui/common/atoms/atom-attach-link/AtomAttachLinkModal.vue';
import DesktopDrawerShapeLink from '@/ui/components/drawer/custom-shapes/DesktopDrawerShapeLink.vue';
import WhiteboardService from '@/application/services/WhiteboardService';
import useAttachFilesAndNotes from '@/ui/common/composables/attachments/use-attach-files-and-notes';
import { IAttachmentFileDetail } from '@/application/services/attachment/AttachmentClass';
import { IDrawNoteDetail } from '@/application/services/attachment/NoteClass';
import {
  convertClassToJSON,
  onLoadImage,
  getObjectDiff,
} from '@/ui/hooks/commonFunction';
import whiteboardStore from '@/store/whiteboard';
import { IUserWhiteboard } from '@/application/types/whiteboard';
import WhiteboardCreateModal from '@/ui/modules/whiteboard/create/WhiteboardCreateModal.vue';
import WhiteboardEditTitleModal from '@/ui/modules/whiteboard/create/WhiteboardEditTitleModal.vue';
import RecycleShapeListModal from '@/ui/components/drawer/components/RecycleShapeListModal.vue';
import ShapeArrowEntity from '@/ui/components/drawer/entities/ShapeArrowEntity';
import Minimap from '@/ui/components/drawer/components/Minimap.vue';
import customDocumentTitle from '@/ui/composables/app/document-title';
import { translate } from '@/ui/plugins/i18n/myi18n';
import ZoomDropdown from '@/ui/components/drawer/tools/ZoomDropdown.vue';
import {
  isOpenTaskDrawer,
  isShowTaskDetailGlobal,
} from '@/ui/modules/task/task-global-state';
import { activeAndFocusOnConversationId } from '@/ui/modules/messaging/chat-widget/chat-widget-public-state';
import { getImageSize } from './utils';

const IMAGE_MIN_SIZE = 64;
const attachFileInputId = 'desktop-drawer-attachment-input';
const route = useRoute();
const _remoteConfigStore = remoteConfigStore();
const _whiteboardStore = whiteboardStore();

const allWhiteboards = computed<IUserWhiteboard[]>(() => {
  return _whiteboardStore.allWhiteboards;
});

const whiteboardId = computed(() => route.params?.id);

watch(
  () => whiteboardId.value,
  (newId) => {
    if (newId) handleGetCacheData();
  }
);
const desktopDrawerInstance = ref<DesktopDrawerEntity>(
  new DesktopDrawerEntity({})
);

const shapeList = computed<IDesktopDrawerShape[]>(
  () => desktopDrawerInstance.value?.shapeList
);

const shapeGroups = computed<ShapeGroupEntity[]>(
  () => desktopDrawerInstance.value?.shapeGroups
);
const draftShapeGroup = computed<ShapeGroupEntity>(
  () => desktopDrawerInstance.value?.draftShapeGroup
);
const selectedShapeGroup = computed<ShapeGroupEntity | undefined>(
  () => desktopDrawerInstance.value?.selectedShapeGroup
);

const selectedShapeIds = computed<string[]>(() => {
  return desktopDrawerInstance.value?.selectedShapeIds || [];
});

const handleGetCacheData = async () => {
  if (desktopDrawerInstance.value?.id) {
    desktopDrawerInstance.value.onSaveChangeRealtime(true);
  }
  if (!whiteboardId.value) {
    return;
  }
  const cachedData: any =
    await DesktopIndexedDBSingleton.getInstance().getDetailByKeyPath(
      whiteboardId.value
    );
  if (cachedData)
    desktopDrawerInstance.value = new DesktopDrawerEntity({
      ...cachedData,
      id: cachedData?.desktopId || cachedData?.id,
    });

  const res = await WhiteboardService.getWhiteboardDetail(whiteboardId.value);
  const dataFromServer = res?.result;

  const isCacheOutOfDate =
    !cachedData ||
    cachedData?.lastModificationTime < dataFromServer?.lastModificationTime;

  if (isCacheOutOfDate && dataFromServer?.id) {
    desktopDrawerInstance.value = new DesktopDrawerEntity(dataFromServer);

    const DesktopIndexedDBSingletonInstance =
      DesktopIndexedDBSingleton.getInstance();
    const dataPayload = DesktopIndexedDBSingletonInstance.toRawData(
      whiteboardId.value,
      desktopDrawerInstance.value
    );

    DesktopIndexedDBSingletonInstance.put(dataPayload);
  }

  if (!desktopDrawerInstance.value || !desktopDrawerInstance.value?.id) {
    // router.push({
    //   name: 'WhiteboardList',
    // });
    return;
  }

  _whiteboardStore.setCurrentWhiteboardId(whiteboardId.value);

  const attachedTasks = _whiteboardStore.attachedTasks?.filter(
    (o) => o?.whiteboardId == desktopDrawerInstance.value?.id
  );
  if (attachedTasks?.length > 0) {
    attachedTasks.forEach((o) => {
      onConfirmSelectTask(o?.task);
    });

    _whiteboardStore.updateAttachedTasks(
      _whiteboardStore.attachedTasks?.filter(
        (o) => o?.whiteboardId !== desktopDrawerInstance.value?.id
      )
    );
  }

  if (!desktopDrawerInstance.value?.title) {
    desktopDrawerInstance.value.title = dataFromServer?.title;

    desktopDrawerInstance.value.onSaveChangeLocal();
  }
  if (!desktopDrawerInstance.value?.title) {
    desktopDrawerInstance.value.title = dataFromServer?.title;

    desktopDrawerInstance.value.onSaveChangeLocal();
  }
  updatedHistories.value = [];
  currentVersionIndex.value = 0;
  addMoreHistory();

  checkAndChangeViewMode();
  updateMinimap();

  handleChangeDocumentTitle();
};

const attachedTasks = computed<any>(() => {
  return _whiteboardStore.attachedTasks?.filter(
    (o) => o?.whiteboardId == desktopDrawerInstance.value?.id
  );
});

watch(
  () => attachedTasks.value,
  () => {
    if (attachedTasks.value?.length > 0) {
      attachedTasks.value.forEach((o) => {
        onConfirmSelectTask(o?.task);
      });

      _whiteboardStore.updateAttachedTasks(
        _whiteboardStore.attachedTasks?.filter(
          (o) => o?.whiteboardId !== desktopDrawerInstance.value?.id
        )
      );
    }
  }
);

const pastedImage = ref<any>();
const onPasteFiles = async (event) => {
  if (
    isOpenTaskDrawer.value ||
    isShowTaskDetailGlobal.value ||
    activeAndFocusOnConversationId.value
  )
    return;

  const files = (event.clipboardData || event.originalEvent.clipboardData)
    .files;

  if (!files || files?.length == 0) return;

  onChooseFiles({
    target: {
      files,
    },
  });
};

onMounted(() => {
  _whiteboardStore.getAllRecycleShapeByUser();

  handleGetCacheData();
  window.addEventListener('paste', onPasteFiles);
  window.addEventListener('mouseup', onMouseUp);
  window.addEventListener('mousedown', onMouseDown);
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('wheel', onMouseWheelToZoom, {
    passive: false,
  });
  window.addEventListener('resize', updateMinimap);
});

onUnmounted(() => {
  window.removeEventListener('paste', onPasteFiles);
  window.removeEventListener('mouseup', onMouseUp);
  window.removeEventListener('mousedown', onMouseDown);
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('wheel', onMouseWheelToZoom);
  window.removeEventListener('resize', updateMinimap);

  desktopDrawerInstance.value.lastAccessTime = Date.now();

  onSaveChangeRealtime();
});

const keepingShape = ref<{
  shapeId: string;
  groupId?: string;
  offsetX: number;
  offsetY: number;
  type?: 'RESIZE' | 'MOVE';
}>({
  shapeId: '',
  offsetX: 0,
  offsetY: 0,
  type: 'MOVE',
});
function updateKeepingShape(
  targetedShape: IDesktopDrawerShape | null,
  type?: 'RESIZE' | 'MOVE'
) {
  if (
    !targetedShape?.id ||
    !shapeList.value?.some((shape) => shape?.id == targetedShape?.id)
  ) {
    keepingShape.value = {
      shapeId: '',
      offsetX: 0,
      offsetY: 0,
      type,
    };

    isShowMinimap.value = false;
    return;
  }

  const targetShape = shapeList.value?.find(
    (shape) => shape?.id == targetedShape?.id
  );

  keepingShape.value = {
    shapeId: targetedShape?.id,
    groupId: targetedShape?.groupId,
    offsetX: mouseInContainerX.value - (targetShape?.translateX || 0),
    offsetY: mouseInContainerY.value - (targetShape?.translateY || 0),
    type,
  };
  isShowMinimap.value = true;
}

const selectedResizeShape = ref<IDesktopDrawerShape | undefined>(undefined);
function onSelectResize(targetedShape: IDesktopDrawerShape | undefined) {
  selectedResizeShape.value = targetedShape;
}

const desktopDrawerContainerRef = ref(null);
const {
  x: mouseInContainerX,
  y: mouseInContainerY,
  elementX: containerElementX,
  elementY: containerElementY,
} = useMouseInElement(desktopDrawerContainerRef);

const changeShapePosition = (moveDistanceX, moveDistanceY) => {
  const keepingShapeId = keepingShape.value?.shapeId;
  if (
    !keepingShapeId ||
    !shapeList.value?.some((shape) => shape?.id == keepingShapeId)
  )
    return;

  let affectedShapes: IDesktopDrawerShape[] = [];

  const currentShape = shapeList.value?.find(
    (shape) => shape?.id == keepingShapeId
  );

  if (currentShape) affectedShapes.push(currentShape);
  // handle move group

  if (draftShapeGroup.value?.shapes?.length > 1) {
    draftShapeGroup.value?.shapes.forEach((shape) => {
      const _currentShape = shapeList.value?.find(
        (_shape) => _shape?.id == shape?.id
      );

      if (currentShape?.id == shape?.id || !_currentShape) return;
      affectedShapes.push(_currentShape);
    });
  } else {
    const currentGroup = shapeGroups.value?.find(
      (g) => g.id == keepingShape.value?.groupId
    );
    if (currentGroup) {
      currentGroup.shapes.forEach((shape) => {
        const _currentShape = shapeList.value?.find(
          (_shape) => _shape?.id == shape?.id
        );

        if (currentShape?.id == shape?.id || !_currentShape) return;
        affectedShapes.push(_currentShape);
      });
    }
  }
  affectedShapes.forEach((shape) => {
    const newTranslate = desktopDrawerInstance.value.getTranslateShape(
      shape.translateX + moveDistanceX,
      shape.translateY + moveDistanceY
    );
    shape.changePosition(newTranslate?.translateX, newTranslate?.translateY);
  });
};

const onResizeShape = (moveDistanceX, moveDistanceY) => {
  const keepingShapeId = keepingShape.value?.shapeId;
  if (
    !keepingShapeId ||
    !shapeList.value?.some((shape) => shape?.id == keepingShapeId)
  )
    return;

  const currentShape = shapeList.value?.find(
    (shape) => shape?.id == keepingShapeId
  );

  if (
    !currentShape ||
    (moveDistanceX <= 0 && currentShape?.width <= IMAGE_MIN_SIZE) ||
    (moveDistanceY <= 0 && currentShape?.height <= IMAGE_MIN_SIZE)
  )
    return;

  switch (currentShape?.type) {
    case ShapeType.TODO_LIST:
      {
        const newHeight = currentShape.height + moveDistanceY;

        currentShape.updateSize(currentShape.width, newHeight);
      }
      break;
    case ShapeType.DRAW_NOTE:
    case ShapeType.MEDIA_IMAGE:
      {
        const changedSize =
          Math.abs(moveDistanceX) > Math.abs(moveDistanceY)
            ? moveDistanceX
            : moveDistanceY;

        const newWidth = currentShape.width + changedSize;
        const newHeight = (newWidth * currentShape.height) / currentShape.width;

        if (
          (moveDistanceX <= 0 && newWidth <= IMAGE_MIN_SIZE) ||
          (moveDistanceY <= 0 && newHeight <= IMAGE_MIN_SIZE)
        )
          return;

        currentShape.updateSize(newWidth, newHeight);
      }
      break;

    default:
      {
        const newWidth = currentShape.width + moveDistanceX;
        const newHeight = currentShape.height + moveDistanceY;

        currentShape.updateSize(newWidth, newHeight);
      }
      break;
  }
};

const whiteboardMinimapRef = ref<any>(null);

const updateMinimap = () => {
  if (
    whiteboardMinimapRef.value &&
    typeof whiteboardMinimapRef.value.initData == 'function'
  )
    whiteboardMinimapRef.value.initData();
};
const checkAndChangeViewMode = () => {
  if (
    !desktopDrawerInstance.value?.lastAccessTime ||
    Date.now() - desktopDrawerInstance.value?.lastAccessTime > 30 * 1000 * 60
  ) {
    // currentTool.value = EWhiteboardTool.TOOL_DRAG;
    let minPosition = {
      x: 0,
      y: 0,
    };
    desktopDrawerInstance.value?.shapeList
      ?.filter((x) => x)
      .forEach((shape, index) => {
        const _x = shape?.translateX;
        const _y = shape?.translateY;
        if (_x < minPosition.x || index == 0) minPosition.x = _x;
        if (_y < minPosition.y || index == 0) minPosition.y = _y;
      });
    desktopDrawerInstance.value.changePosition(
      -(minPosition.x - 15),
      -(minPosition.y - 15),
      updateMinimap
    );
  }
};
watch(
  [() => mouseInContainerX.value, () => mouseInContainerY.value],
  ([newX, newY], [prevX, prevY]) => {
    if (currentTool.value == EWhiteboardTool.TOOL_DRAG) {
      goToDefaultModeTimeout();
    }
    const moveDistanceX = (newX - prevX) / desktopDrawerInstance.value.scale;
    const moveDistanceY = (newY - prevY) / desktopDrawerInstance.value.scale;

    if (currentTool.value == EWhiteboardTool.ARROW && drawArrowData.value) {
      if (currentUpdateArrowSide.value == 'end') {
        const newTranslateX =
          drawArrowData.value.sourceData?.end?.point?.x + moveDistanceX;
        const newTranslateY =
          drawArrowData.value.sourceData?.end?.point?.y + moveDistanceY;

        drawArrowData.value.updateEndPosition(newTranslateX, newTranslateY);
      } else {
        const newTranslateX =
          drawArrowData.value.sourceData?.start?.point?.x + moveDistanceX;
        const newTranslateY =
          drawArrowData.value.sourceData?.start?.point?.y + moveDistanceY;

        drawArrowData.value.updateStartPosition(newTranslateX, newTranslateY);
      }

      updateMinimap();
      onSaveChangeRealtime();
    } else {
      if (isDragging.value) {
        const newTranslateX =
          desktopDrawerInstance.value.translateX + moveDistanceX;
        const newTranslateY =
          desktopDrawerInstance.value.translateY + moveDistanceY;
        desktopDrawerInstance.value.changePosition(
          newTranslateX,
          newTranslateY,
          updateMinimap
        );
      }

      if (keepingShape.value?.shapeId) {
        if (keepingShape.value?.type == 'MOVE') {
          changeShapePosition(moveDistanceX, moveDistanceY);
        }

        if (keepingShape.value?.type == 'RESIZE') {
          onResizeShape(moveDistanceX, moveDistanceY);
        }

        updateMinimap();
        onSaveChangeRealtime();
      }
    }
  }
);
watch(
  [() => containerElementX.value, () => containerElementY.value],
  ([x, y]) => {
    const newX = x - desktopDrawerInstance.value?.translateX;
    const newY = y - desktopDrawerInstance.value?.translateY;
    desktopDrawerInstance.value.updateBrushByNewPosition(newX, newY);
  }
);

const onMouseUp = (event) => {
  isShowMinimap.value = false;
  switch (event.button) {
    case 0:
      {
        // Left button clicked

        if (currentTool.value == EWhiteboardTool.TOOL_DRAG) {
          if (isDragging.value) addMoreHistory();

          isDragging.value = false;
          return;
        }
        onMouseUpOnBackground();
      }
      break;
    case 1:
      {
        // Middle button clicked

        if (isDragging.value) addMoreHistory();

        currentTool.value = EWhiteboardTool.SELECT;
        isDragging.value = false;
        addMoreHistory();
      }
      break;

    default:
      break;
  }
  if (
    keepingShape.value?.shapeId &&
    shapeList.value?.some((shape) => shape?.id == keepingShape.value?.shapeId)
  )
    addMoreHistory();

  updateKeepingShape(null);
};
const onMouseDown = (event) => {
  switch (event.button) {
    case 0:
      {
        // Left button clicked
        if (currentTool.value == EWhiteboardTool.TOOL_DRAG) {
          isDragging.value = true;

          return;
        }
        if (
          event?.target !== document.getElementById('desktop-drawer-background')
        )
          return;

        onMouseDownOnBackground(event);
      }
      break;
    case 1:
      {
        // Middle button clicked
        currentTool.value = EWhiteboardTool.TOOL_DRAG;
        isDragging.value = true;
        isShowMinimap.value = true;
      }
      break;

    default:
      break;
  }
};
const onMouseMove = () => {
  onMouseMoveOnBackground();
};

const desktopDrawerLayerRef = ref<any>(null);
const { elementX: drawerLayerElementX, elementY: drawerLayerElementY } =
  useMouseInElement(desktopDrawerContainerRef);

const onMouseWheelToZoom = (e) => {
  if (!e?.ctrlKey) return;

  e.preventDefault();
  e.stopPropagation();

  const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
  if (delta < 0 && desktopDrawerInstance.value.scale <= 0.25) return;

  const clientX = drawerLayerElementX.value;
  const clientY = drawerLayerElementY.value;
  const currentPaddingX = clientX / desktopDrawerInstance.value.scale - clientX;
  const currentPaddingY = clientY / desktopDrawerInstance.value.scale - clientY;
  if (delta > 0) desktopDrawerInstance.value.scale *= 1.2;
  else desktopDrawerInstance.value.scale /= 1.2;

  const newPaddingX = clientX / desktopDrawerInstance.value.scale - clientX;
  const newPaddingY = clientY / desktopDrawerInstance.value.scale - clientY;

  desktopDrawerInstance.value.translateX += newPaddingX - currentPaddingX;
  desktopDrawerInstance.value.translateY += newPaddingY - currentPaddingY;
};

const setGroup = () => {
  desktopDrawerInstance.value?.createNewShapeGroupFromDraftGroup();
};

const actionUnGroup = () => {
  desktopDrawerInstance.value?.actionUnGroup();
};

const unSelectAll = () => {
  desktopDrawerInstance.value?.setSelectedShapeIds(null);
  desktopDrawerInstance.value?.setCurrentGroup();

  currentTool.value = EWhiteboardTool.SELECT;
};

const clickOnBackground = () => {
  if (isBrushing.value) {
    return;
  }

  unSelectAll();
  desktopDrawerInstance.value?.updateStatus('SELECT');
};

const onCloseTaskList = () => {
  currentTool.value = EWhiteboardTool.SELECT;
};

const onConfirmSelectTask = (taskDetail) => {
  if (!taskDetail || !taskDetail?.id) return;
  desktopDrawerInstance.value?.addShape(ShapeType.TASK, taskDetail);

  onCloseTaskList();
  updateMinimap();
  addMoreHistory();
};
const onConfirmSelectUser = (user) => {
  desktopDrawerInstance.value?.addShape(ShapeType.USER, user);

  onCloseTaskList();
  updateMinimap();
  addMoreHistory();
};

const currentTool = ref<EWhiteboardTool>(EWhiteboardTool.SELECT);
const isDragging = ref<boolean>(false);
const toolGroupList = [
  {
    id: 'SELECT',
    children: [
      {
        id: EWhiteboardTool.SELECT,
        iconName: 'tool-select',
      },
      {
        id: EWhiteboardTool.TOOL_DRAG,
        iconName: 'tool-drag',
      },
    ],
  },
  {
    id: 'ATTACHMENT',
    children: [
      {
        id: EWhiteboardTool.TASK,
        iconName: 'note',
      },
      {
        id: EWhiteboardTool.USER,
        iconName: 'user',
      },
      {
        id: EWhiteboardTool.DRAW_NOTE,
        iconName: 'noter',
      },
      {
        id: EWhiteboardTool.TODO_LIST,
        iconName: 'todo-list',
      },
      {
        id: EWhiteboardTool.MEDIA_AUDIO,
        iconName: 'tool-microphone',
      },
      {
        id: EWhiteboardTool.MEDIA_IMAGE,
        // iconName: 'tool-image',
        iconName: 'attach-image',
      },
      {
        id: EWhiteboardTool.MEDIA_LINK,
        iconName: 'link',
      },
    ],
  },
  {
    id: 'ADVANCE',
    children: [
      {
        id: EWhiteboardTool.TEXT,
        iconName: 'tool-text',
      },
      {
        id: EWhiteboardTool.ARROW,
        iconName: 'tool-arrow',
      },

      {
        id: EWhiteboardTool.RECYCLE_CAN,
        iconName: 'RecycleCan',
      },
    ],
  },
];

const _getTypeOfFile = (file) => {
  if ((file?.type || file?.contentType)?.includes('image'))
    return ShapeType.MEDIA_IMAGE;
  if ((file?.type || file?.contentType)?.includes('audio'))
    return ShapeType.MEDIA_AUDIO;
  if ((file?.type || file?.contentType)?.includes('video'))
    return ShapeType.MEDIA_VIDEO;

  return ShapeType.MEDIA_DOCUMENT;
};

const onAddedFiles = (files: IAttachmentFileDetail[]) => {
  let position = 0;
  files.forEach((file) => {
    const typeOfFile = _getTypeOfFile(file);

    position += 20;
    switch (typeOfFile) {
      case ShapeType.MEDIA_IMAGE:
        {
          onLoadImage(file.blob, {
            onLoaded: (image) => {
              const fileSize = getImageSize(image);

              desktopDrawerInstance.value?.addShape(
                ShapeType.MEDIA_IMAGE,
                {
                  ...file,
                  id: file?.localId,
                },
                {
                  translateX: position,
                  translateY: position,
                  width: fileSize?.width,
                  height: fileSize?.height,
                }
              );
              updateMinimap();
              addMoreHistory();
            },
          });
        }
        break;

      case ShapeType.MEDIA_AUDIO:
        {
          desktopDrawerInstance.value?.addShape(
            ShapeType.MEDIA_AUDIO,
            {
              ...file,
              id: file?.localId,
            },
            {
              translateX: position,
              translateY: position,
            }
          );
          updateMinimap();
          addMoreHistory();
        }
        break;

      default:
        {
          desktopDrawerInstance.value?.addShape(
            typeOfFile,
            {
              ...file,
              id: file?.localId,
            },
            {
              translateX: position,
              translateY: position,
            }
          );
          updateMinimap();
          addMoreHistory();
        }
        break;
    }
  });
};
const onChangedFiles = (files: IAttachmentFileDetail[]) => {
  files.forEach((file) => {
    const currentShape = desktopDrawerInstance.value.shapeList?.find(
      (sh) => sh?.sourceId && sh?.sourceId == file?.localId
    );
    if (currentShape && currentShape?.id) {
      desktopDrawerInstance.value.updateShape(
        currentShape?.id,
        'SOURCE_DATA',
        file
      );
      addMoreHistory();
    }
  });
};
const onAddedNotes = (notes: IDrawNoteDetail[]) => {
  let position = 0;
  notes.forEach((note) => {
    position += 20;

    onLoadImage(note, {
      onLoaded: (image) => {
        const fileSize = getImageSize(image);
        desktopDrawerInstance.value?.addShape(
          ShapeType.DRAW_NOTE,
          {
            ...note,
            id: note?.localId,
          },
          {
            translateX: position,
            translateY: position,
            width: fileSize?.width,
            height: fileSize?.height,
          }
        );
        updateMinimap();
        addMoreHistory();
      },
    });
  });
};
const onChangedNotes = (notes: IDrawNoteDetail[]) => {
  notes.forEach((note) => {
    const currentShape = desktopDrawerInstance.value.shapeList?.find(
      (sh) => sh?.sourceId && sh?.sourceId == note?.localId
    );
    if (currentShape && currentShape?.id) {
      desktopDrawerInstance.value.updateShape(
        currentShape?.id,
        'SOURCE_DATA',
        note
      );
      addMoreHistory();
    }
  });
};

const {
  isOpenRecording,
  onCreateAudioDone,
  onChooseFiles,
  isOpenNote,
  drawNoteData,
  onOpenCreateNote,
  onSaveNote,
  onCloseNote,
} = useAttachFilesAndNotes('STORAGE', {
  isUploadRightAway: true,
  onFilesAdded: onAddedFiles,
  onFilesChanged: onChangedFiles,
  onNotesAdded: onAddedNotes,
  onNotesChanged: onChangedNotes,
});

const onSaveNewNote = (nodeData) => {
  pastedImage.value = null;
  onSaveNote(nodeData);
};

const onAddLink = (linkData) => {
  currentTool.value = EWhiteboardTool.SELECT;

  const url = linkData?.url;
  if (!url) return;

  const info = linkData?.info;

  desktopDrawerInstance.value?.addShape(ShapeType.MEDIA_LINK, {
    url: url,
    ...info,
  });
  updateMinimap();
  addMoreHistory();
};

let goToDefaultModeTimer;
const goToDefaultModeTimeout = () => {
  if (goToDefaultModeTimer) clearTimeout(goToDefaultModeTimer);

  goToDefaultModeTimer = setTimeout(() => {
    chooseTool(EWhiteboardTool.SELECT);
  }, 30 * 1000);
};
const chooseTool = (toolId) => {
  currentTool.value = toolId;

  switch (currentTool.value) {
    case EWhiteboardTool.TOOL_DRAG:
      {
        isShowMinimap.value = true;

        goToDefaultModeTimeout();
      }
      break;
    case EWhiteboardTool.TEXT:
      {
        desktopDrawerInstance.value?.addShape(ShapeType.TEXT, {
          text: '',
        });
        setTimeout(() => {
          desktopDrawerInstance.value?.updateStatus('TEXT_EDITING');
          unSelectAll();
        });
        updateMinimap();
        addMoreHistory();
      }
      break;
    case EWhiteboardTool.DRAW_NOTE:
      {
        onOpenCreateNote();
      }
      break;
    case EWhiteboardTool.TODO_LIST:
      {
        desktopDrawerInstance.value?.addShape(ShapeType.TODO_LIST, {
          id: uuidv4(),
          title: 'New checklist',
          description: '',
          todoItems: [
            {
              id: uuidv4(),
              title: '',
              shortDescription: '',
              description: '',
              status: ITaskTodoItemStatus.PENDING,
              index: 0,
            },
          ],
        });
        updateMinimap();
        addMoreHistory();
      }
      break;
    case EWhiteboardTool.MEDIA_AUDIO:
      {
        isOpenRecording.value = true;
      }
      break;

    default:
      break;
  }
};

const updatedHistories = ref<any[]>([]);
const canUndo = computed<any>(() => {
  return updatedHistories.value?.length > 0 && currentVersionIndex.value > 0;
});
const currentVersionIndex = ref<number>(0);
const canRedo = computed<any>(() => {
  return (
    updatedHistories.value?.length > 0 &&
    currentVersionIndex.value < updatedHistories.value.length - 1
  );
});

const addMoreHistory = () => {
  const newHistory = convertClassToJSON(desktopDrawerInstance.value);

  const canAdd =
    updatedHistories.value.length == 0 ||
    getObjectDiff(
      newHistory,
      updatedHistories.value[updatedHistories.value?.length - 1]
    )?.filter((key) => ['translateX', 'translateY', 'shapeList'].includes(key))
      .length > 0;

  if (!canAdd) return;

  updatedHistories.value.splice(
    currentVersionIndex.value + 1,
    updatedHistories.value.length - currentVersionIndex.value + 1,
    convertClassToJSON(desktopDrawerInstance.value)
  );

  currentVersionIndex.value = updatedHistories.value.length - 1;
};
const actionList = [
  {
    id: 'CLONE',
    iconName: 'duplicate',
    checkEnable: () => false,
    onClick: () => {},
  },
  {
    id: 'UNDO',
    iconName: 'undo',
    checkEnable: () => canUndo.value,
    onClick: () => {
      if (!canUndo.value) return;

      currentVersionIndex.value -= 1;

      desktopDrawerInstance.value = new DesktopDrawerEntity(
        updatedHistories.value[currentVersionIndex.value]
      );

      onSaveChangeRealtime();
    },
  },
  {
    id: 'REDO',
    iconName: 'redo',
    checkEnable: () => canRedo.value,
    onClick: () => {
      if (!canRedo.value) return;

      currentVersionIndex.value += 1;

      desktopDrawerInstance.value = new DesktopDrawerEntity(
        updatedHistories.value[currentVersionIndex.value]
      );

      onSaveChangeRealtime();
    },
  },
  {
    id: 'REMOVE',
    iconName: 'trash',
    checkEnable: () => {
      return selectedShapeIds.value?.length > 0;
    },
    onClick: () => {
      if (!selectedShapeIds.value || selectedShapeIds.value?.length == 0)
        return;

      selectedShapeIds.value.forEach((shapeId) => {
        desktopDrawerInstance.value.removeShapeById(shapeId);
      });

      addMoreHistory();
    },
  },
];

watch(
  () => desktopDrawerInstance.value?.timeoutSaveChange,
  (newData) => {
    if (newData) isDisabledSaveButton.value = false;
  }
);

const onSaveChangeRealtime = () => {
  desktopDrawerInstance.value?.onSaveChangeRealtime();
};

const isDisabledSaveButton = ref<boolean>(true);
const onSaveChange = () => {
  desktopDrawerInstance.value?.onSaveChange();
  isDisabledSaveButton.value = true;
};

const isBrushing = ref<boolean>(false);
const onMouseDownOnBackground = (event) => {
  isBrushing.value = false;
  const offsetX = event?.offsetX - desktopDrawerInstance.value?.translateX;
  const offsetY = event?.offsetY - desktopDrawerInstance.value?.translateY;
  desktopDrawerInstance.value.addBrush(offsetX, offsetY);
};
const onMouseMoveOnBackground = () => {
  isBrushing.value = true;
};
const onMouseUpOnBackground = () => {
  desktopDrawerInstance.value.removeBrush();
};

const isOpenMoreOtherWhiteboard = ref<boolean>(false);

const isFullPage = computed<boolean>(() => {
  return route.name?.toString()?.startsWith('Fp');
});

const switchToWhiteboard = (whiteboardId) => {
  if (whiteboardId == desktopDrawerInstance.value?.id) return;

  router.push({
    name: isFullPage.value ? 'FpWhiteboardDetail' : 'WhiteboardDetail',
    params: {
      id: whiteboardId,
    },
  });
};

const isOpenCreateWhiteboardModal = ref<boolean>(false);
const onCreateSuccess = (newWhiteboard) => {
  isOpenCreateWhiteboardModal.value = false;

  if (!newWhiteboard?.id) return;

  router.push({
    name: isFullPage.value ? 'FpWhiteboardDetail' : 'WhiteboardDetail',
    params: { id: newWhiteboard?.id },
  });
};

const isEditingTitle = ref<boolean>(false);
const currentUpdateWhiteboard = ref<
  | {
      id: string;
      title: string;
    }
  | undefined
>();
// const onOpenEditTitle = (id, title) => {
//   currentUpdateWhiteboard.value = {
//     id,
//     title,
//   };
//   isEditingTitle.value = true;
// };
const onUpdateWhiteboardTitleSuccess = (newTitle) => {
  if (currentUpdateWhiteboard.value?.id == desktopDrawerInstance.value?.id)
    desktopDrawerInstance.value.title = newTitle;

  currentUpdateWhiteboard.value = undefined;
  isEditingTitle.value = false;
};
// const onRemoveWhiteboard = async (whiteboardId, whiteboardTitle) => {
//   const res = await _whiteboardStore.deleteWhiteboard(
//     whiteboardId,
//     whiteboardTitle
//   );
//   if (res && whiteboardId == desktopDrawerInstance.value?.id)
//     router.push({ name: 'WhiteboardList' });
// };

const onRestoreShape = (shapeList) => {
  currentTool.value = EWhiteboardTool.SELECT;
  let position = 0;
  shapeList.forEach((shape) => {
    position += 20;

    desktopDrawerInstance.value?.addShape(
      shape?.type,
      typeof shape?.sourceData == 'string'
        ? JSON.parse(shape?.sourceData)
        : shape?.sourceData,
      {
        translateX: position,
        translateY: position,
        width: shape?.width,
        height: shape?.height,
      }
    );

    updateMinimap();
    addMoreHistory();
  });
};

const drawArrowData = ref<ShapeArrowEntity | undefined>(undefined);
const onStartDrawArrow = (event) => {
  if (!event) {
    drawArrowData.value = undefined;
    return;
  }

  // const clientRect = event?.target?.getBoundingClientRect();

  const newShape = desktopDrawerInstance.value?.addShape(
    ShapeType.ARROW,
    {
      isStraight: true,
      start: {
        point: {
          x: 0,
          y: 0,
        },
        arrowhead: false,
      },
      end: {
        point: {
          x: 0,
          y: 0,
        },
        arrowhead: 'triangle',
      },
    },
    {
      translateX: drawerLayerElementX.value,
      translateY: drawerLayerElementY.value,
    }
  );

  drawArrowData.value = newShape as ShapeArrowEntity;
  updateMinimap();
};
const onEndDrawArrow = () => {
  currentTool.value = EWhiteboardTool.SELECT;
  drawArrowData.value = undefined;
  addMoreHistory();
};

const currentUpdateArrowSide = ref<'start' | 'end'>('end');
const updateArrowShape = (shapeId, side: 'start' | 'end') => {
  currentUpdateArrowSide.value = side || 'end';
  const arrowShape = shapeList.value?.find((s) => s?.id == shapeId) as
    | ShapeArrowEntity
    | undefined;
  if (arrowShape) {
    drawArrowData.value = arrowShape;
    currentTool.value = EWhiteboardTool.ARROW;
  }
};

const isShowMinimap = ref(false);

const { updateDocumentTitle } = customDocumentTitle();
const handleChangeDocumentTitle = () => {
  let nameInfo = desktopDrawerInstance.value.title || 'Whiteboard';

  nameInfo =
    nameInfo.length > 55 ? `${nameInfo.substring(0, 56)}...` : nameInfo;

  updateDocumentTitle(
    `${nameInfo} | ${translate('COMMON_LABEL_MY_DESKTOP')} | TicTop`
  );
};

let updateMinimapTimeout;
const zoomTo = (scale) => {
  desktopDrawerInstance.value.scale = scale;

  if (updateMinimapTimeout) clearTimeout(updateMinimapTimeout);
  updateMinimapTimeout = setTimeout(() => {
    // if (scale == 1)
    //   desktopDrawerInstance.value.changePosition(0, 0, updateMinimap);

    updateMinimap();
  }, 100);
};

// const isOpenMoreAction = ref<boolean>(false);
provide(PROVIDER_IDS.DESKTOP_DRAWER, {
  keepingShape,
  updateKeepingShape,
  desktopDrawerInstance,
  unSelectAll,
  onMouseUp,
  onSelectResize,
  updateArrowShape,
  addMoreHistory,
} as TDesktopDrawer);
</script>
<template>
  <div
    v-system-config="{
      functionCode: 'DRAFT_WHITEBOA_WHITEBOARD_FUNCTIONS_ALLOWED',
      functionValue: '1',
    }"
    class="w-full h-full relative"
  >
    <!-- container -->
    <div class="relative flex flex-col w-full h-full">
      <div class="h-full flex flex-col">
        <!-- HEADER -->
        <div
          class="
            flex
            items-center
            justify-between
            w-full
            bg-white
            shadow-md
            border-t
          "
        >
          <div class="tlui-menu-zone">
            <div class="tlui-menu-zone__controls rounded-md space-x-2 px-4">
              <VigDropdown
                v-model:is-open="isOpenMoreOtherWhiteboard"
                placement="bottom-start"
                :arrow="false"
              >
                <template #dropdown-toggle>
                  <div
                    class="
                      w-64
                      h-10
                      px-2
                      flex
                      items-center
                      justify-between
                      cursor-pointer
                      bg-gray-100
                      rounded-md
                      hover-to-show__parent
                    "
                  >
                    <div class="flex-1 min-w-0 pl-2 text-lg truncate">
                      {{ desktopDrawerInstance?.title }}
                    </div>

                    <div class="flex-center space-x-1">
                      <!-- <div
                        class="flex-center space-x-1 hover-to-show__children"
                      >
                        <SynIcon
                          name="edit"
                          has-action
                          @click.stop="
                            onOpenEditTitle(
                              desktopDrawerInstance?.id,
                              desktopDrawerInstance?.title
                            )
                          "
                        />
                        <SynIcon
                          name="trash"
                          has-action
                          custom-class="w-4 h-4 fill-red-500"
                          @click.stop="
                            onRemoveWhiteboard(
                              desktopDrawerInstance?.id,
                              desktopDrawerInstance?.title
                            )
                          "
                        />
                      </div> -->

                      <SynIcon
                        :name="'sort-down'"
                        custom-class="h-2.5 w-2.5"
                        :class="!isOpenMoreOtherWhiteboard ? '-rotate-90' : ''"
                      />
                    </div>
                  </div>
                </template>
                <template #dropdown-menu>
                  <div class="flex flex-col w-64">
                    <div class="list-ul">
                      <div
                        v-for="whiteboard in allWhiteboards"
                        :key="whiteboard.id"
                        class="
                          list-li
                          px-4
                          dropdown-item
                          flex
                          items-center
                          justify-between
                          hover-to-show__parent
                          h-10
                        "
                        :class="
                          whiteboard?.id == desktopDrawerInstance?.id
                            ? 'bg-current-50'
                            : ''
                        "
                        @click="switchToWhiteboard(whiteboard?.id)"
                      >
                        <span>
                          {{ whiteboard?.title }}
                        </span>
                        <!-- <div
                          class="flex-center space-x-1 hover-to-show__children"
                        >
                          <SynIcon
                            name="edit"
                            has-action
                            @click.stop="
                              onOpenEditTitle(whiteboard?.id, whiteboard?.title)
                            "
                          />
                          <SynIcon
                            name="trash"
                            has-action
                            custom-class="w-4 h-4 fill-red-500"
                            @click.stop="
                              onRemoveWhiteboard(
                                whiteboard?.id,
                                whiteboard?.title
                              )
                            "
                          />
                        </div> -->
                      </div>
                    </div>

                    <div
                      class="
                        p-2
                        flex
                        items-center
                        space-x-2
                        cursor-pointer
                        hover:bg-current-50
                        dropdown-item
                        border-t
                        bg-gray-50
                      "
                      @click.stop="isOpenCreateWhiteboardModal = true"
                    >
                      <SynIcon
                        name="plus"
                        custom-class="w-3 h-3 fill-current"
                      />
                      <span class="text-current-500 text-sm">
                        {{ $t('WHITEBOARD_CREATE_NEW') }}
                      </span>
                    </div>
                    <RouterLink
                      :to="{
                        name: isFullPage
                          ? 'FpWhiteboardList'
                          : 'WhiteboardList',
                        query: {
                          redirect: '2',
                        },
                      }"
                      class="
                        p-2
                        flex
                        items-center
                        space-x-2
                        cursor-pointer
                        hover:bg-current-50
                        dropdown-item
                        border-t
                        bg-gray-50
                      "
                    >
                      <SynIcon
                        name="redirect"
                        custom-class="w-3 h-3 fill-current"
                      />
                      <span class="text-current-500 text-sm">
                        {{ $t('WHITEBOARD_LABEL_GO_TO_THE_MANAGEMENT_PAGE') }}
                      </span>
                    </RouterLink>
                  </div>
                </template>
              </VigDropdown>
            </div>
          </div>
          <div class="flex-center">
            <div
              class="
                flex-center
                gap-2
                tlui-menu-zone__controls
                text-base
                px-2
                py-1
                rounded-md
              "
            >
              <div
                v-for="group of toolGroupList"
                :key="group?.id"
                class="flex-center gap-2"
              >
                <span
                  v-for="tool in group?.children"
                  :key="tool?.id"
                  class="flex-center"
                  @click="chooseTool(tool?.id)"
                >
                  <div
                    class="w-10 h-10 flex-center cursor-pointer rounded-md"
                    :class="{
                      'bg-blue-500': currentTool == tool?.id,
                      'hover:bg-blue-50': currentTool !== tool?.id,
                    }"
                  >
                    <template v-if="tool?.id == EWhiteboardTool.MEDIA_IMAGE">
                      <label
                        :for="attachFileInputId"
                        class="w-full h-full flex-center cursor-pointer"
                      >
                        <SynIcon
                          :name="tool?.iconName"
                          :custom-class="
                            currentTool == tool?.id
                              ? 'w-6 h-6 fill-white'
                              : 'w-6 h-6 fill-current'
                          "
                        />
                      </label>
                    </template>
                    <SynIcon
                      v-else
                      :name="tool?.iconName"
                      :custom-class="
                        currentTool == tool?.id
                          ? 'w-5 h-5 fill-white text-white'
                          : 'w-5 h-5 fill-current text-current-500'
                      "
                    />
                  </div>
                </span>
                <span class="text-gray-300 px-1">|</span>
              </div>
            </div>

            <div class="tlui-menu-zone">
              <div
                class="tlui-menu-zone__controls p-1 rounded-md m-2 space-x-2"
              >
                <div class="flex items-center">
                  <span
                    v-if="draftShapeGroup?.shapes?.length > 1"
                    @click="setGroup"
                  >
                    Set group
                  </span>
                  <span v-if="selectedShapeGroup?.id" @click="actionUnGroup">
                    Un group
                  </span>
                  <button
                    v-for="action in actionList"
                    :key="action?.id"
                    class="w-10 h-10 flex-center rounded-md hover:bg-gray-100"
                    :disabled="!action?.checkEnable()"
                    @click="action?.onClick"
                  >
                    <SynIcon
                      :name="action?.iconName"
                      :custom-class="
                        action?.checkEnable()
                          ? 'w-5 h-5 fill-gray-500'
                          : 'w-5 h-5 fill-gray-300'
                      "
                    />
                  </button>
                </div>
                <SynButton
                  type-outline
                  :disabled="isDisabledSaveButton"
                  @click="onSaveChange"
                >
                  <span class="relative">
                    <span>
                      {{ $t('COMMON_LABEL_SAVE') || 'Save change' }}
                    </span>
                  </span>
                </SynButton>
              </div>
            </div>
          </div>
        </div>
        <div
          id="desktop-drawer-container"
          ref="desktopDrawerContainerRef"
          class="flex-1 relative tl-container tl-theme__light"
        >
          <div class="absolute inset-0 h-full w-full tl-canvas">
            <!-- background -->
            <div
              id="desktop-drawer-background"
              class="absolute h-full w-full bg-gray-50 white-board-background"
              @click.self="clickOnBackground"
            ></div>
            <div
              v-if="typeof desktopDrawerInstance?.configs?.minY == 'number'"
              class="absolute ring-2 w-full ring-current-500"
              :style="{
                top: `${desktopDrawerInstance?.configs?.minY}px`,
              }"
            ></div>
            <div
              v-if="typeof desktopDrawerInstance?.configs?.maxY == 'number'"
              class="absolute ring-2 w-full ring-current-500"
              :style="{
                top: `${desktopDrawerInstance?.configs?.maxY}px`,
              }"
            ></div>
            <div
              v-if="typeof desktopDrawerInstance?.configs?.minX == 'number'"
              class="absolute ring-2 h-full ring-current-500"
              :style="{
                left: `${desktopDrawerInstance?.configs?.minX}px`,
              }"
            ></div>
            <div
              v-if="typeof desktopDrawerInstance?.configs?.maxX == 'number'"
              class="absolute ring-2 h-full ring-current-500"
              :style="{
                left: `${desktopDrawerInstance?.configs?.maxX}px`,
              }"
            ></div>
            <!-- background -->
            <div
              v-if="currentTool == EWhiteboardTool.TOOL_DRAG"
              class="absolute h-full w-full bg-transparent"
              style="z-index: 10000; cursor: grab"
            ></div>
            <div
              v-if="currentTool == EWhiteboardTool.ARROW"
              class="absolute h-full w-full bg-transparent"
              style="z-index: 10000; cursor: crosshair"
              @mousedown.self="onStartDrawArrow"
              @mouseup.self="onEndDrawArrow"
              @mouseup.middle="
                [(currentTool = EWhiteboardTool.SELECT), (isDragging = false)]
              "
            ></div>

            <div class="absolute left-2 bottom-2" style="z-index: 5000">
              <div class="flex items-end gap-2">
                <div class="w-max">
                  <ZoomDropdown
                    v-model="desktopDrawerInstance.scale"
                    @update:model-value="zoomTo"
                  />
                </div>
                <div v-if="shapeList?.length > 0" class="relative">
                  <template
                    v-if="
                      isShowMinimap || currentTool == EWhiteboardTool.TOOL_DRAG
                    "
                  >
                    <Minimap
                      ref="whiteboardMinimapRef"
                      :shape-list="shapeList"
                      :translate-x="desktopDrawerInstance.translateX"
                      :translate-y="desktopDrawerInstance.translateY"
                      :scale="desktopDrawerInstance.scale || 1"
                    />
                  </template>
                </div>
              </div>
            </div>

            <!-- layer -->
            <div
              id="desktop-drawer-layer"
              ref="desktopDrawerLayerRef"
              class="tl-html-layer"
              :style="{
                position: 'absolute',
                width: '1px',
                height: '1px',
                transform: `scale(${desktopDrawerInstance.scale}) translate( ${desktopDrawerInstance.translateX}px, ${desktopDrawerInstance.translateY}px)`,
              }"
            >
              <!-- shapes -->
              <div class="tl-shapes">
                <!-- <DesktopDrawerShapeTest /> -->
                <template v-for="shape in shapeList" :key="shape?.id">
                  <DesktopDrawerShape
                    :shape-entity="shape"
                    :style="{
                      pointerEvents: 'all',
                    }"
                  >
                    <template v-if="shape?.type == ShapeType.ARROW">
                      <DesktopDrawerShapeArrow :shape-entity="shape" />
                    </template>
                    <template v-if="shape?.type == ShapeType.TASK">
                      <DesktopDrawerShapeTask
                        v-if="shape?.sourceId"
                        :shape-entity="shape"
                      />
                    </template>

                    <template v-if="shape?.type == ShapeType.USER">
                      <DesktopDrawerShapeUser :shape-entity="shape" />
                    </template>
                    <template v-if="shape?.type == ShapeType.TEXT">
                      <DesktopDrawerShapeText :shape-entity="shape" />
                    </template>
                    <template v-if="shape?.type == ShapeType.DRAW_NOTE">
                      <DesktopDrawerShapeDrawNote
                        :shape-entity="shape"
                        @update-data="onSaveChangeRealtime"
                      />
                    </template>
                    <template v-if="shape?.type == ShapeType.TODO_LIST">
                      <DesktopDrawerShapeTodoList :shape-entity="shape" />
                    </template>
                    <template
                      v-if="
                        shape?.type == ShapeType.MEDIA_AUDIO ||
                        shape?.type == ShapeType.MEDIA_IMAGE ||
                        shape?.type == ShapeType.MEDIA_DOCUMENT ||
                        shape?.type == ShapeType.MEDIA_VIDEO
                      "
                    >
                      <DesktopDrawerShapeMedia :shape-entity="shape" />
                    </template>
                    <template v-if="shape?.type == ShapeType.MEDIA_LINK">
                      <DesktopDrawerShapeLink
                        :shape-entity="shape"
                        :is-dragging="keepingShape?.shapeId == shape?.id"
                      />
                    </template>
                  </DesktopDrawerShape>
                </template>
              </div>
              <!-- overlays -->
              <DesktopDrawerOverlays />
            </div>
          </div>
          <!-- actions -->
          <div
            class="absolute w-full h-full top-0 left-0 tlui-layout"
            style="z-index: 5001"
          >
            <div class="grid grid-cols-3">
              <div class="flex flex-col"></div>
              <div></div>
              <!-- SEARCH TASK AND USER -->
              <div
                class="h-ful flex flex-col justify-between items-end relative"
              >
                <div
                  class="
                    border border-gray-300
                    rounded-md
                    overflow-hidden
                    absolute
                    bottom-0
                    right-0
                    transition
                    duration-300
                    transform
                    bg-white
                  "
                  :class="[
                    currentTool == EWhiteboardTool.TASK
                      ? 'translate-x-0 ease-out'
                      : 'translate-x-full ease-in',
                  ]"
                  style="height: 100%; pointer-events: all"
                >
                  <SearchTasks
                    @on-close="onCloseTaskList"
                    @on-select-one="onConfirmSelectTask"
                  />
                </div>
                <div
                  class="
                    border border-gray-300
                    rounded-md
                    overflow-hidden
                    absolute
                    bottom-0
                    right-0
                    transition
                    duration-300
                    transform
                    bg-white
                    px-2
                  "
                  :class="[
                    currentTool == EWhiteboardTool.USER
                      ? 'translate-x-0 ease-out'
                      : 'translate-x-full ease-in',
                  ]"
                  style="width: 22rem; height: 100%; pointer-events: all"
                >
                  <SearchAndSelectUser
                    @on-close="onCloseTaskList"
                    @on-select-one="onConfirmSelectUser"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <input
      :id="attachFileInputId"
      type="file"
      multiple
      class="hidden opacity-0"
      :accept="_remoteConfigStore.webTaskUploadContentTypes"
      @change="onChooseFiles"
    />
  </div>

  <CreateNoteModal
    v-if="isOpenNote"
    :draw-note-data="drawNoteData"
    :pasted-image="pastedImage"
    @on-close="onCloseNote"
    @on-save="onSaveNewNote"
  />
  <RecorderModal
    v-if="isOpenRecording"
    :max-record-minute="10"
    @on-done="onCreateAudioDone"
    @on-cancel="isOpenRecording = false"
  />

  <AtomAttachLinkModal
    v-if="currentTool == EWhiteboardTool.MEDIA_LINK"
    @on-cancel="currentTool = EWhiteboardTool.SELECT"
    @done="onAddLink"
  />

  <WhiteboardCreateModal
    v-if="isOpenCreateWhiteboardModal"
    @cancel="isOpenCreateWhiteboardModal = false"
    @create-success="onCreateSuccess"
  />

  <WhiteboardEditTitleModal
    v-if="isEditingTitle && currentUpdateWhiteboard"
    :whiteboard-id="currentUpdateWhiteboard?.id"
    :whiteboard-title="currentUpdateWhiteboard?.title"
    @cancel="isEditingTitle = false"
    @update-title-success="onUpdateWhiteboardTitleSuccess"
  />
  <RecycleShapeListModal
    v-if="currentTool == EWhiteboardTool.RECYCLE_CAN"
    @cancel="currentTool = EWhiteboardTool.SELECT"
    @on-restore="onRestoreShape"
  />
</template>
<style>
@import '@/ui/components/drawer/tl-drawer.scss';

/* Chrome, Safari, Edge, Opera */
input.zoom-input::-webkit-outer-spin-button,
input.zoom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.zoom-input[type='number'] {
  -moz-appearance: textfield;
}

input.zoom-input {
  padding: 0;
  border: 0;
  width: 2rem;
}
input.zoom-input:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #4bb0b2;
}
</style>
