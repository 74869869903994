<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import PersonalSidebarItem from './PersonalSidebarItem.vue';
import settingStore from '@/store/setting';
import appStore from '@/store/app';
import { isOpenSpeedChatDrawer } from '@/ui/modules/messaging/speed-chat/speed-chat.const';

defineProps<{
  data: any[];
  currentActive: string;
  type: string;
  onAction: Function;
  isCollapse: boolean;
}>();
defineEmits(['on-collapse']);
const route = useRoute();
const _settingStore = settingStore();
const _appStore = appStore();
const isOpenMenuBar = computed(() => _appStore.isOpenMenuBar);
// show setting group

const getMainPageLinkUrl = () => {
  return `/messages`;
};

const isNotMessagePage = computed<boolean>(() => {
  return route?.name !== 'Messages';
});

const chatEnabled = computed(() => _settingStore.chatEnabled);
</script>

<template>
  <div v-if="chatEnabled" v-permission="'Messages'" class="w-full">
    <PersonalSidebarItem
      icon-name="contact"
      :is-active="
        currentActive == 'messages' || currentActive.includes('messages')
      "
      :is-hidden-text="!isOpenMenuBar"
      :item-name="$t('CHAT_AND_CALL_LABEL') || 'Chat & call'"
      :link-to="getMainPageLinkUrl()"
    >
      <template #suffix>
        <div
          v-if="isNotMessagePage"
          class="h-8 w-8 flex-center rounded-md group"
          @click.stop.prevent="isOpenSpeedChatDrawer = !isOpenSpeedChatDrawer"
        >
          <SynIcon
            name="flash"
            class="group-hover:fill-yellow-400"
            :custom-class="
              isOpenSpeedChatDrawer
                ? 'h-4 w-4 fill-yellow-500'
                : 'h-4 w-4 fill-gray-500'
            "
          />
        </div>
      </template>
    </PersonalSidebarItem>
  </div>
</template>
