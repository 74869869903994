<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TaskList from '@/ui/modules/task/table/TaskList.vue';
// import FavoriteButton from '@/ui/components/user/favorite-button.vue';
import taskListStore from '@/store/task/task-list-store';
import userStore from '@/store/user';
import settingStore from '@/store/setting';
import { isEqual } from '@/ui/hooks/commonFunction';
import { decryptStringToNumber } from '@/ui/plugins/crypto/custom-crypto';
import FilterTaskButton from '@/ui/modules/task/filter/FilterTaskButton.vue';
import DomainDetailModal from '@/ui/modules/domains/domain/DomainDetailModal.vue';
import TaskTabs from '@/ui/modules/task/components/TaskTabs.vue';
// import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';
import { TaskListTab } from '@/domain/enums/taskEnum';
import customDocumentTitle from '@/ui/composables/app/document-title';
import domainStore from '@/store/scope';
import { getCurrentUserId } from '@/ui/hooks/storageHook';
import DomainManagementPage from '@/ui/modules/domains/domain-page/DomainManagementPage.vue';
import CustomExportModal from '@/ui/modules/task/export/CustomExportModal.vue';
// import { hasRolePermission } from '@/ui/hooks/permission/permission-by-role';
// import { AdminType } from '@/ui/common/constants/constant';
import AvatarByTaskList from '@/ui/modules/task/components/task-list/AvatarByTaskList.vue';
import FilterTabs from '@/ui/modules/task/components/task-list/FilterTabs.vue';
import TaskBoard from '@/ui/modules/task/board/TaskBoard.vue';
import taskTabViewStore from '@/store/task/task-tab-view';
import tableListTaskComposable from '@/ui/composables/task/table-list-task';
import ActionsListTask from '@/ui/modules/task/filter/ActionsListTask.vue';
import { ALL_SCREENS } from '@/ui/hooks/permission/permission-by-function';
import UpdateMultipleActions from '@/ui/modules/task/table/UpdateMultipleActions.vue';
import taskListRealtime from '@/ui/modules/task/composables/task-list-realtime';
import {
  EFilterTaskDefault,
  ETaskViewType,
} from '@/application/constants/task.const';
import {
  ECustomFilterSourceType,
  ETaskListModule,
} from '@/application/types/task/task.types';
import GanttChart from '@/ui/pages/tasks/GanttChart.vue';
import TaskCalendarView from '@/ui/pages/tasks/calendar/TaskCalendarView.vue';
import localConfigsStore from '@/store/local-configs';
import remoteConfigStore from '@/store/remoteConfig';
import GedPage from '@/ui/modules/ged/ged-page/GedPage.vue';

const props = defineProps<{
  domainId: any;
  projectId: any;
  subProjectId: any;
  createTaskDomainId: any;
  isShowTasksOnly?: boolean;
  hasSetDefaultButton?: boolean;
  isIncludeSub?: boolean;
  isHiddenFilterSub?: boolean;
  customFilterSourceType?: ECustomFilterSourceType;
}>();
defineEmits<{
  (e: 'addNewTask'): void;
  (e: 'ready'): void;
}>();

const _taskListStore = taskListStore();
const _userStore = userStore();
const _settingStore = settingStore();
const _domainStore = domainStore();
const _taskTabViewStore = taskTabViewStore();
const router = useRouter();
const _localConfigsStore = localConfigsStore();
const _remoteConfigStore = remoteConfigStore();

const viewTask = computed<ETaskViewType>(() => _taskTabViewStore.customizeView);
const domainByIds = computed(() => _domainStore.domainByIds);
const childrenTabActive = computed(() => _taskTabViewStore.childrenTabActive);
const domainId = computed(
  () => props?.domainId || decryptStringToNumber(route.params?.id?.toString())
);
const currentUserId = computed(() => getCurrentUserId());
const selectedTaskIds = computed(() => _taskListStore.selectedTaskIds);
const isLoading = computed(() => _taskListStore.isLoading);
const totalTasks = computed(() => _taskListStore.taskAllList?.length || 0);
const isVisitor = computed(() => _userStore.isVisitor);
// const isAdmin = computed(
//   () => _userStore.isAdmin || hasRolePermission(AdminType.Owner)
// );
const isTasksTab = computed(
  () =>
    selectedTab.value == TaskListTab.MyList ||
    selectedTab.value == TaskListTab.MyAssign
);
const actionHidden = computed(() => {
  let res = [] as any;
  if (!isTasksTab.value) res?.push('export_task');
  if (isVisitor.value) {
    res = [...res, 'profile', 'information'];
  } else {
    res = [...res, 'profile'];
  }
  return res;
});

const webTaskHiddenCreateFormBottom = computed<boolean>(() => {
  return _remoteConfigStore.webTaskHiddenCreateFormBottom;
});
const isCanCreateTask = computed(() => {
  return webTaskHiddenCreateFormBottom.value;
});
const isGroupSubTasks = computed(() => {
  return _taskListStore.isGroupSubTasks;
});
const resourceFixedMember = computed(() => ({
  id: currentDomain.value?.id,
  isDomain: true,
}));

const route = useRoute();
const { changeByRouter } = customDocumentTitle();

const currentDomain = computed<any>(() => {
  return domainByIds.value[domainId.value];
});

const showMessage = ref(false);
const selectedTab = computed<TaskListTab>(() => _taskListStore.activeTab);
let unscibleLatestTaskActivity;
const isShowDomainDetail = ref(false);
const currentTabInfoGroup = ref('INFORMATION');
const taskListRef = ref(null as any);
const isShowCustomExportModal = ref(false);

const { initListData, onChangeParentTab, onChangeChildTab } =
  tableListTaskComposable();

const initData = async (isReload?) => {
  if (
    !domainId.value &&
    !props.domainId &&
    !props.projectId &&
    !props.subProjectId
  ) {
    return;
  }

  _taskListStore.$reset();
  if (props.isShowTasksOnly) {
    _taskListStore.isGroupSubTasks = true;
  }

  if (!isReload && route.query?.filterTab == 'TODO') {
    taskTabViewStore().updateDefaultTab(EFilterTaskDefault.DO_TODAY);
  }

  if (props.customFilterSourceType == ECustomFilterSourceType.OVERVIEW_DOMAIN) {
    await initListData(props.domainId, ETaskListModule.DOMAIN, {
      customFilterSourceType: ECustomFilterSourceType.OVERVIEW_DOMAIN,
    });
  } else if (
    props.customFilterSourceType == ECustomFilterSourceType.OVERVIEW_PROJECT
  ) {
    await initListData(props.projectId, ETaskListModule.PROJECT, {
      customFilterSourceType: ECustomFilterSourceType.OVERVIEW_PROJECT,
    });
  } else if (
    props.customFilterSourceType == ECustomFilterSourceType.OVERVIEW_SUB_PROJECT
  ) {
    await initListData(props.subProjectId, ETaskListModule.SUB_PROJECT, {
      customFilterSourceType: ECustomFilterSourceType.OVERVIEW_SUB_PROJECT,
    });
  } else {
    await initListData(domainId.value, ETaskListModule.DOMAIN, {
      isReload,
    });
  }

  console.log('🚀 Tictop ~ currentDomain.value:', currentDomain.value);

  changeByRouter(
    route?.name?.toString(),
    currentDomain.value?.name?.toString(),
    'Domain'
  );
};

const onReady = () => {
  if (props.isShowTasksOnly) {
    _taskListStore.isGroupSubTasks = true;
    _taskListStore.setFilterDataByKey('subTasks', true);
    _taskListStore.setFilterDataByKey('subDomain', props.isIncludeSub);

    taskListStore().onFilterTaskOnColumn();
  }
};

watch(
  () => props.isIncludeSub,
  () => {
    _taskListStore.setFilterDataByKey('subDomain', props.isIncludeSub);

    taskListStore().onFilterTaskOnColumn();
  }
);

const { onListenFirebase, onListenMultiTask } = taskListRealtime();
onMounted(async () => {
  _taskTabViewStore.setCustomizeViewActive();

  showMessage.value = _settingStore.checkSettingAccessPermission('chatEnabled');
});

onUnmounted(() => {
  unscibleLatestTaskActivity && unscibleLatestTaskActivity();
});

watch(
  () => domainId.value,
  (newData, oldData) => {
    if (isLoading.value) return;
    if (!newData || !oldData) return;
    if (!isEqual(newData, oldData) && currentDomain?.value?.id) {
      onListenFirebase('domain', domainId.value);
      onListenMultiTask('domain', domainId.value);
      initData();

      if (route.name !== 'DomainTasks') {
        changeByRouter(
          route?.name?.toString(),
          currentDomain.value?.name?.toString(),
          'Domain'
        );
      }
    }
  }
);
watch(
  [
    () => props.projectId,
    () => props.subProjectId,
    () => props.customFilterSourceType,
  ],
  () => {
    initData();
  }
);

// DETAIL GROUP
const onShowSettingGroup = (location) => {
  currentTabInfoGroup.value = location;
  isShowDomainDetail.value = true;
};

const onUpdateInformation = () => {
  initData();
};

const onDeleteSuccess = () => {
  router.replace({
    name: 'OverviewDomains',
  });
};

const onResetFilterMyTasks = () => {
  initData(true);
};

const onApplyFilter = () => {
  taskListRef.value.initData();
};

const setDefault = () => {
  _localConfigsStore.setLocalConfigs('domain', {
    overviewDomainViewDefault: 'LIST',
  });
};

initData();
</script>

<template>
  <div
    class="
      h-full
      w-full
      flex flex-col
      overflow-y-hidden overflow-x-hidden
      bg-white
    "
  >
    <!-- HEADER -->
    <div v-if="!isShowTasksOnly" class="flex justify-between items-center">
      <div class="flex items-end justify-between px-2 h-full w-full">
        <AvatarByTaskList
          class="py-1"
          type="DOMAIN"
          :name="currentDomain?.name"
          :contact-object="currentDomain"
          :total-tasks="totalTasks"
          :total-member="currentDomain?.members?.length"
          :is-loading="isLoading"
          :id-contact="currentDomain?.id || currentDomain?.domainId"
          @on-show-profile="onShowSettingGroup('INFORMATION')"
          @on-show-member="onShowSettingGroup('MEMBERS')"
        />
        <div class="flex items-center">
          <TaskTabs
            is-group
            :tab-list="['TASKS', 'RESOURCES', 'MANAGEMENT']"
            :active-tab="selectedTab"
            @tab-selected="onChangeParentTab"
          />
        </div>
        <div></div>
      </div>
    </div>
    <!-- MULTIPLE SELECT & FILTERS -->
    <UpdateMultipleActions v-if="selectedTaskIds?.length > 0" />
    <div
      v-else-if="isTasksTab"
      class="flex w-full"
      :class="isShowTasksOnly ? '' : 'border-t border-gray-100'"
    >
      <FilterTabs
        :key="isLoading?.toString()"
        :list-task-owner-id="createTaskDomainId || domainId"
        :type="'DOMAIN'"
        :is-show-tab="selectedTab === TaskListTab.MyList"
        :view-mode-list="
          isShowTasksOnly
            ? []
            : [
                ETaskViewType.LIST,
                ETaskViewType.BOARD,
                ETaskViewType.CALENDAR,
                ETaskViewType.GANTT,
              ]
        "
        :source-type="ETaskListModule.DOMAIN"
        :custom-filter-source-type="customFilterSourceType"
        @on-change-tab="onChangeChildTab"
      >
        <template #actions>
          <div class="flex items-center">
            <!-- CREATE DATE -->
            <AtomButton
              v-if="hasSetDefaultButton"
              size="sm"
              type="TEXT"
              :label="$t('COMMON_LABEL_SET_DEFAULT')"
              @click="setDefault"
            />
            <ActionsListTask
              :is-hidden-search-filter="!isTasksTab"
              :domain-id="currentDomain?.id"
              :current-tab="selectedTab"
              :action-hidden="actionHidden"
              :is-hidden-filter="true"
              :is-show-reload="true"
              :disable-extend-actions="isShowTasksOnly"
              :source-type="ETaskListModule.DOMAIN"
              :is-hidden-filter-sub="isHiddenFilterSub"
              :is-group-sub-tasks="isGroupSubTasks"
              show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_DOMAIN"
              @on-show-profile="onShowSettingGroup('INFORMATION')"
              @on-export-task="isShowCustomExportModal = true"
              @on-reload="onResetFilterMyTasks"
              @on-reset-filter="onResetFilterMyTasks"
              @on-apply-filter="onApplyFilter"
            />
          </div>
        </template>
        <template #filter>
          <FilterTaskButton
            :domain-id="currentDomain?.id"
            :current-tab="selectedTab"
            :current-children-tab="childrenTabActive"
            show-filter-all-checkbox-code="TASK_LABEL_FILTER_ALL_DESCRIPTION_DOMAIN"
            @on-reset-filter="onResetFilterMyTasks"
            @on-apply-filter="onApplyFilter"
          />
        </template>
      </FilterTabs>
    </div>
    <div class="relative flex-1 min-h-0">
      <div class="w-auto h-full">
        <div v-if="isLoading" class="flex flex-col h-full">
          <SynAnimation name="my-tasks" />
        </div>
        <template v-else>
          <template v-if="selectedTab === TaskListTab.MyList">
            <template v-if="viewTask === ETaskViewType.BOARD">
              <TaskBoard
                location="DOMAIN_TASKS"
                class="px-1 pb-2"
                :is-show-all="false"
                :current-contact-id="domainId"
                :type="'DOMAIN'"
              />
            </template>
            <template v-else-if="viewTask === ETaskViewType.GANTT">
              <div
                v-permission-screen="{
                  screenCode: ALL_SCREENS.DOMAIN.TASK.GANTT_CHART,
                  isPage: true,
                }"
                class="w-full h-full"
              >
                <GanttChart
                  :owner-id="domainId"
                  :source-type="ETaskListModule.DOMAIN"
                />
              </div>
            </template>
            <template v-else-if="viewTask === ETaskViewType.CALENDAR">
              <div
                v-permission-screen="{
                  screenCode: ALL_SCREENS.DOMAIN.TASK.CALENDAR,
                  isPage: true,
                }"
                class="w-full h-full relative"
              >
                <TaskCalendarView
                  :owner-id="domainId"
                  :source-type="ETaskListModule.DOMAIN"
                />
              </div>
            </template>
            <template v-else-if="viewTask === ETaskViewType.LIST">
              <div
                :style="
                  isShowTasksOnly || !isCanCreateTask
                    ? ''
                    : 'height: calc(100% - 4.5rem)'
                "
                class="bg-white h-full overflow-hidden"
              >
                <TaskList
                  ref="taskListRef"
                  type="domain"
                  location="DOMAIN_TASKS"
                  :info-owner="currentDomain"
                  :is-filter-in-table="true"
                  :is-simple-table="isShowTasksOnly"
                  :is-show-add-new-button="isShowTasksOnly"
                  @reload="onResetFilterMyTasks"
                  @add-new-task="$emit('addNewTask')"
                  @ready="onReady"
                />
              </div>
            </template>
          </template>
          <div v-if="selectedTab === TaskListTab.Resources" class="h-full">
            <GedPage :fixed-member="resourceFixedMember" />
          </div>
          <div
            v-if="selectedTab === TaskListTab.Management"
            v-permission-screen="{
              screenCode: ALL_SCREENS.DOMAIN.MANAGEMENT.PAGE,
              isPage: true,
            }"
            style="height: 100%"
          >
            <DomainManagementPage
              :domain-id="currentDomain?.id || currentDomain?.domainId"
              @on-change-tab="onSelectedTab(TaskListTab.MyList)"
              @on-update-infomation="onUpdateInformation"
            />
          </div>
        </template>
      </div>

      <!-- <div
        v-else
        class="w-auto pt-4"
        :style="isShowTasksOnly ? '' : 'height: calc(100vh - 12rem)'"
      >
        <SynAnimation
          name="private"
          stype="width: 100px; height: 100px"
          :label="$t('HEADER_LABEL_CANNOT_VIEW_TASK_IN_PRIVATE_GROUP')"
        />
      </div> -->
      <TaskCreateLocal
        v-if="
          selectedTab !== TaskListTab.Management &&
          !isShowTasksOnly &&
          isCanCreateTask
        "
        :assignee-id="currentUserId"
        :domain-id="domainId"
        :is-show-apart="selectedTab !== TaskListTab.Resources"
      />
    </div>
  </div>
  <DomainDetailModal
    v-if="isShowDomainDetail"
    :domain-id="domainId"
    :default-tab="currentTabInfoGroup"
    @on-close="isShowDomainDetail = false"
    @on-save-success="onUpdateInformation"
    @on-delete-success="onDeleteSuccess"
  />

  <CustomExportModal
    v-if="isShowCustomExportModal"
    module-name="Domain"
    :domain-id="currentDomain?.id"
    :owner-name="currentDomain?.name"
    :current-tab="selectedTab"
    @on-close="isShowCustomExportModal = false"
    @on-reset-filter="onResetFilterMyTasks"
  />
</template>
