import {
    arrayUnion,
    collection,
    deleteField,
    doc,
    endBefore,
    getDocsFromCache,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    updateDoc,
} from 'firebase/firestore';
import { firestoreDb } from '@/ui/plugins/firebases/init-app';
import { getDocs, QuerySnapshot } from '@firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { chain } from 'lodash';
import { ChatMessageModel } from '@/application/models/chat/ChatMessageModel';
import { IDayOffFromMessage } from '@/application/types/calendar/day-off.types';
import { IEventFromMessage } from '@/application/types/calendar/event.types';

class ChatMessageRepository {
    getMessagesFromLocal(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ) {
        const query = this._prepareMessagesQuery(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return getDocsFromCache(query);
    }

    getMessagesFromServer(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number
    ) {
        const query = this._prepareMessagesQuery(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return getDocs(query);
    }

    subscribeAddedMessages(
        orgId: number,
        converId: string,
        startAfterVal: any,
        endBeforeVal: any,
        pageSize: number,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const query = this._prepareMessagesQuery(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize
        );

        return onSnapshot(query, onNext);
    }

    subscribeUpdatedMessages(
        orgId: number,
        converId: string,
        startAfterVal: any,
        endBeforeVal: any,
        pageSize: number,
        onNext: (snapshot: QuerySnapshot<any>) => void
    ) {
        const query = this._prepareMessagesQuery(
            orgId,
            converId,
            startAfterVal,
            endBeforeVal,
            pageSize,
            'updatedDate'
        );

        return onSnapshot(query, onNext);
    }

    getNewMessageDoc(orgId: number, converId: string) {
        return doc(this.getCollectionRef(orgId, converId));
    }

    addMessage(
        orgId: number,
        converId: string,
        msgId: string,
        message: ChatMessageModel
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);
        return setDoc(docRef, message);
    }

    updateMessage(
        orgId: number,
        converId: string,
        msgId: string,
        updData: any,
        userId: number
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);
        return updateDoc(docRef, {
            ...updData,
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        });
    }

    addMessageReaction(
        orgId: number,
        converId: string,
        msgId: string,
        userId: number,
        reaction: any
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        const newReactionId = uuidv4();
        const newReaction = {
            content: reaction || null,
            createdBy: `${userId}`,
            createdDate: serverTimestamp(),
        };

        return setDoc(
            docRef,
            {
                reactions: {
                    [`${newReactionId}`]: newReaction,
                },
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            },
            { merge: true }
        );
    }

    removeMessageReactions(
        orgId: number,
        converId: string,
        msgId: string,
        userId: number,
        reactionIds: string[]
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        const reactions = chain(reactionIds)
            .keyBy()
            .mapValues(() => deleteField())
            .value();

        return setDoc(
            docRef,
            {
                reactions,
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            },
            { merge: true }
        );
    }

    addMessageBackgroundColor(
        orgId: number,
        converId: string,
        msgId: string,
        userId: number,
        bgColor: any
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);
        return setDoc(
            docRef,
            {
                customizes: {
                    backgroundColor: {
                        [`${userId}`]: bgColor,
                    },
                },
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            },
            { merge: true }
        );
    }

    removeMessageBackgroundColor(
        orgId: number,
        converId: string,
        msgId: string,
        userId: number
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);
        return setDoc(
            docRef,
            {
                customizes: {
                    backgroundColor: {
                        [`${userId}`]: deleteField(),
                    },
                },
                updatedBy: `${userId}`,
                updatedDate: serverTimestamp(),
            },
            { merge: true }
        );
    }

    pinMessage(orgId: number, converId: string, msgId: string, userId: string) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        return updateDoc(docRef, {
            pinned: true,
            pinnedBy: `${userId}`,
            pinnedDate: serverTimestamp(),
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        });
    }

    unpinMessage(
        orgId: number,
        converId: string,
        msgId: string,
        userId: string
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        return updateDoc(docRef, {
            pinned: deleteField(),
            pinnedBy: deleteField(),
            pinnedDate: deleteField(),
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        });
    }

    addTaskCreatedFromMessage(
        orgId: number | string,
        converId: string,
        msgId: string,
        taskInfo: any,
        userId: number
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        return updateDoc(docRef, {
            createdTasks: arrayUnion({
                taskId: taskInfo?.id || null,
                taskCode: taskInfo?.code || null,
                createdDate: taskInfo?.creationTime || null,
                createdBy: taskInfo?.creatorId || null,
            }),
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        });
    }

    addCreatedCalendarToMessage(
        orgId: number | string,
        converId: string,
        msgId: string,
        metaData: IDayOffFromMessage | IEventFromMessage,
        userId: number
    ) {
        const docRef = doc(this.getCollectionRef(orgId, converId), msgId);

        return updateDoc(docRef, {
            createdCalendarActions: arrayUnion(metaData),
            updatedBy: `${userId}`,
            updatedDate: serverTimestamp(),
        });
    }

    getCollectionRef(orgId: number | string, converId: string) {
        return collection(
            firestoreDb,
            'organizationConversations',
            `${orgId}`,
            'conversations',
            `${converId}`,
            'messages'
        );
    }

    private _prepareMessagesQuery(
        orgId: number,
        converId: string,
        startAfterVal?: any,
        endBeforeVal?: any,
        pageSize?: number,
        orderByField: string = 'createdDate'
    ) {
        let q = query(
            this.getCollectionRef(orgId, converId),
            orderBy(orderByField, 'desc')
        );

        if (startAfterVal) {
            q = query(q, startAfter(startAfterVal));
        }

        if (endBeforeVal) {
            q = query(q, endBefore(endBeforeVal));
        }

        if (pageSize) {
            q = query(q, limit(pageSize));
        }

        return q;
    }
}

export default new ChatMessageRepository();
